import { ArrowLeftOutlined, ExclamationCircleFilled, UserOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import exclamationBlue from '../../assets/exclamation-circle.svg';
import CardCountDown from '../../components/Common/CardCountdown';
import StyledModal from '../../components/Common/StyledModal';
import { Loading } from '../../components/Loading';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { RoutesPath } from '../../routes/routesPath';
import theme from '../../styles/theme';
import { ContentModalBidDetails } from './ContentModalBidDetails';
import * as S from './styles';
import { useBidResponse } from './useBidResponse';

export function BidResponse() {
  useDocumentTitle(t('pages.rfq.title'));
  const {
    isLoading,
    bidItemSelected,
    currentUser,
    visibleModal,
    bidId,
    categorySelected,
    handleCountItensOnBid,
    handleRenderBidDetails,
    handleRenderBidGroupsWithCards,
    handleGetBidData,
    handleCloseModal,
  } = useBidResponse();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const queryParams = useQuery();
  const history = useHistory();

  useEffect(() => {
    const bidIdReceived = queryParams.get('bidId');
    if (bidIdReceived) {
      bidId.current = bidIdReceived;
      handleGetBidData();
    }
  }, []);

  return (
    <S.Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <S.BackButton
            onClick={() => history.push(RoutesPath.bidSupplierView, { bidId: bidId.current })}
            variant="slim"
          >
            <ArrowLeftOutlined />
            {t('pages.awardsSummary.bidSummaryBack')}
          </S.BackButton>
          <S.HeaderContainer>
            <Col>
              <Row align="middle" style={{ gap: 4 }}>
                <S.SpanTile>{t('pages.bidResponse.title')}</S.SpanTile>
                <S.SpanQtItens>
                  | <b>{handleCountItensOnBid()}</b> itens
                </S.SpanQtItens>
              </Row>
              <Row>
                <S.SpanLabel>{t('pages.bidResponse.subTitle')}</S.SpanLabel>
              </Row>
            </Col>
            <Row align="middle" style={{ gap: 4 }}>
              <S.SupplierSpan>{currentUser.companyUser || ''}</S.SupplierSpan>
              <S.UserContainer>
                <UserOutlined />
                <span>{currentUser.currentUser || currentUser.currentUserNickName}</span>
              </S.UserContainer>
            </Row>
          </S.HeaderContainer>

          <S.ContentContainer>
            <S.ContentLeftContainer>
              <S.HeaderContentLeft>
                {bidItemSelected?.altGroup && (
                  <CardCountDown endDate={bidItemSelected?.responseBy || ''} showEndDate />
                )}
              </S.HeaderContentLeft>
              <S.CardsBidContainer>{handleRenderBidGroupsWithCards()}</S.CardsBidContainer>
            </S.ContentLeftContainer>
            <S.ContentRightContainer>
              <S.HeaderContentRight>
                {(bidItemSelected?.status === 'PENDING' ||
                  bidItemSelected?.status === 'UPDATING') && (
                  <Row align="middle" className="row">
                    <ExclamationCircleFilled
                      style={{ fontSize: 20, color: theme.colorsDesignSystem.tagColorYellow }}
                    />
                    <S.TitleCardRight>{`${categorySelected} | ${bidItemSelected?.altGroup}`}</S.TitleCardRight>
                  </Row>
                )}
                {(bidItemSelected?.status === 'DECLINED' ||
                  bidItemSelected?.status === 'ANSWERED') && (
                  <Row align="middle" className="row">
                    <span>{t('pages.bidResponse.messages.rankingForbid')}</span>
                    |
                    <img
                      src={exclamationBlue}
                      alt={t('pages.bidResponse.altImages.exclamationBlue')}
                    />
                    <S.TitleCardRight>{`${categorySelected} | ${bidItemSelected?.altGroup}`}</S.TitleCardRight>
                  </Row>
                )}
              </S.HeaderContentRight>
              {handleRenderBidDetails()}
            </S.ContentRightContainer>
          </S.ContentContainer>
          {visibleModal && (
            <StyledModal
              footer={null}
              title={t('pages.bidResponse.modalColumnLabels.title')}
              width="54rem"
              open={visibleModal}
              onClose={handleCloseModal}
              body={
                <ContentModalBidDetails
                  id={bidItemSelected?.id || ''}
                  title={`${categorySelected} | ${bidItemSelected?.altGroup}`}
                  specifications={bidItemSelected?.specifications || []}
                />
              }
            />
          )}
        </>
      )}
    </S.Container>
  );
}
