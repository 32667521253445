/* eslint-disable react/jsx-boolean-value */
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegUserCircle, FaUserCircle } from 'react-icons/fa';
import { UsersSelect } from '../../../components/forms/UsersSelect';
import { useUserSelect } from '../../../components/forms/UsersSelect/useUserSelect';
import { oneAlert } from '../../../helpers/utils';
import { ValidateMessages } from '../../../helpers/ValidateMessages';
import { RoutesPath } from '../../../routes/routesPath';
import { api } from '../../../services/api';
import { services } from '../../../services/services';
import { Alert } from '../types';
import * as S from './styles';

export function EditAlert({
  alert,
  onClose,
  onSuccess,
}: {
  alert: Alert | null;
  onClose: () => void;
  onSuccess: (newAlert: Alert) => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { users, getUsers } = useUserSelect();
  useEffect(() => {
    getUsers();
  }, []);

  const initialFormValues = [
    { name: 'description', value: alert?.description },
    { name: 'cm', value: alert?.cm },
    {
      name: 'contacts',
      value:
        alert?.contacts?.filter((c: any) => !!c?.email)?.map((contact) => contact?.email || '') ||
        [],
    },
    {
      name: 'sendEmail',
      value: alert?.sendEmail || 'NO',
    },
    {
      name: 'emails',
      value: alert?.contacts?.filter((c) => c.sendEmail).map((c) => c.email),
    },
    {
      name: 'frequency',
      value: 7,
    },
    {
      name: 'emailMsg',
      value: alert?.emailMsg,
    },
    {
      name: 'active',
      value: alert?.active,
    },
    {
      name: 'showFileRemarks',
      value: alert?.showFileRemarks || false,
    },
  ];

  const handleSave = async () => {
    const emailsToSend = form.getFieldValue('emails');
    const { active, description, emailMsg, frequency, sendEmail, showFileRemarks, contacts } =
      form.getFieldsValue();
    const changeContacts = form.isFieldTouched('contacts');

    const payload = {
      alertName: alert?.alertName,
      description,
      emailMsg,
      frequency: alert?.category === 'QD' || alert?.category === 'QF' ? 0 : 7,
      active,
      sendEmail,
      showFileRemarks,
      cm: alert?.cm,
      contacts: changeContacts
        ? contacts?.map((user: any) => ({
            email: user?.value || (user?.includes('@') ? user : ''),
            name: typeof user.label === 'object' ? user.label.props.children[1] : user.value,
            sendEmail:
              (typeof user.label === 'string' && user.label.includes('@')) ||
              typeof user.label === 'undefined' ||
              sendEmail === 'all'
                ? true
                : sendEmail === 'no'
                ? false
                : emailsToSend?.includes(user.value),
          }))
        : alert?.contacts.map((contact) => ({
            ...contact,
            sendEmail:
              contact.name?.includes('@') || sendEmail === 'all'
                ? true
                : sendEmail === 'no'
                ? false
                : emailsToSend?.includes(contact.email),
          })),
    };

    await api
      .put(`${services.events}${RoutesPath.alerts}/${alert?.id}`, payload)
      .then(async (res) => {
        oneAlert('success', t('toast.successOnSave'));
        onSuccess({ ...alert, ...payload } as Alert);
        onClose();
      })
      .catch((err) => {
        oneAlert('error', t('toast.errorOnSave'));
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const handleRenderFrequencyQualificationAlert = (): ReactNode => {
    if (alert?.category === 'QD' || alert?.category === 'QF') {
      return (
        <>
          <Checkbox checked>{t('pages.alert.fields.frequencyImmediately')}</Checkbox>
          <Form.Item
            name="showFileRemarks"
            label={t('pages.alert.fields.ShowFileRemarks')}
            labelCol={{ span: 24 }}
            style={{ marginBottom: 8, marginTop: '2.25rem' }}
          >
            <Radio.Group
              onChange={(e) => {
                e.preventDefault();
                form.setFieldValue('showFileRemarks', e.target.value);
              }}
            >
              <Radio value={false}>{t('pages.alert.fields.no')}</Radio>
              <Radio value={true}>{t('pages.alert.fields.yes')}</Radio>
            </Radio.Group>
          </Form.Item>
        </>
      );
    }

    return null;
  };

  return (
    <S.Wrapper>
      <Form
        form={form}
        fields={initialFormValues}
        onReset={() => handleCancel()}
        onFinish={handleSave}
        validateMessages={ValidateMessages()}
      >
        <Row>
          <div className="title">
            <Typography.Title level={4}>{alert?.alertName}</Typography.Title>
            <div className="activateAlert">
              <span>{t('pages.alert.fields.activate')}</span>
              <Form.Item name="active" valuePropName="checked">
                <Switch
                  className="switch"
                  size="small"
                  onChange={(e) => {
                    form.setFieldValue('active', e);
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </Row>

        <ConfigProvider>
          <Row gutter={32}>
            <Col span={24}>
              <Form.Item
                name="description"
                label={t('pages.alert.fields.description')}
                labelCol={{ span: 24, style: { height: 'auto' } }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="CM" name="cm" labelCol={{ span: 24 }}>
                <Select disabled style={{ width: '8.5rem' }} />
              </Form.Item>
              <Form.Item
                name="contacts"
                messageVariables={{
                  label: t('pages.alert.validate.contacts'),
                }}
                label={t('pages.alert.fields.users')}
                labelCol={{ span: 24 }}
                rules={[
                  { required: true },
                  {
                    type: 'array',
                    defaultField: {
                      type: 'email',
                      message: `${t('pages.alert.validate.contact')} ${t(
                        'pages.alert.validate.emailInvalid'
                      )}`,
                      transform(value) {
                        return value.value;
                      },
                    },
                  },
                ]}
                style={{ marginBottom: 64 }}
              >
                <UsersSelect form={form} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="sendEmail"
                label={t('pages.alert.fields.sendEmail')}
                labelCol={{ span: 24 }}
                style={{ marginBottom: 8 }}
              >
                <Radio.Group
                  onChange={(e) => {
                    e.preventDefault();
                    console.log(e.target.value);
                    form.setFieldValue('sendEmail', e.target.value);
                  }}
                >
                  <Radio value="NO">{t('pages.alert.fields.no')}</Radio>
                  <Radio value="FOR_ALL">{t('pages.alert.fields.forAll')}</Radio>
                  <Radio value="FOR_SOME">{t('pages.alert.fields.forSome')}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.contacts !== currentValues.contacts ||
                  prevValues.sendEmail !== currentValues.sendEmail ||
                  prevValues.emails !== currentValues.sendEmail
                }
                noStyle
              >
                {(formInst) => {
                  // console.log('formInst=>', formInst.getFieldsValue());
                  const contacts = formInst.getFieldValue('contacts');
                  const emails = formInst.getFieldValue('emails');
                  const usersChanged = formInst.isFieldTouched('contacts');

                  const options = usersChanged
                    ? contacts
                        ?.filter((c: any) => !!c?.value)
                        ?.map((option: any) => {
                          const label = option.label || option.value || option;
                          const value = option.value || option;
                          return { label, value };
                        })
                    : alert?.contacts
                        ?.filter((c: any) => !!c?.email)
                        ?.map((option: any) => ({
                          label: option.name,
                          value: option.email,
                        }));

                  return formInst.getFieldValue('sendEmail') === 'FOR_SOME' ? (
                    <Checkbox.Group
                      value={emails}
                      onChange={(e) => {
                        formInst.setFieldValue('emails', e);
                      }}
                    >
                      {options.map((cont: any) => {
                        const isUser = users.find((u) => u.value === cont.value);
                        return (
                          <Checkbox key={cont.value} value={cont.value} disabled={!isUser}>
                            {!isUser ? (
                              <span>
                                <FaRegUserCircle color="#8DC55B" size={18} />
                                {cont.label}
                              </span>
                            ) : (
                              <span>
                                <FaUserCircle color="#8DC55B" size={18} /> {isUser.label}
                              </span>
                            )}
                          </Checkbox>
                        );
                      })}
                    </Checkbox.Group>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={12}>
              {alert?.category === 'SKU' && (
                <Form.Item
                  name="frequency"
                  messageVariables={{
                    label: t('pages.alert.fields.frequency'),
                  }}
                  label={t('pages.alert.fields.editFrequency')}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 8 }}
                  rules={[
                    {
                      min: 1,
                      max: 99,
                      type: 'number',
                    },
                  ]}
                >
                  <InputNumber
                    type="number"
                    style={{ display: 'flex', justifyContent: 'center' }}
                    addonAfter="days"
                    controls={false}
                    disabled
                  />
                </Form.Item>
              )}
              {handleRenderFrequencyQualificationAlert()}
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('pages.alert.fields.emailMsg')}
                messageVariables={{
                  label: t('pages.alert.validate.emailMsg'),
                }}
                labelCol={{ span: 24 }}
                name="emailMsg"
                rules={[
                  {
                    min: 3,
                    max: 150,
                  },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={150}
                  style={{ height: 100, marginBottom: 24 }}
                  placeholder=""
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 24 }}
            >
              <Button style={{ width: 140 }} htmlType="reset">
                {t('common.cancel')}
              </Button>
              <Button type="primary" htmlType="submit" style={{ width: 140 }}>
                {t('common.save')}
              </Button>
            </Col>
          </Row>
        </ConfigProvider>
      </Form>
    </S.Wrapper>
  );
}
