import {
  ExclamationCircleFilled,
  InfoCircleFilled,
  TrophyOutlined,
  TruckOutlined,
} from '@ant-design/icons';
import { Flex, Progress, Radio, Select, Switch, Tooltip } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import StyledButton from '../../components/Common/StyledButton';
import StyledModal from '../../components/Common/StyledModal';
import StyledSelect from '../../components/Common/StyledSelect';
import { Loading } from '../../components/Loading';
import { BidStatus } from '../../helpers/enums';
import { handleFormatValueFields } from '../../helpers/nUtils';
import theme from '../../styles/theme';
import { ModalTotalAwards } from './ModalTotalAwards';
import * as S from './styles';
import { AwardSplitPageProps } from './types';
import { useAwardSplit } from './useAwardSplit';

export function AwardSplit() {
  const {
    handleRenderSpecs,
    handleCalculatePercentProjectsDone,
    handleRenderAwardSupplierCards,
    setVisualizationType,
    setProjectSelected,
    handleVerifyIfProjectIsNotFirst,
    setSuppliersSelected,
    handleSaveRemark,
    handleRenderEditsSplitAward,
    handleAllAwardDistributionForOneSupplier,
    setVisibleModal,
    handleSendAwardDistribution,
    handleGetAllData,
    handleToggleSplitDistribution,
    handleNavigateBackAwardSimulation,
    handlePreviousProject,
    handleNavigateToDetailsPage,
    handleValidateIfAllProjectsConcluded,
    handleVerifyIfSplitMustBeDisabled,
    setIsFirstRendering,
    handleRenderTitleButtonNext,
    handleGetProjects,
    projectSelected,
    dataAward,
    projects,
    suppliers,
    suppliersSelected,
    errorDistribution,
    visibleModal,
    dataAwardModal,
    loading,
    propsPage,
  } = useAwardSplit();

  const location = useLocation();
  const { bidId, altGroup, isEdit } = location.state as AwardSplitPageProps;

  propsPage.current = { bidId, altGroup, isEdit };

  useEffect(() => {
    handleGetProjects(bidId, altGroup);
  }, []);

  useEffect(() => {
    projectSelected && handleGetAllData();
  }, [projectSelected]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <S.Container>
          <S.ContentContainer>
            <S.ContentLeft>
              <Flex
                align="center"
                gap={8}
                style={{
                  fontSize: '1.75rem',
                  color:
                    dataAward?.status === BidStatus.RESULT
                      ? theme.colorsDesignSystem.blue
                      : theme.colorsDesignSystem.tagColorYellow,
                }}
              >
                <ExclamationCircleFilled />
                <S.TitleLeftCard>{altGroup}</S.TitleLeftCard>
              </Flex>
              <hr />
              <StyledSelect
                disable={!!propsPage?.current.isEdit}
                options={projects}
                value={projectSelected}
                onChange={setProjectSelected}
              />
              <Flex vertical gap={8} justify="center">
                <S.Labels className="withBorder">{`${dataAward?.projectsConcluded}/${
                  dataAward?.totalProjects
                } ${t('pages.awardSplit.labels.projects')}`}</S.Labels>
                <Progress percent={handleCalculatePercentProjectsDone()} showInfo={false} />
              </Flex>
              <S.ContainerCards>
                <S.CardLeftSide>
                  <S.Labels>{t('pages.awardSplit.labels.category')}</S.Labels>
                  <S.Labels className="normal">{dataAward?.category || '-'}</S.Labels>
                </S.CardLeftSide>
                <S.CardLeftSide>
                  <S.Labels>{t('pages.awardSplit.labels.spec')}</S.Labels>
                  <Flex wrap gap={8}>
                    {handleRenderSpecs()}
                  </Flex>
                </S.CardLeftSide>
                <S.CardLeftSide>
                  <S.CardFromTo>
                    <S.Labels>{t('pages.awardSplit.labels.from')}</S.Labels>
                    <S.Labels className="normal">{dataAward?.from || '-'}</S.Labels>
                    <TruckOutlined
                      style={{ fontSize: '1.2rem', color: theme.colorsDesignSystem.blue }}
                    />
                    <S.Labels>{t('pages.awardSplit.labels.to')}</S.Labels>
                    <S.Labels className="normal">{dataAward?.to || '-'}</S.Labels>
                  </S.CardFromTo>
                </S.CardLeftSide>
                <S.CardLeftSide>
                  <Flex justify="space-between">
                    <Flex vertical gap={4}>
                      <S.Labels>{t('pages.awardSplit.labels.startingPrice')}</S.Labels>
                      <S.Labels className="large">
                        {dataAward?.startingPrice
                          ? `$${handleFormatValueFields({
                              number: dataAward?.startingPrice,
                              notation: 'compact',
                            })}`
                          : '-'}
                      </S.Labels>
                    </Flex>
                    |
                    <Flex vertical gap={4}>
                      <S.Labels>{t('pages.awardSplit.labels.totalDemands')}</S.Labels>
                      <S.Labels className="large">
                        {dataAward?.totalDemand ? (
                          <Tooltip
                            title={handleFormatValueFields({
                              number: dataAward?.totalDemand || 0,
                            })}
                          >
                            {handleFormatValueFields({
                              number: dataAward?.totalDemand || 0,
                              notation: 'compact',
                            })}
                          </Tooltip>
                        ) : (
                          '-'
                        )}
                      </S.Labels>
                    </Flex>
                  </Flex>
                </S.CardLeftSide>
                <StyledButton
                  variant="secondary"
                  onClick={() => {
                    setVisibleModal(true);
                  }}
                >
                  <TrophyOutlined />
                  {t('pages.awardSplit.labels.projectAward')}
                </StyledButton>
                <Flex vertical gap={7}>
                  <Flex gap={8}>
                    <S.Labels>{t('pages.awardSplit.labels.remarks')}</S.Labels>
                    <InfoCircleFilled style={{ color: theme.colorsDesignSystem.primary }} />
                  </Flex>
                  <TextArea
                    onChange={(e) => {
                      handleSaveRemark(e.target.value);
                    }}
                    value={dataAward?.remark}
                    style={{ minHeight: '4.5rem' }}
                  />
                </Flex>
              </S.ContainerCards>
            </S.ContentLeft>
            <S.ContainerRight>
              <S.ContainerContentRight>
                <Flex vertical gap={8}>
                  <S.Labels>{t('pages.awardSplit.labels.supplier')}</S.Labels>
                  <Select
                    mode="multiple"
                    maxCount={3}
                    options={suppliers}
                    value={suppliersSelected}
                    onChange={(values) => {
                      setSuppliersSelected(values);
                      handleAllAwardDistributionForOneSupplier();
                    }}
                  />
                </Flex>
                {dataAward?.splitAward && (
                  <Flex vertical align="center">
                    <S.ContainerCardInputs>{handleRenderEditsSplitAward()} </S.ContainerCardInputs>
                    {errorDistribution && (
                      <S.Labels className="alert">
                        {t('pages.awardSplit.messages.errorDistribution')}
                      </S.Labels>
                    )}
                  </Flex>
                )}

                <Flex justify="flex-end" align="center" gap={24}>
                  <Flex align="center" gap={8}>
                    <S.Labels>{t('pages.awardSplit.labels.splitAward')}</S.Labels>
                    <Switch
                      value={dataAward?.splitAward}
                      disabled={handleVerifyIfSplitMustBeDisabled()}
                      onChange={() => {
                        handleAllAwardDistributionForOneSupplier();
                        handleToggleSplitDistribution();
                      }}
                    />
                  </Flex>
                  <S.StyledRadioGroup
                    defaultValue="NET"
                    buttonStyle="solid"
                    size="small"
                    onChange={(e) => {
                      setVisualizationType(e.target.value);
                    }}
                  >
                    <Radio.Button value="GROSS">{t('pages.awardSplit.labels.gross')}</Radio.Button>
                    <Radio.Button value="NET">{t('pages.awardSplit.labels.net')}</Radio.Button>
                  </S.StyledRadioGroup>
                </Flex>
                <S.ContainerCardsRight>{handleRenderAwardSupplierCards()}</S.ContainerCardsRight>
              </S.ContainerContentRight>
              <S.ContainerFooter>
                <StyledButton
                  disabled={!!propsPage.current.isEdit}
                  onClick={handleNavigateBackAwardSimulation}
                  variant="secondary"
                >
                  {t('pages.awardSplit.labels.cancel')}
                </StyledButton>
                <Flex gap={8}>
                  {handleVerifyIfProjectIsNotFirst() && (
                    <StyledButton variant="secondary" onClick={handlePreviousProject}>
                      {t('pages.awardSplit.labels.previous')}
                    </StyledButton>
                  )}
                  <StyledButton
                    onClick={() => {
                      handleValidateIfAllProjectsConcluded()
                        ? handleNavigateToDetailsPage(altGroup)
                        : handleSendAwardDistribution();
                    }}
                    variant="primary"
                  >
                    {handleRenderTitleButtonNext()}
                  </StyledButton>
                </Flex>
              </S.ContainerFooter>
            </S.ContainerRight>
          </S.ContentContainer>

          {dataAwardModal && (
            <StyledModal
              body={<ModalTotalAwards data={dataAwardModal} />}
              title={t('pages.awardSplit.modal.title')}
              footer={null}
              open={visibleModal}
              centered
              width="40rem"
              onClose={() => {
                setVisibleModal(false);
              }}
            />
          )}
        </S.Container>
      )}
    </div>
  );
}
