import { useTranslation } from 'react-i18next';
import BadConnectionSvg from '../../assets/bad-connection.svg';
import LogoSvg from '../../assets/title_auth.svg';
import * as S from './styles';

export function SessionExpired() {
  const { t } = useTranslation();

  const goToLogin = () => {
    window.location.href = 'https://www.hml.acer-loginunico.com/';
  };
  return (
    <S.Container>
      <img src={LogoSvg} alt="Acer" />
      <S.Description>
        <span>{t('pages.sessionExpired.title')}</span>
        <span>{t('pages.sessionExpired.description')}</span>
      </S.Description>
      <img src={BadConnectionSvg} alt="Bad Connection" />
      <S.Button variant="primary" onClick={goToLogin}>
        {t('pages.sessionExpired.button')}
      </S.Button>
    </S.Container>
  );
}
