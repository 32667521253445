import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import { TagStatusIndication } from '../../components/Qualification/TagStatusIndication';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import { formatDataToSelect, handleFormatValueFields, oneAlert } from '../../helpers/nUtils';
import { getSortOrder, getURI } from '../../helpers/utils';
import { api } from '../../services/api';
import * as S from './styles';
import { IParams, IQualificationData } from './types';

export function useQualification() {
  const { t } = useTranslation();
  const initialParams: IParams = {
    qualificationName: null,
    project: null,
    category: null,
    specification: null,
    partNumber: null,
    supplier: null,
    status: null,
    page: 0,
    size: 10,
    direction: 'asc',
    sortBy: 'creationDate',
  };
  const [params, setParams] = useState<IParams>(initialParams);
  const [projects, setProjects] = useState<StyledSelectOptions[]>();
  const [categories, setCategories] = useState<StyledSelectOptions[]>();
  const [specifications, setSpecifications] = useState<StyledSelectOptions[]>();
  const [partNumbers, setPartNumbers] = useState<StyledSelectOptions[]>();
  const [suppliers, setSuppliers] = useState<StyledSelectOptions[]>();
  const [status, setStatus] = useState<StyledSelectOptions[]>();

  const [isFirstRendering, setIsFirstRendering] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [qualificationData, setQualificationData] = useState<IQualificationData[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);

  const onChangeParams = (field: keyof IParams, value: string | null) => {
    setParams((prev) => {
      return {
        ...prev,
        [field]: value,
      };
    });
  };

  const handleRenderTags = () => {
    return (
      <>
        {params.project && (
          <StyledFilterTag
            label={t('pages.qualification.filters.project')}
            closeicon={<CloseOutlined />}
            title={params.project}
            closeble
            onClose={() => onChangeParams('project', null)}
          />
        )}
        {params.category && (
          <StyledFilterTag
            label={t('pages.qualification.filters.category')}
            closeicon={<CloseOutlined />}
            title={params.category}
            closeble
            onClose={() => onChangeParams('category', null)}
          />
        )}
        {params.specification && (
          <StyledFilterTag
            label={t('pages.qualification.filters.specification')}
            closeicon={<CloseOutlined />}
            title={params.specification}
            closeble
            onClose={() => onChangeParams('specification', null)}
          />
        )}

        {params.partNumber && (
          <StyledFilterTag
            label={t('pages.qualification.filters.partNumber')}
            closeicon={<CloseOutlined />}
            title={params.partNumber}
            closeble
            onClose={() => onChangeParams('partNumber', null)}
          />
        )}

        {params.supplier && (
          <StyledFilterTag
            label={t('pages.qualification.filters.supplier')}
            closeicon={<CloseOutlined />}
            title={params.supplier}
            closeble
            onClose={() => onChangeParams('supplier', null)}
          />
        )}
        {params.status && (
          <StyledFilterTag
            label={t('pages.qualification.filters.status')}
            closeicon={<CloseOutlined />}
            title={params.status}
            closeble
            onClose={() => onChangeParams('status', null)}
          />
        )}
        {params.qualificationName && (
          <StyledFilterTag
            label={t('pages.qualification.filters.idQualification')}
            closeicon={<CloseOutlined />}
            title={params.qualificationName}
            closeble
            onClose={() => onChangeParams('qualificationName', null)}
          />
        )}
      </>
    );
  };

  const onChangePage = (page: number, pageSize: number) => {
    setParams({ ...params, page: page - 1, size: pageSize });
  };

  const handleChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IQualificationData[]>
  ) => {
    setParams({
      ...params,
      sortBy: sorter.field as Key,
      direction: getSortOrder(sorter.order).toLowerCase(),
    });
  };

  const fetchProjects = async () => {
    try {
      const { status: statusCode, data } = await api.get(
        getURI(`/qualification/details/filter`, {
          filterToReturn: 'project',
        })
      );
      if (statusCode === 200) setProjects(formatDataToSelect(data, true));
      else setProjects([]);
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message });
    }
  };

  const fetchCategories = async () => {
    try {
      const { status: statusCode, data } = await api.get(
        getURI(`/qualification/details/filter`, {
          filterToReturn: 'category',
          project: params.project,
        })
      );
      if (statusCode === 200) setCategories(formatDataToSelect(data, true));
      else setCategories([]);
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message });
    }
  };

  const fetchSpecifications = async () => {
    try {
      const { status: statusCode, data } = await api.get(
        getURI(`/qualification/details/filter`, {
          filterToReturn: 'specification',
          project: params.project,
          category: params.category,
        })
      );
      if (statusCode === 200) setSpecifications(formatDataToSelect(data, true));
      else setSpecifications([]);
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message });
    }
  };

  const fetchPartNumbers = async () => {
    try {
      const { status: statusCode, data } = await api.get(
        getURI(`/qualification/details/filter`, {
          filterToReturn: 'partNumber',
          project: params.project,
          category: params.category,
          specification: params.specification,
        })
      );
      if (statusCode === 200) setPartNumbers(formatDataToSelect(data, true));
      else setPartNumbers([]);
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message });
    }
  };

  const fetchSuppliers = async () => {
    try {
      const { status: statusCode, data } = await api.get(
        getURI(`/qualification/details/filter`, {
          filterToReturn: 'supplier',
          project: params.project,
          category: params.category,
          specification: params.specification,
          partNumber: params.partNumber,
        })
      );
      if (statusCode === 200) setSuppliers(formatDataToSelect(data, true));
      else setSuppliers([]);
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message });
    }
  };

  const fetchStatus = async () => {
    try {
      const { status: statusCode, data } = await api.get(
        getURI(`/qualification/details/filter`, {
          filterToReturn: 'status',
          project: params.project,
          category: params.category,
          specification: params.specification,
          partNumber: params.partNumber,
          supplier: params.supplier,
        })
      );
      if (statusCode === 200) setStatus(formatDataToSelect(data, true));
      else setStatus([]);
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message });
    }
  };

  const fetchData = async (paramsProps: IParams) => {
    try {
      setIsLoading(true);
      const { status: statusCode, data } = await api.get(
        getURI(`/qualification/details`, {
          ...paramsProps,
        })
      );
      if (statusCode === 200) {
        setQualificationData(data.content[0]);
        setTotalElements(data.totalElements);
      } else setQualificationData([]);
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRenderClearTags = () => {
    const showClearFiltes =
      params.project ||
      params.category ||
      params.specification ||
      params.partNumber ||
      params.supplier ||
      params.status;
    if (showClearFiltes)
      return (
        <StyledClearTag
          onClick={() => {
            setParams(initialParams);
            fetchData(initialParams);
          }}
        />
      );
  };

  const columns: ColumnsType<IQualificationData> = [
    {
      title: t('pages.qualification.tableColumns.idQualification'),
      align: 'left',
      dataIndex: 'qualificationName',
      key: 'qualificationName',
      width: '9.5rem',
    },
    {
      title: t('pages.qualification.tableColumns.project'),
      align: 'left',
      dataIndex: 'project',
      key: 'project',
      width: '8.5rem',
      render: (_, record) => {
        return <Button type="link">{record.project}</Button>;
      },
    },
    {
      title: t('pages.qualification.tableColumns.category'),
      align: 'left',
      dataIndex: 'category',
      key: 'category',
      width: '9.5rem',
    },
    {
      title: t('pages.qualification.tableColumns.specification'),
      align: 'left',
      dataIndex: 'specification',
      key: 'specification',
      width: '9.5rem',
    },
    {
      title: t('pages.qualification.tableColumns.acerPN'),
      align: 'left',
      dataIndex: 'partNumber',
      key: 'partNumber',
      width: '9.8rem',
      render: (_, record) => {
        return <S.PartNumber>{record.partNumber}</S.PartNumber>;
      },
    },
    {
      title: t('pages.qualification.tableColumns.suppliers'),
      align: 'left',
      dataIndex: 'supplier',
      key: 'supplier',
      width: '10rem',
    },
    {
      title: t('pages.qualification.tableColumns.ODM'),
      align: 'left',
      dataIndex: 'odm',
      key: 'odm',
      width: '8.5rem',
    },
    {
      title: t('pages.qualification.tableColumns.qtySamples'),
      align: 'left',
      dataIndex: 'sampleQuantity',
      key: 'sampleQuantity',
      width: '5rem',
      render: (_, record) => {
        return (
          <span>
            {handleFormatValueFields({
              number: record.sampleQuantity,
              maxFactional: 0,
              minFractional: 0,
            })}
          </span>
        );
      },
    },
    {
      title: t('pages.qualification.tableColumns.createdOn'),
      align: 'left',
      dataIndex: 'creationDate',
      key: 'creationDate',
      sorter: true,
      width: '10.25rem',
      render: (_, record) => {
        return <span>{dayjs(record.creationDate).format('MM/DD/YYYY - HH:mm')}</span>;
      },
    },
    {
      title: t('pages.qualification.tableColumns.status'),
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      width: '8rem',
      render: (_, record) => {
        return <TagStatusIndication status={record.status} />;
      },
    },
    {
      title: t('pages.qualification.tableColumns.action'),
      align: 'center',
      width: '6rem',
      render: (_, record) => {
        return <TableAction dropdownItems={[]} placement="bottom" />;
      },
    },
  ];

  useEffect(() => {
    if (isFirstRendering) return;
    setParams((prev) => {
      return {
        ...prev,
        category: null,
        specification: null,
        partNumber: null,
        supplier: null,
        status: null,
        page: 0,
      };
    });
    Promise.all([
      fetchCategories(),
      fetchSpecifications(),
      fetchPartNumbers(),
      fetchSuppliers(),
      fetchStatus(),
    ]);
  }, [params.project]);

  useEffect(() => {
    if (isFirstRendering) return;
    setParams((prev) => {
      return {
        ...prev,
        specification: null,
        partNumber: null,
        supplier: null,
        status: null,
        page: 0,
      };
    });
    Promise.all([fetchSpecifications(), fetchPartNumbers(), fetchSuppliers(), fetchStatus()]);
  }, [params.category]);

  useEffect(() => {
    if (isFirstRendering) return;
    setParams((prev) => {
      return {
        ...prev,
        partNumber: null,
        supplier: null,
        status: null,
        page: 0,
      };
    });
    Promise.all([fetchPartNumbers(), fetchSuppliers(), fetchStatus()]);
  }, [params.specification]);

  useEffect(() => {
    if (isFirstRendering) return;
    setParams((prev) => {
      return {
        ...prev,
        supplier: null,
        status: null,
        page: 0,
      };
    });
    Promise.all([fetchSuppliers(), fetchStatus()]);
  }, [params.partNumber]);

  useEffect(() => {
    if (isFirstRendering) return;
    setParams((prev) => {
      return {
        ...prev,
        status: null,
        page: 0,
      };
    });
    Promise.all([fetchStatus()]);
  }, [params.supplier]);

  useEffect(() => {
    fetchData(params);
  }, [params.page, params.size, params.direction, params.sortBy]);

  useEffect(() => {
    if (isFirstRendering) {
      Promise.all([
        fetchProjects(),
        fetchCategories(),
        fetchSpecifications(),
        fetchPartNumbers(),
        fetchSuppliers(),
        fetchStatus(),
        fetchData(params),
      ]).finally(() => setIsFirstRendering(false));
    }
  }, []);

  return {
    params,
    onChangeParams,
    handleRenderTags,
    columns,
    onChangePage,
    projects,
    categories,
    specifications,
    partNumbers,
    suppliers,
    status,
    fetchData,
    qualificationData,
    handleChangeTable,
    isLoading,
    totalElements,
    handleRenderClearTags,
  };
}
