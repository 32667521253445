import { DefaultOptionType } from 'antd/es/select';
import { useState } from 'react';
import { api } from '../../../services/api';
import { services } from '../../../services/services';

export const useUserSelect = () => {
  const [users, setUsers] = useState<DefaultOptionType[]>([]);
  const getUsers = async () => {
    if (users.length === 0) {
      try {
        const { data } = await api.get(
          `${import.meta.env.VITE_API_LOGIN_UNICO}${services.singleLogin}/user-consult`
        );
        const parsedUsers: DefaultOptionType[] = data.map((user: any) => ({
          label: user.name,
          value: user.email,
        }));
        setUsers(parsedUsers);
      } catch (error) {
        console.log('Error on the fetch Users', error);
      }
    }
  };

  return { users, getUsers };
};
