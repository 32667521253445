import { ReactNode } from 'react';
import { NotificationsProvider } from './notification';
import { CmsProvider } from './CmsContext';
import { YearsScenariosContextProvider } from './YearsScenariosContext';

type Props = {
  children: ReactNode;
};

export const AppProviders = ({ children }: Props) => {
  return (
    <NotificationsProvider>
      <CmsProvider>
        <YearsScenariosContextProvider>{children}</YearsScenariosContextProvider>
      </CmsProvider>
    </NotificationsProvider>
  );
};
