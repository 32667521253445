import {
  DollarOutlined,
  FundOutlined,
  InfoCircleFilled,
  PieChartOutlined,
} from '@ant-design/icons';
import { Divider, Flex, Tooltip } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReceiptImg from '../../../../assets/receipt.svg';

import { StyledLoading } from '../../../../components/Common/StyledLoading';
import { handleFormatValueFields } from '../../../../helpers/nUtils';
import { IParams } from '../types';
import * as S from './styles';
import { useTotalAwards } from './useTotalAwards';

export function TotalAwards({ altGroup, category, supplier, idBid }: IParams) {
  const { t } = useTranslation();
  const { isLoading, totalAwardsInformation, fetchData } = useTotalAwards();

  useEffect(() => {
    fetchData({ altGroup, category, supplier, idBid });
  }, [altGroup, category, supplier]);

  return (
    <S.Container>
      <S.TitleContainer>
        <span>{t('pages.awards.managementBid.totalAwards.title')}</span>
      </S.TitleContainer>
      <S.Content>
        {isLoading ? (
          <StyledLoading height={10} />
        ) : (
          <>
            {/* TOTAL DEMANDS */}
            <S.Card>
              <Flex gap={12} align="center">
                <S.CardIconContent>
                  <PieChartOutlined />
                </S.CardIconContent>
                <S.CardTitle>{t('pages.awards.managementBid.totalAwards.totalDemand')}</S.CardTitle>
              </Flex>
              <S.CardValue>
                {handleFormatValueFields({
                  number: totalAwardsInformation?.totalDemands,
                  maxFactional: 0,
                  minFractional: 0,
                })}
              </S.CardValue>
            </S.Card>

            {/* TOTAL WIN  NET */}
            <S.Card>
              <Flex gap={12} align="center">
                <S.CardIconContent>
                  <img src={ReceiptImg} alt="Recepit Icon" />
                </S.CardIconContent>
                <S.CardTitle>{t('pages.awards.managementBid.totalAwards.totalWinNet')}</S.CardTitle>
                {!totalAwardsInformation?.taxesEmpty && (
                  <Tooltip
                    title={t('pages.awards.managementBid.totalAwards.outdatedTax')}
                    placement="leftBottom"
                  >
                    <InfoCircleFilled className="taxesEmpty" />
                  </Tooltip>
                )}
              </Flex>
              <S.CardValue>
                ${handleFormatValueFields({ number: totalAwardsInformation?.totalNet })}
              </S.CardValue>
            </S.Card>

            {/* TOTAL WIN  GRPSS */}
            <S.Card>
              <Flex gap={12} align="center">
                <S.CardIconContent>
                  <DollarOutlined />
                </S.CardIconContent>
                <S.CardTitle>
                  {t('pages.awards.managementBid.totalAwards.totalWinGross')}
                </S.CardTitle>
              </Flex>
              <S.CardValue>
                ${handleFormatValueFields({ number: totalAwardsInformation?.totalGross })}
              </S.CardValue>
            </S.Card>

            {/* IMPACT/SAVING*/}
            <S.Card>
              <Flex gap={12} align="center">
                <S.CardIconContent>
                  <FundOutlined />
                </S.CardIconContent>
                <S.CardTitle>
                  {t('pages.awards.managementBid.totalAwards.impactSavingEstimate')}
                </S.CardTitle>
              </Flex>
              <S.ImpactSavingPercent>
                {handleFormatValueFields({
                  number: totalAwardsInformation?.impactSavingPercentage,
                })}
                %
              </S.ImpactSavingPercent>
              <Divider type="vertical" />
              <Tooltip
                title={handleFormatValueFields({
                  number: totalAwardsInformation?.impactSaving,
                })}
              >
                <S.ImpactSavingValue value={totalAwardsInformation?.impactSaving}>
                  $
                  {handleFormatValueFields({
                    number: totalAwardsInformation?.impactSaving,
                    notation: 'compact',
                    minFractional: 1,
                    maxFactional: 2,
                  })}
                </S.ImpactSavingValue>
              </Tooltip>
            </S.Card>
          </>
        )}
      </S.Content>
    </S.Container>
  );
}
