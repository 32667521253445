import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { oneAlert } from '../../../../helpers/nUtils';
import { api } from '../../../../services/api';
import { services } from '../../../../services/services';
import { IError } from '../../types';

export function useTermsAndConditions(bidId: string) {
  const { t } = useTranslation();
  const [termsId, setTermsId] = useState<string>();
  const [initialValue, setInitialValue] = useState<string>();
  const [html, setHTML] = useState<string>();
  const [countText, setCountText] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchTermsAndConditions = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get(`${services.rfq}/bid/${bidId}/termsAndConditions`);
      if (status === 200) {
        setInitialValue(data.termsAndConditions);
        setTermsId(data.id);
      }
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse?.messageDetail || t('toast.errorOnList'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const saveTermsAndConditions = async (): Promise<AxiosResponse<string> | undefined> => {
    try {
      setIsLoading(true);
      if (termsId) {
        const response = await api.put(
          `${services.rfq}/bid/${bidId}/termsAndConditions/update`,
          html,
          {
            headers: {
              'Content-Type': 'text/plain',
            },
          }
        );
        return response;
      }
      const response = await api.post(`${services.rfq}/bid/${bidId}/termsAndConditions/add`, html, {
        headers: {
          'Content-Type': 'text/plain',
        },
      });
      return response;
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse?.messageDetail || t('toast.errorOnSave'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTermsAndConditions();
  }, []);

  return {
    html,
    setHTML,
    fetchTermsAndConditions,
    saveTermsAndConditions,
    initialValue,
    isLoading,
    setCountText,
    countText,
  };
}
