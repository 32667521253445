import React from 'react';
import { Col, Input, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LateralFilter } from '../../components/LateralFilter';
import { SelectInput } from '../../components/LateralFilter/SelectInput';
import { useYearsScenariosContext } from '../../context/YearsScenariosContext';

export default function ScenariosFilter({ clearFilter, searchFilter, values, title }) {
  const { t } = useTranslation();
  const { yearsScenarios } = useYearsScenariosContext();

  return (
    <LateralFilter
      onClearFilter={clearFilter}
      onSearch={searchFilter}
      values={values}
      title={title}
    >
      <Row gutter={[8]} data-cy="filter-form">
        <Col span={24}>
          <Form.Item label={t('pages.scenarios.fields.name')} name="name">
            <Input placeholder={t('pages.scenarios.fields.name')} />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item label={t('pages.scenarios.fields.targetYear')} name="targetYear">
            <SelectInput
              placeholder={t('pages.scenarios.fields.targetYear')}
              options={yearsScenarios}
            />
          </Form.Item>
        </Col>
      </Row>
    </LateralFilter>
  );
}
