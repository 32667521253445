import { TableColumnsType, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { HttpStatusCode } from 'axios';
import { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { handleFormatValueFields } from '../../../helpers/nUtils';
import { getSortOrder, getURI, oneAlert } from '../../../helpers/utils';
import { api } from '../../../services/api';
import { services } from '../../../services/services';
import * as S from './styles';
import { BidAllocationParams, DataTables, SuppliersData } from './types';

export function useBidAllocation() {
  const { id: bidId } = useParams<{ id: string }>();
  const initialParams: BidAllocationParams = {
    sortDirection: 'asc',
    sortField: 'gruop',
  };
  const [params, setParams] = useState(initialParams);
  const [bidAllocationData, setBidAllocationData] = useState<DataTables[]>([]);
  const [isLoding, setIsLoading] = useState<boolean>(false);
  const [dataType, setDataType] = useState<string>('QTY');

  const options = [
    { label: 'GROSS', value: 'GROSS' },
    { label: 'NET', value: 'NET' },
    { label: 'QTY', value: 'QTY' },
    { label: 'IMP/SAV', value: 'IMPSAV' },
  ];
  const { t } = useTranslation();

  const fetchBidAllocationData = async () => {
    try {
      setIsLoading(true);
      const { data, status }: { data: Array<DataTables>; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/bid/allocation/${bidId}`, {
          fieldToReturn: dataType.toLowerCase(),
          sort: params.sortDirection === 'asc',
        })
      );
      if (status === 200) {
        return setBidAllocationData(data);
      }
      setBidAllocationData([]);
    } catch (error: any) {
      console.log(error);
      setBidAllocationData([]);
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  const removeDuplicates = (data: string[][]) => {
    let largestArray: string[] = [];

    data.forEach((subArray) => {
      if (subArray.length > largestArray.length) {
        largestArray = subArray;
      }
    });

    return largestArray;
  };

  const generateSupplierColumns = (data: Array<DataTables>): TableColumnsType<DataTables> => {
    const suppliers = data.map((items) =>
      items.supplier.map((supplier: SuppliersData) => supplier.name)
    );
    return removeDuplicates(suppliers).map((supplier: string) => ({
      title: <S.HeadersTables>{supplier}</S.HeadersTables>,
      dataIndex: 'supplier',
      align: 'right',
      key: supplier,
      width: 150,
      render: (value: SuppliersData[], record) => {
        const supplierData = value.find((item) => item.name === supplier);

        return record.altGroup === 'Total' ? (
          supplierData ? (
            <S.TotalValues>
              {dataType !== 'QTY' && '$ '}
              {handleFormatValueFields({
                number: supplierData.value,
                maxFactional: dataType === 'QTY' ? 0 : 2,
                minFractional: dataType === 'QTY' ? 0 : 2,
              })}
            </S.TotalValues>
          ) : (
            <S.ValuesContainer> - </S.ValuesContainer>
          )
        ) : supplierData ? (
          <S.ValuesContainer>
            {dataType !== 'QTY' && '$ '}
            {handleFormatValueFields({
              number: supplierData.value,
              maxFactional: dataType === 'QTY' ? 0 : 2,
              minFractional: dataType === 'QTY' ? 0 : 2,
            })}
          </S.ValuesContainer>
        ) : (
          <S.ValuesContainer> - </S.ValuesContainer>
        );
      },
    }));
  };

  const generateSupplierColumnsPercent = (
    data: Array<DataTables>
  ): TableColumnsType<DataTables> => {
    const suppliers = data.map((items) =>
      items.supplier.map((supplier: SuppliersData) => supplier.name)
    );
    return removeDuplicates(suppliers).map((supplier: string) => ({
      title: <S.HeadersTables>{supplier}</S.HeadersTables>,
      dataIndex: 'supplier',
      align: 'right',
      key: supplier,
      width: 130,

      render: (value: SuppliersData[], record: { altGroup: string }) => {
        const supplierData = value.find((item) => item.name === supplier);
        return record.altGroup === 'Total' ? (
          supplierData ? (
            <S.TotalValues>{`${supplierData.percentage}%`}</S.TotalValues>
          ) : (
            <S.ValuesContainer> - </S.ValuesContainer>
          )
        ) : supplierData ? (
          <S.ValuesContainer>{`${supplierData.percentage}%`} </S.ValuesContainer>
        ) : (
          <S.ValuesContainer> - </S.ValuesContainer>
        );
      },
    }));
  };

  const handleChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<DataTables[]>
  ) => {
    setParams({
      ...params,
      sortField: sorter.field as Key,
      sortDirection: getSortOrder(sorter.order).toLowerCase(),
    });
  };

  const columns: TableColumnsType<DataTables> = [
    {
      title: <S.HeadersTables>Alt Group</S.HeadersTables>,
      dataIndex: 'altGroup',
      key: 'altGroup',
      align: 'left',
      fixed: 'left',
      width: 300,
      render: (_, record) => {
        return record.altGroup === 'Total' ? (
          <S.TotalValues>{record.altGroup}</S.TotalValues>
        ) : (
          <S.TotalValues>{record.altGroup}</S.TotalValues>
          //   <S.AltGroupLink onClick={(e) => console.log((e.target as HTMLAnchorElement).textContent)}>
          //     {record.altGroup}
          //   </S.AltGroupLink>
        );
      },
    },
    ...generateSupplierColumns(bidAllocationData),
    {
      title: <S.HeaderTotalDemand>Total Demand</S.HeaderTotalDemand>,
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      fixed: 'right',
      width: 170,
      className: 'highlighted-column',
      render: (_, record) => {
        return record.altGroup === 'Total' ? (
          <S.TotalValues>
            {dataType !== 'QTY' && '$ '}
            {handleFormatValueFields({
              number: record.total,
              maxFactional: dataType === 'QTY' ? 0 : 2,
              minFractional: dataType === 'QTY' ? 0 : 2,
            })}
          </S.TotalValues>
        ) : (
          <S.ValuesContainer>
            {dataType !== 'QTY' && '$ '}
            {handleFormatValueFields({
              number: record.total,
              maxFactional: dataType === 'QTY' ? 0 : 2,
              minFractional: dataType === 'QTY' ? 0 : 2,
            })}
          </S.ValuesContainer>
        );
      },
    },
  ];

  useEffect(() => {
    fetchBidAllocationData();
  }, [params.sortDirection, params.sortField, dataType]);

  return {
    options,
    columns,
    bidAllocationData,
    isLoding,
    dataType,
    setDataType,
    fetchBidAllocationData,
    generateSupplierColumnsPercent,
    handleChangeTable,
    params,
  };
}
