import jwtDecode from 'jwt-decode';
import { routesConfig } from '../routes/config';
import { api } from './api';

import { services } from './services';
import { JWTPemisions, TokenPayload } from './types';

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken') || '';
};

export const getAccessToken = () => {
  return localStorage.getItem('accessToken') || '';
};

export const getIdToken = () => {
  return localStorage.getItem('idToken') || '';
};

export const getSession = () => {
  return localStorage.getItem('session') || '';
};

export const getPermissionsToken = () => {
  return localStorage.getItem('permissions');
};

export const getPermissions = () => {
  try {
    const getPerms = localStorage.getItem('permissions');
    if (!getPerms) return null;

    if (getPerms.toUpperCase() === 'ADMIN') return getPerms;

    if (getPerms.includes(';')) {
      const allPermissions = getPerms.split(';').map((permission) => permission.split('_')[0]);
      return allPermissions;
    }

    //caso seja uma única permissão
    return [getPerms.split('_')[0]];
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getUsernameId = () => {
  const session = localStorage.getItem('idToken');
  if (!session) return;

  const sessionName: any = jwtDecode(session);

  return sessionName['cognito:username'];
};

export const cleanTokens = () => {
  localStorage.setItem('accessToken', '');
  localStorage.setItem('refreshToken', '');
  localStorage.setItem('idToken', '');
  localStorage.setItem('session', '');
  localStorage.setItem('permissions', '');
  localStorage.setItem('userId', '');
};

export const setSession = (tokenPayload: TokenPayload) => {
  if (tokenPayload.session) {
    localStorage.setItem('session', tokenPayload.session);
  }
};

export const saveUserId = (userId: string) => {
  userId && localStorage.setItem('userId', userId);
};

export const getUserId = (): string => {
  return localStorage.getItem('userId') || '';
};

export const setAccessTokens = (tokenPayload: TokenPayload) => {
  if (tokenPayload.accessToken) {
    localStorage.setItem('accessToken', tokenPayload.accessToken);
  }

  if (tokenPayload.refreshToken) {
    localStorage.setItem('refreshToken', tokenPayload.refreshToken);
  }

  if (tokenPayload.idToken) {
    localStorage.setItem('idToken', tokenPayload.idToken);
  }

  if (tokenPayload.permissions) {
    localStorage.setItem('permissions', tokenPayload.permissions);
  }
};

export const isTokenTimeValid = () => {
  const currentAccessToken: JWTPemisions = jwtDecode(getAccessToken());
  return currentAccessToken.exp > new Date().getTime() / 1000;
};

export const refreshToken = async (success: any) => {
  if (isTokenTimeValid()) {
    if (success) success();
    return;
  }

  const response = await api.post(
    `${import.meta.env.VITE_API_LOGIN_UNICO}${services.singleLogin}/refresh-token`,
    {
      refresh_token: getRefreshToken(),
      user_id: getUserId(),
    }
  );

  setAccessTokens(response.data);
  if (success) success();
};

export const logout = async (urlPath = '/') => {
  try {
    const idToken = getIdToken();
    const permissionsToken = getPermissionsToken();

    if (idToken && permissionsToken) {
      await api.post(`userlogs/logout`, null, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          Permissions: permissionsToken,
        },
      });
    }

    cleanTokens();
    window.location.href = urlPath;
  } catch (e) {
    console.log('errorOnLogout=>', e);
    cleanTokens();
    window.location.href = urlPath;
  }
};

export function isAuthenticated() {
  return getAccessToken();
}

export const isAuthRequest = (requestURL: string) => {
  return (
    typeof requestURL === 'string' &&
    (requestURL.includes('/refresh-token') || requestURL.includes('/session-expired'))
  );
};

// export const getUserGroup = () => {
//   const accessToken = getAccessToken();

//   if (!accessToken) {
//     return null;
//   }

//   const decodedToken: any = jwtDecode(accessToken);

//   const userGroup = decodedToken['cognito:groups'][0];

//   return userGroup;
// };

// export const routePermissions = {
//   admin: [],
//   supervisor: [
//     { route: '/scenarios', path: '/scenarios' },
//     { route: '/simulation', path: '/simulation' },
//     { route: '/forecasts', path: '/forecasts' },
//     { route: '/prices', path: '/prices' },
//     { route: '/boms', path: '/boms' },
//     { route: '/suppliers', path: '/suppliers' },
//     { route: '/sku', path: '/sku' },
//     { route: '/components', path: '/components' },
//     { route: '/ppb', path: '/ppb' },
//     { route: '/reports', path: '/reports/scenarios' },
//   ],
//   parceiro: [
//     { route: '/forecasts', path: '/forecasts' },
//     { route: '/boms', path: '/boms' },
//     { route: '/suppliers', path: '/suppliers' },
//     { route: '/sku', path: '/sku' },
//     { route: '/components', path: '/components' },
//     { route: '/ppb', path: '/ppb' },
//   ],
//   convidado: [{ route: '/reports', path: '/reports/scenarios' }],
//   'T.I': [{ route: '/fileerrors', path: '/fileerrors' }],
// };

export const getAllowedRoutes = () => {
  const permissions = getPermissions();

  if (!permissions) return [];

  const allowedRoutes = routesConfig.routes.filter((route) => permissions.includes(route.route));

  return allowedRoutes;
};

export const handleVerifyUserIsSupplier = (): boolean => {
  const allowedRoutes = getAllowedRoutes();

  if (allowedRoutes.length > 3) return false;
  return allowedRoutes.some(
    (route) =>
      route.route === 'bidresponse' || route.route === 'bidsupplierview' || route.route === 'award'
  );
};
