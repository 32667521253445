import { DeleteOutlined } from '@ant-design/icons';

import * as S from './styles';
import { ContactTagProps } from './types';

import StyledButton from '../../../../../components/Common/StyledButton';

export function ContactTag({ contact, supplier, onDelete, email }: ContactTagProps) {
  return (
    <S.Container>
      <S.DataContainer>
        <S.Contact>{contact}</S.Contact>
        <S.Email>{email}</S.Email>
        <S.Supplier>{supplier}</S.Supplier>
      </S.DataContainer>
      <StyledButton variant="slim" onClick={onDelete}>
        <DeleteOutlined />
      </StyledButton>
    </S.Container>
  );
}
