import React from 'react';
import { Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LateralFilter } from '../../components/LateralFilter';
import { SelectInput } from '../../components/LateralFilter/SelectInput';
import { useYearsScenariosContext } from '../../context/YearsScenariosContext';

export default function PricesFilter({
  clearFilter,
  searchFilter,
  scenariosOptions,
  values,
  title,
}) {
  const { t } = useTranslation();
  const { yearsScenarios } = useYearsScenariosContext();

  return (
    <LateralFilter
      title={title}
      onClearFilter={clearFilter}
      onSearch={searchFilter}
      values={values}
      searchFieldsOnChange={['targetYear']}
    >
      <Row gutter={[8]} data-cy="filter-form">
        <Col span={8}>
          <Form.Item label={t('filter.year')} name="targetYear" rules={[{ required: true }]}>
            <SelectInput placeholder={t('filter.year')} options={yearsScenarios} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t('filter.scenario')} name="scenario" rules={[{ required: true }]}>
            <SelectInput placeholder={t('filter.scenario')} options={scenariosOptions} />
          </Form.Item>
        </Col>
      </Row>
    </LateralFilter>
  );
}
