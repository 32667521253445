import { Layout } from 'antd';
import { Redirect, Route } from 'react-router-dom';
import { NavBar } from '../components/Layout/NavBar';
import { CmsProvider } from '../context/CmsContext';
import { NotificationsProvider } from '../context/notification';
import { cleanTokens, getPermissions, isAuthenticated } from '../services/auth';
import { routesConfig } from './config';
import { RoutesPath } from './routesPath';
import { PrivateRouteProps } from './types';
import { AppProviders } from '../context/AppProvider';

const { Content } = Layout;

export function PrivateRoute({ component: Component, ...rest }: PrivateRouteProps) {
  const permissions = getPermissions();

  if (!permissions) {
    cleanTokens();
    return <Redirect to={{ pathname: RoutesPath.login }} />;
  }

  const allowedRoutes = routesConfig.routes.filter((route) => permissions?.includes(route.route));

  const splitPath = rest.path.split('/');
  const path = splitPath.length > 2 ? `${splitPath[1]}/${splitPath[2]}` : splitPath[1];

  const removePadding =
    path === 'boms' || path === 'scenarios' || path === 'demands' || path === 'prices';

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated()) {
          if (permissions === 'admin') {
            return (
              <AppProviders>
                <Layout>
                  <NavBar />
                  <Layout>
                    <Content style={{ padding: !removePadding ? '1.5rem 2.5rem' : '0' }}>
                      <Component {...props} />
                    </Content>
                  </Layout>
                </Layout>
              </AppProviders>
            );
          }

          const allowedRoute = allowedRoutes.find(
            (route) => route.route === path || route.path.slice(1) === path
          );
          if (allowedRoute && allowedRoute.path && allowedRoute.path === rest.path) {
            return (
              <AppProviders>
                <Layout>
                  <NavBar />
                  <Layout>
                    <Content style={{ padding: !removePadding ? '1.5rem 2.5rem' : '0' }}>
                      <Component {...props} />
                    </Content>
                  </Layout>
                </Layout>
              </AppProviders>
            );
          }

          return (
            <Redirect to={{ pathname: allowedRoutes[0].path, state: { from: props.location } }} />
          );
        }

        return <Redirect to={{ pathname: RoutesPath.login, state: { from: props.location } }} />;
      }}
    />
  );
}
