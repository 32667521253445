import { FormInstance } from 'antd';
import { useEffect } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaRegUserCircle, FaSearch, FaUserCircle } from 'react-icons/fa';
import { UserTag } from '../../UserTag';
import { Select } from './styles';
import { useUserSelect } from './useUserSelect';

export const UsersSelect = ({ form, ...props }: { form: FormInstance } & any) => {
  const { users, getUsers } = useUserSelect();

  useEffect(() => {
    getUsers();
  }, []);

  const tagRender = (tagProps: any) => {
    const { label, value, closable, onClose } = tagProps;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const isUser = users.find((user) => user.value === value);
    const labelText = label?.props ? label?.props.children[1] : label;
    return (
      <UserTag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
        icon={
          isUser?.label ? (
            <FaUserCircle color="#8DC55B" size={18} />
          ) : (
            <FaRegUserCircle color="#8DC55B" size={18} />
          )
        }
        closeIcon={
          <AiOutlineCloseCircle size={18} style={{ alignSelf: 'center' }} color="#8DC55B" />
        }
      >
        {isUser?.label || labelText}
      </UserTag>
    );
  };

  const filteredOptions = users.filter(
    (u) => !props?.value?.map((a: any) => a.value)?.includes(u.value as string)
  );

  return (
    <Select
      mode="tags"
      showSearch
      tagRender={tagRender}
      suffixIcon={<FaSearch color="#8DC55B" size={18} />}
      // showArrow
      loading={users.length === 0}
      disabled={users.length === 0}
      labelInValue
      onSelect={(e: any) => {
        const emails = form.getFieldValue('emails');
        !e.label && emails.push(e.value);
        form.setFieldValue('emails', emails);
      }}
      onDeselect={(e: any) => {
        const emails = form.getFieldValue('emails');
        emails.filter((em: any) => em !== e.value);
        form.setFieldValue('emails', emails);
      }}
      {...props}
    >
      {filteredOptions?.map((user) => (
        <Select.Option key={user.value} value={user.value} label={user.label}>
          {!user?.label?.toString().includes('@') ? (
            <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <FaUserCircle color="#8DC55B" size={18} /> {user.label}
            </span>
          ) : (
            <span>
              <FaRegUserCircle color="#8DC55B" size={18} /> {user.label}
            </span>
          )}
        </Select.Option>
      ))}
    </Select>
  );
};
