import { Flex } from 'antd';
import styled from 'styled-components';
import StyledButton from '../../components/Common/StyledButton';

export const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

export const Description = styled(Flex)`
  flex-direction: column;
  align-items: center;

  span {
    font-size: 1.5rem;
    font-weight: 300;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    line-height: 2rem;
  }
`;

export const Button = styled(StyledButton)`
  width: 22.5rem;
  border-radius: 0.5rem;
`;
