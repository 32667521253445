import { SendOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import styled from 'styled-components';

export const TitlePage = styled.p`
  font-weight: 600;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  &.subTitle {
    color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  
  flex-direction: column;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  width: 50%;
  height: 48.688rem;
  padding-bottom: 1rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const HeaderContainers = styled.div`
  display: flex;
  flex-direction: row;
  height: 4.625rem;
  padding: 1.5rem;
  border-top-right-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  p {
    font-size: 1.25rem;
    font-weight: bold;
    &.subTitle {
      font-weight: normal;
    }
  }
`;

export const ContainerPadding = styled(Flex)`
  padding: 1.5rem;
  gap: 1.5rem;
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
`;
export const ContainerCardsRight = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
`;

export const Title = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  margin: 0;
  &.subTitle {
    font-weight: 300;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-inline: 1.5rem;
  align-items: center;
  gap: 1rem;
  hr {
    flex-grow: 1;
    border: none;
    height: 1px;
    background-color: #e5e8ec;
  }
`;

export const CardsSuppliersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 1.5rem;
  margin-top: 1rem;
  gap: 1rem;
  overflow-y: auto;
`;

export const SendIcon = styled(SendOutlined)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorsDesignSystem.white};
`;

export const LabelBidSupplier = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
  &.subTitle {
    font-weight: normal;
  }
`;
