import { Flex } from 'antd';
import { t } from 'i18next';
import { useEffect } from 'react';
import StyledButton from '../../components/Common/StyledButton';
import StyledModal from '../../components/Common/StyledModal';
import { Loading } from '../../components/Loading';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import CardPrices from './CardPrices';
import ModalSendAward from './ModalSendAward';
import * as S from './styles';
import { useAwardSimulation } from './useAwardSimulation';

export function AwardSimulation() {
  useDocumentTitle(t('pages.viewRfq.title'));

  const {
    fetchAward,
    handleRenderBidSuppliersCard,
    handleRenderDistributionCard,
    handleVerifyIfCanSendAward,
    setVisibleModal,
    handleSendAward,
    visibleModal,
    awardData,
    loading,
    bidId,
  } = useAwardSimulation();

  useEffect(() => {
    fetchAward();
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Flex justify="flex-end" gap={10}>
            <StyledButton
              onClick={() => {
                setVisibleModal(true);
              }}
              disabled={handleVerifyIfCanSendAward()}
              variant="primary"
            >
              <S.SendIcon />
              {t('pages.awardSimulation.labels.send')}
            </StyledButton>
          </Flex>
          <Flex gap={24}>
            <S.ContentContainer>
              <S.HeaderContainers>
                <Flex gap={4}>
                  <p>{t('pages.awardSimulation.labels.total')}</p>
                  <p className="subTitle">{t('pages.awardSimulation.labels.prices')}</p>
                </Flex>
              </S.HeaderContainers>
              <S.ContainerPadding vertical>
                <S.ContainerCards>
                  <CardPrices
                    type="NET"
                    value={awardData?.totalNetPrices.totalWin}
                    outdated={awardData?.totalNetPrices.outdatedTaxesInformation}
                  />
                  <CardPrices type="BASELINE" value={awardData?.totalNetPrices.totalBaseline} />
                </S.ContainerCards>
                <S.ContainerCards>
                  <CardPrices type="FOB" value={awardData?.totalNetPrices.totalFOB} />
                  <CardPrices
                    type="ESTIMATE"
                    impactSavingEstimatePercentage={
                      awardData?.totalNetPrices.impactSavingEstimatePercentage
                    }
                    impactSavingEstimateTotal={awardData?.totalNetPrices.impactSavingEstimateTotal}
                  />
                </S.ContainerCards>
              </S.ContainerPadding>
              <S.TitleContainer>
                <Flex gap={6}>
                  <S.LabelBidSupplier>{t('pages.awardSimulation.labels.total')}</S.LabelBidSupplier>
                  <S.LabelBidSupplier className="subTitle">
                    {t('pages.awardSimulation.labels.bidSuppliers')}
                  </S.LabelBidSupplier>
                </Flex>
                <hr />
              </S.TitleContainer>
              <S.CardsSuppliersContainer>
                {handleRenderBidSuppliersCard(awardData?.totalBIDSuppliers || [])}
              </S.CardsSuppliersContainer>
            </S.ContentContainer>
            <S.ContentContainer>
              <S.HeaderContainers>
                <Flex gap={4}>
                  <p>{t('pages.awardSimulation.labels.award')}</p>
                  <p className="subTitle">{t('pages.awardSimulation.labels.distribution')}</p>
                </Flex>
              </S.HeaderContainers>
              <Flex style={{ padding: '1.5rem' }}>
                {handleRenderDistributionCard(awardData?.awardDistribution || [])}
              </Flex>
            </S.ContentContainer>
          </Flex>
        </>
      )}
      <StyledModal
        title={t('pages.awardSimulation.modalSendAwards.title')}
        open={visibleModal}
        width="46.812rem"
        onClose={() => {
          setVisibleModal(false);
        }}
        footer={null}
        body={<ModalSendAward handleSaveAward={handleSendAward} onClose={setVisibleModal} />}
      />
    </div>
  );
}
