import { Button, Form, Input, Popconfirm, Space, Tooltip } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose, AiOutlineQuestionCircle } from 'react-icons/ai';
import {
  getSortOrder,
  oneAlert,
  filterAndUrlParams,
  getURI,
  parseScenariosLite,
  getErrorMessage,
  parseBaseScenarios,
  debounce,
  isFobOrBr,
  hasPropertyWithHeader,
} from '../../helpers/utils';
import { api } from '../../services/api';
import * as SC from '../../styles/common';
import { ComponentsTypeSelect } from '../../components/forms/ComponentsTypeSelect';
import ComponentsSelect from '../../components/forms/ComponentsSelect';
import { getColumnOptionProps, getColumnSearchProps } from '../../helpers/table';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import { Button as TableActionButton } from '../../components/Button';
import { useCmsContext } from '../../context/CmsContext';
import { useYearsScenariosContext } from '../../context/YearsScenariosContext';

export function useBOM(dataModal = null) {
  const localTargetYear = localStorage.getItem('targetYear') || new Date().getFullYear().toString();
  const { handleGetCms, handleSelectDefaultCm } = useCmsContext();
  const CMs = handleGetCms();

  const initialParams = {
    order: '',
    orderBy: '',
    page: 0,
    limit: 10,
    targetYear: localTargetYear,
    scenario: '',
    sku: '',
    spec: [],
    partType: [],
    partNumber: [],
    amount: '',
    local: '',
    description: '',
    project: '',
    product: '',
    cm: handleSelectDefaultCm(''),
  };

  //Modal must replace all others params
  if (dataModal && dataModal.scenario && dataModal.targetYear) {
    initialParams.scenario = dataModal.scenario;
    initialParams.targetYear = dataModal.targetYear;
  }

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const searchInput = useRef(null);
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [isEditAction, setIsEditAction] = useState(false);
  const [actionLevel, setActionLevel] = useState('');
  const [isNewItem, setIsNewItem] = useState(false);
  const [isNewSubItem, setIsNewSubItem] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const [selectedScenario, setSelectedScenario] = useState();
  const [params, setParams] = useState(initialParams);
  const [searchValue, setSearchValue] = useState('');
  const [refetchData, setRefetchData] = useState({
    spec: [],
    partType: [],
    partNumber: [],
    local: '',
  });
  const [isBaseScenario, setIsBaseScenario] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState(null);
  const [scenariosOptions, setScenariosOptions] = useState([]);
  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });
  const [isTableActionsVisible, setIsTableActionsVisible] = useState({
    rowId: '',
    state: false,
  });
  const { yearsScenarios } = useYearsScenariosContext();

  const [targetYears, setTargetYears] = useState();

  useEffect(() => {
    setTargetYears(yearsScenarios);
  }, [yearsScenarios]);

  const handleGetScenarioSelectedById = () => {
    return scenariosOptions.find((scenario) => scenario.value === params.scenario);
  };

  const fetchScenarios = async () => {
    if (!dataModal) {
      try {
        const allParams = filterAndUrlParams(params);

        const { data: content } = await api.get(
          `scenarios/lite?targetYear=${allParams.targetYear}`
        );

        const selectScenarios = parseScenariosLite(content);
        //verifica se retornou cenários
        if (selectScenarios?.length) {
          //busca o cenário salvo anteriormente
          const localScenario = localStorage.getItem('scenario');
          //verifica se o cenário salvo anteriormente está presente nos que foram recebidos
          const scenarioExist = selectScenarios.find((i) => i.value === localScenario);
          //caso não exista cenário salvo ou o que foi salvo não conste nos recebidos seta o primeiro recebido
          if (!localScenario || !scenarioExist) {
            allParams.scenario = selectScenarios[0]?.value;
          } else {
            //caso o cenário anterior existe entre os recebidos seta ele como selecionado
            allParams.scenario = localScenario;
          }
          //atualiza os valores no localStorage
          if (allParams.scenario && allParams.targetYear) {
            localStorage.setItem('scenario', allParams.scenario);
            localStorage.setItem('targetYear', allParams.targetYear);
          }

          //setSelectedScenario(selectScenarios.find((i) => i.value === allParams?.scenario));
          setScenariosOptions(selectScenarios);
          setParams(allParams);
        } else {
          setScenariosOptions([]);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      try {
        setIsLoading(true);
        const {
          data: { content },
        } = await api.get(`scenarios?targetYear=${params.targetYear}`);

        const selectScenarios = parseBaseScenarios(content);
        if (selectScenarios?.length) {
          setScenariosOptions(selectScenarios);
          setSelectedScenario(
            selectScenarios.find((i) => i.value === params?.scenario) || selectScenarios[0]
          );
        }
      } catch (error) {
        oneAlert('error', getErrorMessage(error) || t('toast.missingScenario'));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const resetControls = (expandeRows = null) => {
    setEditingKey('');
    setActionLevel('');
    setIsEditAction(false);
    setIsNewItem(false);
    setIsNewSubItem(false);
    setExpandedRowKeys(expandeRows);

    setRefetchData({
      partType: [],
      spec: [],
      partNumber: [],
      local: '',
    });

    form.setFieldsValue({
      partType: [],
      spec: [],
      partNumber: [],
      local: '',
    });
  };

  function getQueryParams() {
    const getParams = { ...params };
    getParams.scenario = null;
    getParams.targetYear = null;

    if (params.sku?.length) {
      getParams.part = getParams.sku;
      getParams.sku = null;
    }

    if (!params.spec?.length) {
      getParams.spec = null;
    } else {
      getParams.spec = btoa(params.spec);
    }

    if (!params.partType?.length) getParams.partType = null;
    if (!params.partNumber?.length) getParams.partNumber = null;
    return getParams;
  }

  const formatData = (content) => {
    const formattChildren = (children) => {
      return children.flatMap((child) => {
        if (child.children && child.children !== null) {
          const spredChildrens = child.children?.map((item) => {
            return {
              ...item,
            };
          });

          spredChildrens.push(child);
          delete child.children;

          return spredChildrens;
        }
        return {
          ...child,
        };
      });
    };

    const dataContent = content.length
      ? content.map((component) => {
          return {
            id: component.id,
            sku: component.sku,
            modelName: component.modelName,
            project: component.project,
            children: formattChildren(component.children),
          };
        })
      : [];

    return dataContent;
  };

  const fetchBOM = async () => {
    if (params && !!params.scenario) {
      //change filter params
      const getParams = getQueryParams();

      try {
        if (!isLoading) setIsLoading(true);

        const {
          data: { content, totalElements, totalPages },
        } = await api.get(
          getURI(`scenarios/${params.scenario}/bom`, {
            order: params.order,
            orderBy: params.orderBy,
            targetYear: params.targetYear,
            scenario: params.scenario,
            cm: params.cm,
            limit: params.limit,
            page: params.page,
            sku: params.header_sku,
            partType: params.header_partType,
            spec: params.header_spec,
            partNumber: params.header_partNumber,
            amount: params.header_amount,
            local: params.header_local,
            description: params.header_description,
          })
        );

        if (params.scenario && params.targetYear) {
          localStorage.setItem('scenario', params.scenario);
          localStorage.setItem('targetYear', params.targetYear);
        }

        const dataContent = content?.length ? content : [];
        setData(formatData(dataContent));

        if (dataContent?.length) {
          setExpandedRowKeys([dataContent[0].id]);
        }

        setPageControl({
          pageLabel: params.page + 1,
          totalPages,
          totalElements,
        });

        if (dataModal) {
          const {
            data: { content: scenarios },
          } = await api.get(`scenarios`);
          const scenario = scenarios.find((item) => item.id === params.scenario);
          if (scenario) {
            setIsBaseScenario(!scenario?.base?.id);
          }
        }

        resetControls();
        setIsLoading(false);
      } catch (error) {
        setData([]);
        setPageControl({
          pageLabel: '1',
          totalPages: 0,
          totalElements: 0,
        });
        setIsLoading(false);
      }
    }
  };

  const fetchBOMOnDelete = async () => {
    try {
      const getParams = getQueryParams();

      const {
        data: { content, totalElements, totalPages },
      } = await api.get(getURI(`scenarios/${params.scenario}/bom`, getParams));

      const dataContent = content?.length ? content : [];

      setData(formatData(dataContent));
      setPageControl({ ...pageControl, totalElements, totalPages });
    } catch (error) {
      setData([]);
      setPageControl({
        pageLabel: '1',
        totalPages: 0,
        totalElements: 0,
      });
    }
  };

  const handleUpdateParams = (field, value) => {
    setParams({ ...params, [field]: value });
  };

  const updateProduct = (value) => {
    setParams({ ...params, product: value, page: 0 });
  };

  const changePage = (page, pageSize) => {
    setParams({ ...params, page: page - 1, limit: pageSize });
    setPageControl({ ...pageControl, pageLabel: page });
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    if (data.length === 0) return;
    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const getSkuFromPartType = (id) => {
    const idAsArray = id.split('$');
    const sku = idAsArray[0];
    return sku;
  };

  const getPartTypeId = (id) => {
    const idAsArray = id.split('$');
    const partTypeId = [idAsArray[0], idAsArray[1], idAsArray[2], idAsArray[3]].join('$');
    return partTypeId;
  };

  const getActionLevel = (record) => {
    if (record?.sku) {
      return 'sku';
    }
    if (record?.className === 'partType') {
      return 'partType';
    }
    return 'partNumber';
  };

  const cancelNewItem = (record, key = null) => {
    const keyId = key || editingKey;
    const newData = data.filter((item) => item.id !== keyId);
    setData(newData);
    resetControls();
  };

  const cancelNewSubItem = (record, key = null) => {
    const keyId = key || editingKey;
    const newData = [...data];
    const level = getActionLevel(record);
    const sku = getSkuFromPartType(record.id);

    if (level === 'partType') {
      const bom = newData.find((item) => item.children.find((c) => c.id === keyId));
      const newChildren = bom.children.filter((item) => item.id !== keyId);
      const newRecord = { ...bom, children: newChildren };
      const index = newData.findIndex((item) => item.id === bom.id);
      if (index > -1) newData.splice(index, 1, newRecord);

      setData(newData);
      resetControls([sku]);
    } else {
      const partTypeId = record?.partTypeId || getPartTypeId(record.id);
      const skuIndex = newData.findIndex((item) => item.sku === sku);

      const nodePartType = newData[skuIndex].children;
      const partTypeIndex = nodePartType.findIndex((item) => item.id === partTypeId);

      const nodePartNumber = nodePartType[partTypeIndex].children;
      const partNumberIndex = nodePartNumber.findIndex((item) => item.id === record.id);

      const subItemParent = nodePartType[partTypeIndex];

      if (partNumberIndex > -1) {
        nodePartNumber.splice(partNumberIndex, 1);
      }

      if (subItemParent.children.length === 0) {
        delete subItemParent.children;
      }

      setData(newData);
      resetControls([sku, partTypeId]);
    }
  };

  const cancel = (record, event) => {
    event.stopPropagation();
    if (isNewItem) cancelNewItem(record);
    if (isNewSubItem) cancelNewSubItem(record);
    resetControls();
  };

  const handleSaveItem = (row, record, responseData) => {
    const newData = [...data];
    const sku = actionLevel === 'sku' ? row.sku : getSkuFromPartType(record.id);
    const replaceIndex =
      actionLevel === 'sku'
        ? data?.findIndex((item) => item.id === record.id)
        : data?.findIndex((item) => item.id === sku);
    const indexData = responseData?.findIndex((item) => item.sku === sku);

    const skuData = responseData[indexData];

    newData.splice(replaceIndex, 1, skuData);
    setData(formatData(newData));

    if (actionLevel === 'sku') {
      resetControls([sku]);
    } else {
      // row.partType case POST, record.partType case PUT
      const partType = row?.partType || record?.partType;
      const partTypeId = `${sku}$${partType}$${row.spec}$${row.partNumber}`;

      resetControls([sku, partTypeId]);
    }
  };

  const handleAddItem = () => {
    const newItem = {
      id: data.length + 1,
      sku: '',
      spec: [],
      partType: [],
      partNumber: [],
      amount: '',
      local: '',
      description: '',
    };

    setIsNewItem(true);
    setActionLevel('sku');
    form.setFieldsValue({ ...newItem });
    setData([newItem, ...data]);
    setEditingKey(newItem.id);
    setExpandedRowKeys([newItem.id]);
  };

  const handleAddSubItem = (record, e) => {
    e.stopPropagation();
    setIsTableActionsVisible({ ...isTableActionsVisible, state: false });

    const newData = [...data];
    const copyRecord = { ...record };
    if (!copyRecord?.children?.length) {
      copyRecord.children = [];
    }

    const level = copyRecord?.sku ? 'partType' : 'partNumber';
    const newSubItem = {
      id: `${copyRecord.id}$${level}${newData.length + 1}`,
      level: copyRecord.level,
      altGroup: copyRecord.altGroup,
      partTypeId: copyRecord.id,
      spec: [],
      partType: [],
      partNumber: [],
      amount: '',
      local: '',
      description: '',
    };

    if (level === 'partType') {
      newSubItem.className = 'partType';
      form.setFieldsValue({ ...newSubItem });
      const newChildren = [newSubItem, ...copyRecord.children];
      const newRecord = { ...copyRecord, children: newChildren };
      const index = newData.findIndex((item) => item.id === copyRecord.id);

      if (index > -1) {
        newData.splice(index, 1, newRecord);
      }

      const expand = [copyRecord.id, newSubItem.id];
      setExpandedRowKeys(expand);
    }

    setData(newData);
    setEditingKey(newSubItem.id);
    setIsNewSubItem(true);
    setActionLevel(level);
  };

  const save = async (record, e) => {
    e.stopPropagation();
    const { id } = record;

    setIsLoading(true);
    await form
      .validateFields()
      .then(async (row) => {
        const itemRow = { ...row };

        try {
          let response;
          if (isEditAction) {
            if (actionLevel === 'sku') {
              response = await api.put(getURI(`scenarios/${params.scenario}/bom/sku/${id}`), row);
              if (response.status !== 201) throw Error();
            } else if (actionLevel === 'partType') {
              const sku = getSkuFromPartType(id);
              response = await api.put(
                getURI(`scenarios/${params.scenario}/bom/sku/${sku}/parttype/${id}`),
                row
              );
              if (response.status !== 201) throw Error();
            } else if (actionLevel === 'partNumber') {
              //Alternate
              const sku = getSkuFromPartType(id);
              row.level = record.level;
              row.altGroup = record.altGroup;
              row.amount = record.amount;
              row.partType = record.partType;

              response = await api.put(
                getURI(
                  `scenarios/${params.scenario}/bom/sku/${sku}/parttype/${record.partTypeId}/alternate/${id}`
                ),
                row
              );
              if (response.status !== 201) throw Error();
            } else {
              throw Error('No actionLevel found');
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (actionLevel === 'sku' || actionLevel === 'partType') {
              const sku = actionLevel === 'partType' ? getSkuFromPartType(id) : record.sku;
              row.altGroup = row.altGroup || row.partNumber;

              response = await api.post(getURI(`scenarios/${params.scenario}/bom/sku/${sku}`), row);
              if (response.status !== 201) throw Error();
            } else if (actionLevel === 'partNumber') {
              const sku = getSkuFromPartType(id);
              row.level = record.level;
              row.altGroup = record.altGroup;
              row.amount = record.amount;
              row.partType = record.partType;

              response = await api.post(
                getURI(
                  `scenarios/${params.scenario}/bom/sku/${sku}/parttype/${record.partTypeId}/alternate`
                ),
                row
              );
              if (response.status !== 201) throw Error();
            } else {
              throw Error('No actionLevel found');
            }
          }

          handleSaveItem(itemRow, record, response?.data);
          oneAlert('success', t('toast.successOnSave'));
        } catch (error) {
          oneAlert('error', getErrorMessage(error) || t('toast.errorOnSave'));
        }
      })
      .catch((error) => {
        console.log('Validate Failed: ', error);
      });

    setIsLoading(false);
  };

  const deleteItem = async (record, event) => {
    setIsLoading(true);
    try {
      event.stopPropagation();
      const level = getActionLevel(record);

      if (level === 'sku') {
        const response = await api.delete(
          getURI(`scenarios/${params.scenario}/bom/sku/${record.id}`)
        );

        if (response.status !== 200) throw Error();
        cancelNewItem(record, record.id);
      } else if (level === 'partType') {
        const sku = getSkuFromPartType(record.id);

        const response = await api.delete(
          getURI(`scenarios/${params.scenario}/bom/sku/${sku}/parttype/${record.id}`)
        );

        if (response.status !== 200) throw Error();
        cancelNewSubItem(record, record.id);
      } else {
        const sku = getSkuFromPartType(record.id);
        const { partTypeId } = record;

        const response = await api.delete(
          getURI(
            `scenarios/${params.scenario}/bom/sku/${sku}/parttype/${partTypeId}/alternate/${record.id}`
          )
        );

        if (response.status !== 200) throw Error();
        cancelNewSubItem(record, record.id);
      }

      const isLastItem = data.length === 1;

      if (isLastItem) {
        const newPage = params.page - 1;
        setParams({ ...params, page: newPage < 0 ? 0 : newPage });
      }

      setPageControl({ ...pageControl, totalElements: pageControl.totalElements - 1 });

      oneAlert('info', t('toast.successOnDelete'));
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnDelete'));
    }

    await fetchBOMOnDelete();

    setIsLoading(false);
  };

  const isEditing = (record) => record.id === editingKey;

  const edit = (record, event) => {
    event.stopPropagation();

    setRefetchData({
      partType: record.partType,
      spec: record.spec,
      partNumber: record.partNumber,
      local: record.local,
    });
    form.setFieldsValue({ ...record });

    setActionLevel(getActionLevel(record));
    setEditingKey(record.id);
    setIsEditAction(true);
  };

  const searchFilter = async (newFilters, filterName = '') => {
    setCanUpdate(filterName === 'submit');

    const filterParams = {
      ...params,
      ...newFilters,
      page: 0,
    };

    //Reset dependent filters
    if (filterName === 'targetYear') {
      filterParams.scenario = null;
    }

    setParams(filterParams);
  };

  const debouncedEventHandler = useMemo(
    () =>
      debounce((e) => {
        const value = !e.target.value ? '' : e.target.value.toString().toLowerCase().trim();
        setSearchValue(value);
      }, 300),
    []
  );

  const debouncedOnCell = useMemo(
    () =>
      debounce((fn) => {
        fn();
      }, 0),
    []
  );

  const onValuesChange = (changedValues) => {
    if (changedValues.partType) {
      setRefetchData({
        ...refetchData,
        partType: changedValues.partType,
        spec: [],
        partNumber: [],
        local: '',
      });
      form.setFieldsValue({
        spec: [],
        partNumber: [],
        local: '',
      });
    }
    if (changedValues.spec) {
      setRefetchData({ ...refetchData, spec: changedValues.spec, partNumber: [], local: '' });
      form.setFieldsValue({
        partNumber: [],
        local: '',
      });
    }
  };

  const rowClassName = (row) => {
    const className = row.className ? row.className : '';
    if (isNewItem && row.id === editingKey) {
      return className ? `data-row-editing ${className}` : 'data-row-editing';
    }

    return className;
  };

  const handleHeaderReset = (dataIndex) => {
    const filterParams = { ...params, page: 0 };
    delete filterParams[`header_${dataIndex}`];

    if (!hasPropertyWithHeader(filterParams)) {
      delete filterParams.tipoBusca;
    }

    setParams(filterParams);
  };

  const handleCloseFilteredTag = (field, idx) => {
    if (field?.includes('header_')) {
      const dataIndex = field.replace('header_', '');
      handleHeaderReset(dataIndex);
    } else {
      let newValue;

      if (Array.isArray(params[field])) {
        newValue = [...params[field]];
        newValue.splice(idx, 1);
      } else {
        newValue = '';
      }

      setParams({
        ...params,
        [field]: newValue,
        page: 0,
      });
    }
  };

  const handleHeaderSearch = (dataIndex, formValue) => {
    if (dataIndex === 'local' && formValue?.local?.length) {
      const filterParams = {
        ...params,
        header_local: formValue.local.join(','),
        tipoBusca: 'dinamica',
        page: 0,
      };

      setParams(filterParams);
    } else if (dataIndex !== 'local' && formValue?.length) {
      const filterParams = {
        ...params,
        [`header_${dataIndex}`]: formValue.trim(),
        tipoBusca: 'dinamica',
        page: 0,
      };

      setParams(filterParams);
    } else if (!formValue) {
      handleHeaderReset(dataIndex);
    }
  };

  const columns = [
    {
      title: `${t('pages.bom.fields.sku')} - ${t('pages.bom.fields.model')}`,
      label: t('pages.bom.fields.sku'),
      width: 160,
      dataIndex: 'sku',
      key: 'sku',
      editable: true,
      required: true,
      render: (value, row) => {
        const hasMotherboard = row?.children?.some((x) => x.spec === 'MOTHERBOARD');
        const model = row?.modelName ? ` - ${row.modelName}` : '';
        return row.sku
          ? {
              props: { colSpan: 2 },
              children: (
                <div>
                  {`${value}${model}`}
                  {!hasMotherboard ? (
                    <div>
                      <SC.Tag>{t('pages.bom.noMotherboard')}</SC.Tag>
                    </div>
                  ) : null}
                </div>
              ),
            }
          : null;
      },
      ...getColumnSearchProps({
        dataIndex: 'sku',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.bom.fields.partType'),
      label: t('pages.bom.fields.partType'),
      width: 160,
      dataIndex: 'partType',
      key: 'partType',
      editable: true,
      required: true,
      render: (value, row) => {
        return !row.sku ? value : { props: { colSpan: 0 } };
      },
      ...getColumnSearchProps({
        dataIndex: 'partType',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.bom.fields.spec'),
      label: t('pages.bom.fields.spec'),
      width: 190,
      dataIndex: 'spec',
      key: 'spec',
      editable: true,
      required: true,
      render: (value) => {
        return (
          value && (
            <div style={{ textAlign: 'center' }}>
              <Tooltip placement="left" title={value}>
                <SC.TextElipsed>{value}</SC.TextElipsed>
              </Tooltip>
            </div>
          )
        );
      },
      ...getColumnSearchProps({
        dataIndex: 'spec',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.bom.fields.partNumber'),
      width: 140,
      label: t('pages.bom.fields.partNumber'),
      dataIndex: 'partNumber',
      key: 'partNumber',
      editable: true,
      required: true,
      ...getColumnSearchProps({
        dataIndex: 'partNumber',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.bom.fields.amount'),
      label: t('pages.bom.fields.amount'),
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
      editable: true,
      required: true,
      render: (value) => {
        return <div style={{ textAlign: 'center' }}>{value}</div>;
      },
      ...getColumnSearchProps({
        dataIndex: 'amount',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.bom.fields.local'),
      label: t('pages.bom.fields.local'),
      dataIndex: 'local',
      key: 'local',
      width: 100,
      editable: true,
      required: false,
      render: (value, row) => {
        const hasValue = parseInt(value, 10) >= 0;
        const local = isFobOrBr(value);
        return Object.hasOwn(row, 'local') && hasValue ? (
          <Tooltip title={value}>{local}</Tooltip>
        ) : null;
      },
      ...getColumnOptionProps({
        dataIndex: 'local',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        options: [
          { label: 'FOB', value: 'FOB' },
          { label: 'BR', value: 'BR' },
        ],
      }),
    },
    {
      title: t('pages.bom.fields.description'),
      label: t('pages.bom.fields.description'),
      dataIndex: 'description',
      key: 'description',
      editable: true,
      required: false,
      width: 190,
      render: (value) => {
        return (
          value && (
            <div style={{ textAlign: 'center' }}>
              <Tooltip placement="left" title={value}>
                <SC.TextElipsed>{value}</SC.TextElipsed>
              </Tooltip>
            </div>
          )
        );
      },
      ...getColumnSearchProps({
        dataIndex: 'description',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
  ];

  const columnsWithAction = [
    ...columns,
    {
      title: t('common.action'),
      key: 'action',
      width: 130,
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);

        const actions = [
          {
            key: '1',
            label: (
              <TableActionButton
                type="table_action"
                disabled={editingKey !== '' || isBaseScenario || isLoading}
                onClick={(e) => edit(record, e)}
                data-cy="edit"
              >
                {t('common.edit')}
              </TableActionButton>
            ),
          },
          {
            key: '2',
            label: (
              <Popconfirm
                placement="topLeft"
                title={t('common.deleteMessage')}
                onConfirm={(e) => deleteItem(record, e)}
                onCancel={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
              >
                <TableActionButton
                  type="table_action"
                  danger
                  disabled={editingKey !== '' || isBaseScenario || isLoading}
                  data-cy="delete"
                >
                  {t('common.delete')}
                </TableActionButton>
              </Popconfirm>
            ),
          },
          {
            key: '3',
            label: record.sku ? (
              <TableActionButton
                type="table_action"
                onClick={(e) => handleAddSubItem(record, e)}
                disabled={editingKey !== '' || isBaseScenario || isLoading}
                data-cy="add-sub-item"
              >{`${t('common.add')} ${t('common.item')}`}</TableActionButton>
            ) : null,
          },
        ];

        return editable ? (
          <Space direction="horizontal" size={12}>
            <Button type="primary" onClick={(e) => save(record, e)} data-cy="save">
              {t('common.save')}
            </Button>
            <Popconfirm
              title={t('common.cancelMessage')}
              onConfirm={(e) => {
                cancel(record, e);
              }}
              onCancel={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
            >
              <Button shape="circle" default icon={<AiOutlineClose />} data-cy="cancel" />
            </Popconfirm>
          </Space>
        ) : (
          <TableAction
            dropdownItems={actions}
            onVisibleChange={(visible) =>
              setIsTableActionsVisible({ rowId: record.id, state: visible })
            }
            visible={isTableActionsVisible.rowId === record.id && isTableActionsVisible.state}
          />
        );
      },
    },
  ];

  const headerCsv = [
    { label: t('pages.bom.fields.sku'), key: 'sku' },
    { label: t('pages.bom.fields.partType'), key: 'partType' },
    { label: t('pages.bom.fields.spec'), key: 'spec' },
    { label: t('pages.bom.fields.partNumber'), key: 'partNumber' },
    { label: t('pages.bom.fields.amount'), key: 'amount' },
    { label: t('pages.bom.fields.local'), key: 'local' },
    { label: t('pages.bom.fields.description'), key: 'description' },
  ];

  const transformData = (dataSource) => {
    const rows = [];

    dataSource.forEach((sku) => {
      if (sku?.children?.length) {
        sku.children.forEach((partType) => {
          if (partType?.children?.length) {
            partType.children.forEach((partNumber) => {
              rows.push({
                sku: sku.sku,
                partType: partNumber.partType,
                spec: partNumber.spec,
                partNumber: partNumber.partNumber,
                amount: partNumber.amount,
                local: partNumber.local,
                description: partNumber.description,
              });
            });
          }

          //if no partNumber
          rows.push({
            sku: sku.sku,
            partType: partType.partType,
            spec: partType.spec,
            partNumber: partType.partNumber,
            amount: partType.amount,
            local: partType.local,
            description: partType.description,
          });
        });
      }

      //if no partType
      if (!sku?.children?.length) {
        rows.push({
          sku: sku.sku,
          partType: '',
          spec: '',
          partNumber: '',
          amount: '',
          local: '',
          description: '',
        });
      }
    });

    return rows;
  };

  const handleLocalData = async (value) => {
    const row = form.getFieldValue();

    const {
      data: { content },
    } = await api.get(
      getURI('components', {
        specEquals: btoa(row.spec),
        order: 'spec',
        orderBy: 'ASC',
      })
    );

    const component = content?.find((comp) => comp.partNumber === value);
    if (component && component?.local?.length) {
      setRefetchData({ ...refetchData, local: component?.local });
      form.setFieldsValue({ ...row, local: component?.local });
    }
  };

  const inputNode = (column) => {
    const row = form.getFieldValue();
    const local = row?.local || refetchData?.local;

    switch (column) {
      case 'partType':
        return (
          <ComponentsTypeSelect
            id="partTypeSelect"
            allowClear={false}
            placeholder={t('filter.partType')}
            intable="intable"
            search={searchValue}
            onKeyUp={debouncedEventHandler}
            onClick={(e) => e.stopPropagation()}
          />
        );
      case 'spec':
        return (
          <ComponentsSelect
            id="specSelect"
            intable="intable"
            placeholder={t('filter.spec')}
            disabled={!refetchData?.partType?.length && !row?.partType?.length}
            componentType={refetchData.partType || row?.partType}
            onClick={(e) => e.stopPropagation()}
          />
        );
      case 'partNumber':
        return (
          <ComponentsSelect
            id="partNumberSelect"
            intable="intable"
            placeholder={t('filter.partNumber')}
            disabled={!refetchData.spec?.length && !row?.spec?.length}
            spec={refetchData.spec || row?.spec}
            onSelect={handleLocalData}
            onClick={(e) => e.stopPropagation()}
          />
        );
      case 'local':
        return local ? <Tooltip title={local}>{isFobOrBr(local)}</Tooltip> : '-';
      default:
        return <Input onClick={(e) => e.stopPropagation()} />;
    }
  };

  const mergedColumns = columnsWithAction.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          editable:
            (col.key === 'sku' && actionLevel !== 'sku') ||
            (col.key !== 'sku' && actionLevel === 'sku' && isEditAction)
              ? false
              : col.editable,
          inputType: col.dataIndex === 'amount' ? 'number' : 'text',
          limit: col.dataIndex === 'description' ? '255' : '100',
          required: col.required,
          newInputNode: inputNode(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });

  return {
    rowClassName,
    mergedColumns,
    transformData,
    headerCsv,
    data,
    params,
    pageControl,
    updateProduct,
    fetchScenarios,
    scenariosOptions,
    isBaseScenario,
    canUpdate,
    selectedScenario,
    fetchBOM,
    changePage,
    handleChangeTable,
    onValuesChange,
    refetchData,
    setRefetchData,
    searchValue,
    debouncedEventHandler,
    debouncedOnCell,
    isLoading,
    form,
    isNewItem,
    isNewSubItem,
    save,
    cancel,
    handleAddItem,
    handleAddSubItem,
    actionLevel,
    edit,
    isEditing,
    isEditAction,
    editingKey,
    deleteItem,
    searchFilter,
    handleCloseFilteredTag,
    expandedRowKeys,
    setExpandedRowKeys,
    setParams,
    getQueryParams,
    setSelectedScenario,
    targetYears,
    handleGetScenarioSelectedById,
    CMs,

    handleUpdateParams,
  };
}
