import { ExclamationCircleFilled, InfoCircleFilled, RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  max-width: 18rem;
  flex-direction: column;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  width: 100%;
  height: 16.625rem;
  transition: all 0.3s;
  padding-bottom: 1rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    border: 2px solid ${({ theme }) => theme.colorsDesignSystem.tabBarInactiveColor};
  }
`;

export const HeaderContainers = styled.div`
  display: flex;
  flex-direction: row;
  height: 3.5rem;
  padding-inline: 1.5rem;

  align-items: center;
  border-top-right-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  justify-content: space-between;
  p {
    margin: 0;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    text-transform: uppercase;
  }
`;

export const ContentInside = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding: 1.5rem;
  gap: 1rem;
  p {
    margin: 0;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
    &.title {
      color: ${({ theme }) => theme.colorsDesignSystem.text};
      font-weight: bold;
      font-size: 1rem;
    }
  }
`;

export const SavingLabel = styled.span<{ isPositive: boolean }>`
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.35rem;
  color: ${({ theme, isPositive }) =>
    isPositive
      ? theme.colorsDesignSystem.red
      : theme.colorsDesignSystem.rfqIndicationTagColors.greenFont};
`;

export const ImpactLabel = styled.span`
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1.35rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const ImageIcon = styled.img`
  height: 1rem;
  width: 1rem;
`;

export const PendingIcon = styled(ExclamationCircleFilled)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.tagColorYellow};
`;

export const Arrow = styled(RightOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  font-size: 1rem;
`;

export const InfoCircle = styled(InfoCircleFilled)`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  font-size: 1rem;
`;

export const StyledInfoCircle = styled(InfoCircleFilled)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.red};
`;
