/* eslint-disable no-undefined */
import { CloseOutlined, CodepenOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Popconfirm, Row, Space, Tooltip } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pt-br';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import { useHistory, useLocation } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import calculatedIcon from '../../../assets/calculatedIcon.svg';
import cm from '../../../assets/CM.png';
import EditableTableCell from '../../../components/Common/EditableTableCell';
import StyledFilterTag from '../../../components/Common/StyledFilterTag';
import StyledClearTag from '../../../components/Common/StyledFilterTag/StyledClearTag';
import StyledIndicationTag from '../../../components/IndicationTag';
import { useModal } from '../../../components/Modal/useModal';
import SimpleTable from '../../../components/Tables/SimpleTable';
import { TableAction } from '../../../components/Tables/TableDropdownAction';
import { useCmsContext } from '../../../context/CmsContext';
import { getURIWithFalse, oneAlert } from '../../../helpers/utils';
import { api } from '../../../services/api';
import theme from '../../../styles/theme';
import ExpandableTable from '../ExpandableTable';
import * as S from './styles';
import {
  ComponentsTypes,
  PriceComponentsParams,
  RangeValue,
  StyledSelectOptions,
  TableTypes,
} from './types';

export function usePricesPage() {
  dayjs.extend(quarterOfYear);
  const { t } = useTranslation();
  const { handleGetCms, handleSelectDefaultCm } = useCmsContext();
  const CMs = handleGetCms();
  const initialParams = {
    cm: handleSelectDefaultCm('Foxconn'),
    category: '',
    partNumber: '',
    quarter: '',
    source: '',
    spec: '',
    supplier: '',
    startQ: '',
    endQ: '',
    withPrices: true,
  } as PriceComponentsParams;
  const ExpandCategories: any = {};
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState(initialParams);
  const [dates, setDates] = useState<RangeValue>([null, null]);
  const [dateValue, setDateValue] = useState<RangeValue>();
  const [expandableViewStatus, setExpandableViewStatus] = useState(ExpandCategories);
  const [partNumberEdit, setPartNumberEdit] = useState('');
  const [components, setComponents] = useState<Array<TableTypes>>([]);
  const [partNumbers, setPartNumbers] = useState<Array<ComponentsTypes>>([]);
  const [categoryOptions, setCategoryOptions] = useState<Array<StyledSelectOptions>>([]);
  const [partOptions, setPartOptions] = useState<Array<StyledSelectOptions>>([]);
  const [specOptions, setSpecOptions] = useState<Array<StyledSelectOptions>>([]);
  const [supplierOptions, setSupplierOptions] = useState<Array<StyledSelectOptions>>([]);
  const [dateRequire, setDateRequire] = useState(false);
  const [fileDate, setFileDate] = useState();
  const [visibleModalUpdateData, setVisibleModalUpdateData] = useState(false);
  const isProcessingData = useRef(false);
  const [visibleModalTax, openModalTax, closeModalTax] = useModal();
  const [componentType, setComponentType] = useState('');
  const [period, setPeriod] = useState('');
  const [maxDate, setMaxDate] = useState<Dayjs>();
  const [minDate, setMinDate] = useState<Dayjs>();
  const [isFirstRendering, setIsFirstRendering] = useState(true);
  const [updateValues, setUpdateValues] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  const [form] = Form.useForm();

  const createObject = (data: Array<TableTypes>) => {
    data.forEach(() => {
      setExpandableViewStatus((oldCategories: any) => {
        return {
          ...oldCategories,
        };
      });
    });
  };

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }

    const tooLate = dates[0] && current.diff(dates[0], 'quarter') >= 2;
    const tooEarly = dates[1] && current.diff(dates[1], 'quarter') <= -1;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setMinDate(undefined);
      setMaxDate(undefined);
      setDates([null, null]);
      setDateValue([null, null]);
    } else {
      setDates(null);
    }
  };

  const hasParams = () => {
    return params.category || params.source || params.spec || params.partNumber || params.quarter;
  };

  const handleFetchPrices = async () => {
    setIsLoading(true);
    try {
      const { data, status } = await api.get(
        getURIWithFalse('components/prices', {
          ...params,
          startQ: `Q${dayjs(dateValue?.[0]).format('Q/YYYY')}`,
          endQ: `Q${dayjs(dateValue?.[1]).format('Q/YYYY')}`,
        })
      );
      if (status === 200) {
        setComponents(data.content || []);
        if (hasParams()) {
          return setExpandableViewStatus({ [data.content[0].componentType]: true });
        }
        setExpandableViewStatus({});
      }
      if (status === 204) {
        setComponents([]);
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleConvertOptions = (list: any): Array<StyledSelectOptions> => {
    const noRepeatingList = list.filter((value: any, index: any) => {
      return list.indexOf(value) === index;
    });
    const options = noRepeatingList.map((item: string) => {
      return {
        label: item,
        value: item,
      };
    });
    options.unshift({ label: t('common.viewAll'), value: '' });
    return options;
  };

  const handleFetchHeaders = async () => {
    try {
      const { data } = await api.get(
        getURIWithFalse('components/prices/header', {
          cm: params.cm,
          category: params.category,
          spec: params.spec,
          source: params.source,
          withPrices: params.withPrices,
        })
      );
      const convertCategory = handleConvertOptions(data.categoryList);

      const convertPartNumber = handleConvertOptions(data.partNumberList);

      const convertSpec = handleConvertOptions(data.specList);

      // const convertSupplier = handleConvertOptions(data.supplierList);

      setCategoryOptions(convertCategory);
      setPartOptions(convertPartNumber);
      setSpecOptions(convertSpec);
      // setSupplierOptions(convertSupplier);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const handleUpdateFilters = (field: string, value: string | boolean) => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        [field]: value,
      };
    });
  };

  const clearAllFilters = () => {
    setParams({
      ...initialParams,
      cm: params.cm,
    });
  };

  const handleUpdateExpandableViews = (value: string) => {
    setExpandableViewStatus((oldParams: any) => {
      return {
        ...oldParams,
        [value]: !expandableViewStatus[value],
      };
    });
  };

  const handleRenderClearAllFilters = () => {
    if (params.category || params.partNumber || params.spec || params.supplier || params.source) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  const prepareToEdit = (record: ComponentsTypes) => {
    form.setFieldsValue({ ...record });
    setPartNumberEdit(record.id);
  };

  const isEditing = (record: ComponentsTypes) => record.id === partNumberEdit;

  const saveRecordAfterEditing = async (record: ComponentsTypes) => {
    const valueForm = form.getFieldsValue();
    const Prices: Array<any> = [
      {
        month: record.price1.month,
        gross: valueForm.price1.gross === '' ? null : valueForm.price1.gross,
        netCost: valueForm.price1.netCost === '' ? null : valueForm.price1.netCost,
      },
      {
        month: record.price2.month,
        gross: valueForm.price2.gross === '' ? null : valueForm.price2.gross,
        netCost: valueForm.price2.netCost === '' ? null : valueForm.price2.netCost,
      },
      {
        month: record.price3.month,
        gross: valueForm.price3.gross === '' ? null : valueForm.price3.gross,
        netCost: valueForm.price3.netCost === '' ? null : valueForm.price3.netCost,
      },
      {
        month: record.price4.month,
        gross: valueForm.price4.gross === '' ? null : valueForm.price4.gross,
        netCost: valueForm.price4.netCost === '' ? null : valueForm.price4.netCost,
      },
      {
        month: record.price5.month,
        gross: valueForm.price5.gross === '' ? null : valueForm.price5.gross,
        netCost: valueForm.price5.netCost === '' ? null : valueForm.price5.netCost,
      },
      {
        month: record.price6.month,
        gross: valueForm.price6.gross === '' ? null : valueForm.price6.gross,
        netCost: valueForm.price6.netCost === '' ? null : valueForm.price6.netCost,
      },
    ];
    try {
      const { status } = await api.put(`components/prices/${record.id}`, Prices);
      if (status === 200) {
        oneAlert('loading', t('toast.dataProcessing'));
        setUpdateValues(true);
        handleFetchPrices();
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnSave'));
    } finally {
      setPartNumberEdit('');
    }
  };

  const handleValidateFieldsBeforeSendData = () => {
    try {
      const fields = form.getFieldsError();
      let validate = true;
      fields.forEach((item) => {
        if (item.errors.length > 0) {
          validate = false;
        }
      });
      return validate;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const columns: any = [
    {
      title: () => {
        return (
          <S.PartNumberHeader>
            <span>{t('pages.componentsPrices.tableHeaders.partNumber')}</span>
          </S.PartNumberHeader>
        );
      },
      dataIndex: 'partNumber',
      key: 'partNumber',
      align: 'center',
      editable: false,
      render: (_: string, record: any) => {
        return (
          <S.RowCell>
            <Tooltip placement="right" title={record.description}>
              {record.status === 'CALCULANDO' && <S.UpdateIcons spin />}
              {record.partNumber}
            </Tooltip>
          </S.RowCell>
        );
      },
    },
    {
      title: () => {
        return (
          <S.PartNumberHeader>
            <span>{t('pages.componentsPrices.tableHeaders.cm')}</span>
          </S.PartNumberHeader>
        );
      },
      dataIndex: 'cm',
      key: 'cm',
      align: 'center',
      width: '5rem',
      editable: false,
      render: (_: any, record: any) => {
        return (
          <Row align="middle" justify="center">
            {record.cm && (
              <Flex gap={8} align="center">
                <img src={cm} alt="ícon" width={10.5} height={9.75} />
                <span style={{ fontWeight: 700 }}>
                  {record.cm.toUpperCase() === 'FOXCONN' ? 'FBR' : 'CEB'}
                </span>
              </Flex>
            )}
          </Row>
        );
      },
    },
    {
      title: () => {
        return (
          <S.SpecHeader>
            <span>{t('pages.componentsPrices.tableHeaders.specification')}</span>
          </S.SpecHeader>
        );
      },
      dataIndex: 'spec',
      key: 'spec',
      align: 'center',
      width: '12rem',
      render: (_: any, record: any) => {
        return <S.RowCell>{record.spec}</S.RowCell>;
      },
    },
    {
      title: () => {
        return (
          <S.SupplierHeader>
            <span>{t('pages.componentsPrices.tableHeaders.supplier')}</span>
          </S.SupplierHeader>
        );
      },
      dataIndex: 'supllier',
      key: 'supllier',
      align: 'center',
      width: '22rem',
      render: (_: any, record: any) => {
        return record.supplier !== null && record.supplier !== 'None' ? (
          <S.RowCell>{record.supplier}</S.RowCell>
        ) : (
          <S.RowCell> - </S.RowCell>
        );
      },
    },
    {
      title: () => {
        return (
          <S.SourceHeader>
            <span>{t('pages.componentsPrices.tableHeaders.source')}</span>
          </S.SourceHeader>
        );
      },
      dataIndex: 'source',
      key: 'source',
      align: 'center',
      width: '4.625rem',
      className: 'remove-tip',
      render: (_: any, record: any) => {
        return (
          <S.RowCell>
            <StyledIndicationTag text={record.source || ''} />
          </S.RowCell>
        );
      },
    },
    {
      title: () => {
        return (
          <S.MonthHeader>
            <span>
              {dayjs(`${components[0].components[0].price1.month}`, 'MMMM/YYYY', 'en')
                .locale(t('common.dayjs'))
                .format('MMM/YY')}
            </span>
          </S.MonthHeader>
        );
      },
      children: [
        {
          title: () => {
            return (
              <S.GrossNetCell>
                <span>{t('pages.componentsPrices.tableHeaders.subHeader.gross')}</span>
              </S.GrossNetCell>
            );
          },
          dataIndex: ['price1', 'gross'],
          key: 'gross',
          align: 'right',
          width: '4.8rem',
          render: (_: any, record: any) => {
            return record.price1.gross !== null ? (
              <S.RowCellNetCoast>${record.price1.gross}</S.RowCellNetCoast>
            ) : (
              <S.RowCellNetCoast> - </S.RowCellNetCoast>
            );
          },
          onCell: (record: any) => ({
            record,
            inputType: 'money',
            require: true,
            dataIndex: ['price1', 'gross'],
            editing: isEditing(record),
            title: `${t('pages.componentsPrices.tableHeaders.subHeader.gross')}`,
            messageRequired: `${t('common.validate.typeNumber')}`,
          }),
        },
        {
          title: () => {
            return (
              <S.NetCell>
                <span>{t('pages.componentsPrices.tableHeaders.subHeader.netCost')}</span>
                <Tooltip placement="right" title={t('pages.componentsGroup.modalTax.tootip')}>
                  <img src={calculatedIcon} alt="calculated" />
                </Tooltip>
              </S.NetCell>
            );
          },
          dataIndex: ['price1', 'netCost'],
          key: 'netCost',
          align: 'right',
          width: '4.8rem',
          render: (_: any, record: any) => {
            return record.price1.netCost !== null ? (
              <S.RowCellNetCoast>
                {record.price1.isCalculated ? (
                  <S.NetCostContainer
                    onClick={() => {
                      setComponentType(record.componentType);
                      setPeriod(dayjs(record.price1.month, 'MMMM/YYYY', 'en').format('YYYY-MM-DD'));
                      openModalTax();
                    }}
                  >
                    ${record.price1.netCost}
                  </S.NetCostContainer>
                ) : (
                  `$${record.price1.netCost}`
                )}
              </S.RowCellNetCoast>
            ) : (
              //caso o registro seja FOB e não exista preço NET, repetimos o preço GROSS.
              <S.RowCellNetCoast>
                {record.source === 'FOB' && record.price1.gross !== null ? (
                  <span>{`$${record.price1.gross}`}</span>
                ) : (
                  '-'
                )}
              </S.RowCellNetCoast>
            );
          },
          onCell: (record: any) => ({
            record,
            inputType: 'money',
            require: true,
            dataIndex: ['price1', 'netCost'],
            // editing:
            //   record.source === 'FOB' || record.price1?.netCost !== null
            //     ? false
            //     : isEditing(record),
            title: `${t('pages.componentsPrices.tableHeaders.subHeader.netCost')}`,
            messageRequired: `${t('common.validate.typeNumber')}`,
          }),
        },
      ],
    },
    {
      title: () => {
        return (
          <S.MonthHeader>
            <span>
              {dayjs(`${components[0].components[0].price2.month}`, 'MMMM/YYYY', 'en')
                .locale(t('common.dayjs'))
                .format('MMM/YY')}
            </span>
          </S.MonthHeader>
        );
      },
      children: [
        {
          title: () => {
            return (
              <S.GrossNetCell>
                <span>{t('pages.componentsPrices.tableHeaders.subHeader.gross')}</span>
              </S.GrossNetCell>
            );
          },
          dataIndex: ['price2', 'gross'],
          key: 'gross',
          align: 'right',
          width: '4.8rem',
          render: (_: any, record: any) => {
            return record.price2.gross !== null ? (
              <S.RowCellNetCoast>${record.price2.gross}</S.RowCellNetCoast>
            ) : (
              <S.RowCellNetCoast>-</S.RowCellNetCoast>
            );
          },
          onCell: (record: any) => ({
            record,
            inputType: 'money',
            require: true,
            dataIndex: ['price2', 'gross'],
            editing: isEditing(record),
            title: `${t('pages.componentsPrices.tableHeaders.subHeader.gross')}`,
            messageRequired: `${t('common.validate.typeNumber')}`,
          }),
        },
        {
          title: () => {
            return (
              <S.NetCell>
                <span>{t('pages.componentsPrices.tableHeaders.subHeader.netCost')}</span>
                <Tooltip placement="right" title={t('pages.componentsGroup.modalTax.tootip')}>
                  <img src={calculatedIcon} alt="calculated" />
                </Tooltip>
              </S.NetCell>
            );
          },
          dataIndex: ['price2', 'netCost'],
          key: 'netCost',
          align: 'right',
          width: '4.8rem',
          render: (_: any, record: any) => {
            return record.price2.netCost !== null ? (
              <S.RowCellNetCoast>
                {record.price2.isCalculated ? (
                  <S.NetCostContainer
                    onClick={() => {
                      setComponentType(record.componentType);
                      setPeriod(dayjs(record.price2.month, 'MMMM/YYYY', 'en').format('YYYY-MM-DD'));
                      openModalTax();
                    }}
                  >
                    ${record.price2.netCost}
                  </S.NetCostContainer>
                ) : (
                  `$${record.price2.netCost}`
                )}
              </S.RowCellNetCoast>
            ) : (
              //caso o registro seja FOB e não exista preço NET, repetimos o preço GROSS.
              <S.RowCellNetCoast>
                {record.source === 'FOB' && record.price2.gross ? (
                  <span>{`$${record.price2.gross}`}</span>
                ) : (
                  '-'
                )}
              </S.RowCellNetCoast>
            );
          },
          onCell: (record: any) => ({
            record,
            inputType: 'money',
            require: true,
            dataIndex: ['price2', 'netCost'],
            // editing:
            //   record.source === 'FOB' || record.price2?.netCost !== null
            //     ? false
            //     : isEditing(record),
            title: `${t('pages.componentsPrices.tableHeaders.subHeader.netCost')}`,
            messageRequired: `${t('common.validate.typeNumber')}`,
          }),
        },
      ],
    },
    {
      title: () => {
        return (
          <S.MonthHeader>
            <span>
              {dayjs(`${components[0].components[0].price3.month}`, 'MMMM/YYYY', 'en')
                .locale(t('common.dayjs'))
                .format('MMM/YY')}
            </span>
          </S.MonthHeader>
        );
      },
      children: [
        {
          title: () => {
            return (
              <S.GrossNetCell>
                <span>{t('pages.componentsPrices.tableHeaders.subHeader.gross')}</span>
              </S.GrossNetCell>
            );
          },
          dataIndex: ['price3', 'gross'],
          key: 'gross',
          align: 'right',
          width: '4.8rem',
          render: (_: any, record: any) => {
            return record.price3.gross !== null ? (
              <S.RowCellNetCoast>${record.price3.gross}</S.RowCellNetCoast>
            ) : (
              <S.RowCellNetCoast> - </S.RowCellNetCoast>
            );
          },
          onCell: (record: any) => ({
            record,
            inputType: 'money',
            require: true,
            dataIndex: ['price3', 'gross'],
            editing: isEditing(record),
            title: `${t('pages.componentsPrices.tableHeaders.subHeader.gross')}`,
            messageRequired: `${t('common.validate.typeNumber')}`,
          }),
        },
        {
          title: () => {
            return (
              <S.NetCell>
                <span>{t('pages.componentsPrices.tableHeaders.subHeader.netCost')}</span>
                <Tooltip placement="right" title={t('pages.componentsGroup.modalTax.tootip')}>
                  <img src={calculatedIcon} alt="calculated" />
                </Tooltip>
              </S.NetCell>
            );
          },
          dataIndex: ['price3', 'netCost'],
          key: 'netCost',
          align: 'right',
          width: '4.8rem',
          render: (_: any, record: any) => {
            return record.price3.netCost !== null ? (
              <S.RowCellNetCoast>
                {record.price3.isCalculated ? (
                  <S.NetCostContainer
                    onClick={() => {
                      setComponentType(record.componentType);
                      setPeriod(dayjs(record.price3.month, 'MMMM/YYYY', 'en').format('YYYY-MM-DD'));
                      openModalTax();
                    }}
                  >
                    ${record.price3.netCost}
                  </S.NetCostContainer>
                ) : (
                  `$${record.price3.netCost}`
                )}
              </S.RowCellNetCoast>
            ) : (
              //caso o registro seja FOB e não exista preço NET, repetimos o preço GROSS.
              <S.RowCellNetCoast>
                {record.source === 'FOB' && record.price3.gross ? (
                  <span>{`$${record.price3.gross}`}</span>
                ) : (
                  '-'
                )}
              </S.RowCellNetCoast>
            );
          },
          onCell: (record: any) => ({
            record,
            inputType: 'money',
            require: true,
            dataIndex: ['price3', 'netCost'],
            // editing:
            //   record.source === 'FOB' || record.price3?.netCost !== null
            //     ? false
            //     : isEditing(record),
            title: `${t('pages.componentsPrices.tableHeaders.subHeader.netCost')}`,
            messageRequired: `${t('common.validate.typeNumber')}`,
          }),
        },
      ],
    },
    {
      title: () => {
        return (
          <S.MonthHeader>
            <span>
              {dayjs(`${components[0].components[0].price4.month}`, 'MMMM/YYYY', 'en')
                .locale(t('common.dayjs'))
                .format('MMM/YY')}
            </span>
          </S.MonthHeader>
        );
      },
      children: [
        {
          title: () => {
            return (
              <S.GrossNetCell>
                <span>{t('pages.componentsPrices.tableHeaders.subHeader.gross')}</span>
              </S.GrossNetCell>
            );
          },
          dataIndex: ['price4', 'gross'],
          key: 'gross',
          align: 'right',
          width: '4.8rem',
          render: (_: any, record: any) => {
            return record.price4.gross !== null ? (
              <S.RowCellNetCoast>${record.price4.gross}</S.RowCellNetCoast>
            ) : (
              <S.RowCellNetCoast> - </S.RowCellNetCoast>
            );
          },
          onCell: (record: any) => ({
            record,
            inputType: 'money',
            require: true,
            dataIndex: ['price4', 'gross'],
            editing: isEditing(record),
            title: `${t('pages.componentsPrices.tableHeaders.subHeader.gross')}`,
            messageRequired: `${t('common.validate.typeNumber')}`,
          }),
        },
        {
          title: () => {
            return (
              <S.NetCell>
                <span>{t('pages.componentsPrices.tableHeaders.subHeader.netCost')}</span>
                <Tooltip placement="right" title={t('pages.componentsGroup.modalTax.tootip')}>
                  <img src={calculatedIcon} alt="calculated" />
                </Tooltip>
              </S.NetCell>
            );
          },
          dataIndex: ['price4', 'netCost'],
          key: 'netCost',
          align: 'right',
          width: '4.8rem',
          render: (_: any, record: any) => {
            return record.price4.netCost !== null ? (
              <S.RowCellNetCoast>
                {record.price4.isCalculated ? (
                  <S.NetCostContainer
                    onClick={() => {
                      setComponentType(record.componentType);
                      setPeriod(dayjs(record.price4.month, 'MMMM/YYYY', 'en').format('YYYY-MM-DD'));
                      openModalTax();
                    }}
                  >
                    ${record.price4.netCost}
                  </S.NetCostContainer>
                ) : (
                  `$${record.price4.netCost}`
                )}
              </S.RowCellNetCoast>
            ) : (
              //caso o registro seja FOB e não exista preço NET, repetimos o preço GROSS.
              <S.RowCellNetCoast>
                {record.source === 'FOB' && record.price4.gross ? (
                  <span>{`$${record.price4.gross}`}</span>
                ) : (
                  '-'
                )}
              </S.RowCellNetCoast>
            );
          },
          onCell: (record: any) => ({
            record,
            inputType: 'money',
            require: true,
            dataIndex: ['price4', 'netCost'],
            // editing:
            //   record.source === 'FOB' || record.price4?.netCost !== null
            //     ? false
            //     : isEditing(record),
            title: `${t('pages.componentsPrices.tableHeaders.subHeader.netCost')}`,
            messageRequired: `${t('common.validate.typeNumber')}`,
          }),
        },
      ],
    },
    {
      title: () => {
        return (
          <S.MonthHeader>
            <span>
              {dayjs(`${components[0].components[0].price5.month}`, 'MMMM/YYYY', 'en')
                .locale(t('common.dayjs'))
                .format('MMM/YY')}
            </span>
          </S.MonthHeader>
        );
      },
      children: [
        {
          title: () => {
            return (
              <S.GrossNetCell>
                <span>{t('pages.componentsPrices.tableHeaders.subHeader.gross')}</span>
              </S.GrossNetCell>
            );
          },
          dataIndex: ['price5', 'gross'],
          key: 'gross',
          align: 'right',
          width: '4.8rem',
          render: (_: any, record: any) => {
            return record.price5.gross !== null ? (
              <S.RowCellNetCoast>${record.price5.gross}</S.RowCellNetCoast>
            ) : (
              <S.RowCellNetCoast> - </S.RowCellNetCoast>
            );
          },
          onCell: (record: any) => ({
            record,
            inputType: 'money',
            require: true,
            dataIndex: ['price5', 'gross'],
            editing: isEditing(record),
            title: `${t('pages.componentsPrices.tableHeaders.subHeader.gross')}`,
            messageRequired: `${t('common.validate.typeNumber')}`,
          }),
        },
        {
          title: () => {
            return (
              <S.NetCell>
                <span>{t('pages.componentsPrices.tableHeaders.subHeader.netCost')}</span>
                <Tooltip placement="right" title={t('pages.componentsGroup.modalTax.tootip')}>
                  <img src={calculatedIcon} alt="calculated" />
                </Tooltip>
              </S.NetCell>
            );
          },
          dataIndex: ['price5', 'netCost'],
          key: 'netCost',
          align: 'right',
          width: '4.8rem',
          render: (_: any, record: any) => {
            return record.price5.netCost !== null ? (
              <S.RowCellNetCoast>
                {record.price5.isCalculated ? (
                  <S.NetCostContainer
                    onClick={() => {
                      setComponentType(record.componentType);
                      setPeriod(dayjs(record.price5.month, 'MMMM/YYYY', 'en').format('YYYY-MM-DD'));
                      openModalTax();
                    }}
                  >
                    ${record.price5.netCost}
                  </S.NetCostContainer>
                ) : (
                  `$${record.price5.netCost}`
                )}
              </S.RowCellNetCoast>
            ) : (
              //caso o registro seja FOB e não exista preço NET, repetimos o preço GROSS.
              <S.RowCellNetCoast>
                {record.source === 'FOB' && record.price5.gross ? (
                  <span>{`$${record.price5.gross}`}</span>
                ) : (
                  '-'
                )}
              </S.RowCellNetCoast>
            );
          },
          onCell: (record: any) => ({
            record,
            inputType: 'money',
            require: true,
            dataIndex: ['price5', 'netCost'],
            // editing:
            //   record.source === 'FOB' || record.price5?.netCost !== null
            //     ? false
            //     : isEditing(record),
            title: `${t('pages.componentsPrices.tableHeaders.subHeader.netCost')}`,
            messageRequired: `${t('common.validate.typeNumber')}`,
          }),
        },
      ],
    },
    {
      title: () => {
        return (
          <S.MonthHeaderLastItem>
            <span>
              {dayjs(`${components[0].components[0].price6.month}`, 'MMMM/YYYY', 'en')
                .locale(t('common.dayjs'))
                .format('MMM/YY')}
            </span>
          </S.MonthHeaderLastItem>
        );
      },
      children: [
        {
          title: () => {
            return (
              <S.GrossNetCell>
                <span>{t('pages.componentsPrices.tableHeaders.subHeader.gross')}</span>
              </S.GrossNetCell>
            );
          },
          dataIndex: ['price6', 'gross'],
          key: 'gross',
          align: 'right',
          width: '4.8rem',
          render: (_: any, record: any) => {
            return record.price6.gross !== null ? (
              <S.RowCellNetCoast>${record.price6.gross}</S.RowCellNetCoast>
            ) : (
              <S.RowCellNetCoast> - </S.RowCellNetCoast>
            );
          },
          onCell: (record: any) => ({
            record,
            inputType: 'money',
            require: true,
            dataIndex: ['price6', 'gross'],
            editing: isEditing(record),
            title: `${t('pages.componentsPrices.tableHeaders.subHeader.gross')}`,
            messageRequired: `${t('common.validate.typeNumber')}`,
          }),
        },
        {
          title: () => {
            return (
              <S.NetCell>
                <span>{t('pages.componentsPrices.tableHeaders.subHeader.netCost')}</span>
                <Tooltip placement="right" title={t('pages.componentsGroup.modalTax.tootip')}>
                  <img src={calculatedIcon} alt="calculated" />
                </Tooltip>
              </S.NetCell>
            );
          },
          dataIndex: ['price6', 'netCost'],
          key: 'netCost',
          align: 'right',
          width: '4.8rem',
          render: (_: any, record: any) => {
            return record.price6.netCost !== null ? (
              <S.RowCellNetCoast>
                {record.price6.isCalculated ? (
                  <S.NetCostContainer
                    onClick={() => {
                      setComponentType(record.componentType);
                      setPeriod(dayjs(record.price6.month, 'MMMM/YYYY', 'en').format('YYYY-MM-DD'));
                      openModalTax();
                    }}
                  >
                    ${record.price6.netCost}
                  </S.NetCostContainer>
                ) : (
                  `$${record.price6.netCost}`
                )}
              </S.RowCellNetCoast>
            ) : (
              //caso o registro seja FOB e não exista preço NET, repetimos o preço GROSS.
              <S.RowCellNetCoast>
                {record.source === 'FOB' && record.price6.gross ? (
                  <span>{`$${record.price6.gross}`}</span>
                ) : (
                  '-'
                )}
              </S.RowCellNetCoast>
            );
          },
          onCell: (record: any) => ({
            record,
            inputType: 'money',
            require: true,
            dataIndex: ['price6', 'netCost'],
            // editing:
            //   record.source === 'FOB' || record.price6?.netCost !== null
            //     ? false
            //     : isEditing(record),
            title: `${t('pages.componentsPrices.tableHeaders.subHeader.netCost')}`,
            messageRequired: `${t('common.validate.typeNumber')}`,
          }),
        },
      ],
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      width: 40,
      className: 'remove-tip',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        const actions = [
          {
            key: '1',
            label: (
              <S.EditButton
                icon={<EditOutlined />}
                type="link"
                onClick={() => {
                  prepareToEdit(record);
                }}
                data-cy="edit"
              >
                {t('common.edit')}
              </S.EditButton>
            ),
          },
        ];

        return editable ? (
          <Space direction="horizontal" size={12}>
            <Button
              type="primary"
              style={{ borderRadius: '8px' }}
              onClick={() => {
                if (handleValidateFieldsBeforeSendData()) {
                  saveRecordAfterEditing(record);
                }
              }}
              data-cy="save"
            >
              {t('common.save')}
            </Button>
            <Popconfirm
              title={t('common.cancelMessage')}
              onConfirm={() => {
                setPartNumberEdit('');
              }}
              onClick={() => {
                setPartNumberEdit('');
              }}
            >
              <Button shape="circle" icon={<AiOutlineClose />} data-cy="cancel" />
            </Popconfirm>
          </Space>
        ) : (
          <TableAction dropdownItems={actions} placement="bottom" />
        );
      },
    },
  ];

  const handleRenderFiltersTags = () => {
    return (
      <>
        {/* {params.cm && (
          <StyledFilterTag
            label={t('pages.componentsPrices.labels.cm')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={
              params.cm === 'Ambos' ? `${t(`components.modals.uploadSheets.CmOption`)}` : params.cm
            }
            closeble
            onClose={() => {
              handleUpdateFilters('cm', '');
            }}
          />
        )} */}
        {params.category && (
          <StyledFilterTag
            label={t('pages.componentsPrices.labels.category')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.category}
            closeble
            onClose={() => {
              handleUpdateFilters('category', '');
            }}
          />
        )}
        {params.source && (
          <StyledFilterTag
            label={t('pages.componentsPrices.labels.source')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.source}
            closeble
            onClose={() => {
              handleUpdateFilters('source', '');
            }}
          />
        )}
        {params.spec && (
          <StyledFilterTag
            label={t('pages.componentsPrices.labels.specification')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.spec}
            closeble
            onClose={() => {
              handleUpdateFilters('spec', '');
            }}
          />
        )}
        {params.partNumber && (
          <StyledFilterTag
            label={t('pages.componentsPrices.labels.partNumber')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.partNumber}
            closeble
            onClose={() => {
              handleUpdateFilters('partNumber', '');
            }}
          />
        )}

        {/* {params.supplier && (
          <StyledFilterTag
            label={t('pages.componentsPrices.labels.supplier')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.supplier}
            closeble
            onClose={() => {
              handleUpdateFilters('supplier', '');
            }}
          />
        )} */}
      </>
    );
  };
  // const handleTagHeader = () => {
  //   if (params.source === '' || params.source === null) {
  //     return setTag('');
  //   }
  //   setTag(params.source);
  // };

  const ApplyFilters = () => {
    if (!dateValue || dateValue[0] === null || dateValue[1] === null) {
      return setDateRequire(true);
    }
    if (dayjs(dateValue[0]).quarter() === dayjs(dateValue[1]).quarter()) {
      return oneAlert('error', t('pages.componentsPrices.alertDateError'));
    }
    setDateRequire(false);
    // handleTagHeader();
    handleFetchPrices();
  };

  const RenderTablesComponents = (TableData: Array<TableTypes>) => {
    return TableData.map((item: TableTypes) => {
      const componentsWithComponentType = item.components.map((component) => ({
        ...component,
        componentType: item.componentType,
      }));
      return (
        <S.TablesContainer key={item.componentType}>
          <S.HeaderExpandableItem>
            <ExpandableTable
              isOpen={expandableViewStatus[item.componentType]}
              onClick={() => {
                handleUpdateExpandableViews(item.componentType);
              }}
            />
            <S.HeaderExpandableContainer>
              <div>
                <span>{item.componentType}</span>
                <span>
                  {item.componentsSource === 'FOB,BR' ? (
                    <>
                      <StyledIndicationTag text="FOB" /> <StyledIndicationTag text="BR" />
                    </>
                  ) : (
                    <StyledIndicationTag text={item.componentsSource} />
                  )}
                </span>
              </div>
              <div>
                <S.ComponentsQntContainer>
                  <p>
                    <CodepenOutlined style={{ fontSize: 28 }} />
                  </p>
                  <p>
                    {item.components.length.toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                  </p>
                  <p>{t('pages.componentsPrices.tableHeaders.components')}</p>
                </S.ComponentsQntContainer>
              </div>
            </S.HeaderExpandableContainer>
          </S.HeaderExpandableItem>
          {expandableViewStatus[item.componentType] && (
            <Form form={form} component={false}>
              <S.TableContent>
                <SimpleTable
                  striped
                  bordered={false}
                  pagination={false}
                  dataSource={componentsWithComponentType}
                  rowKey="id"
                  columns={columns}
                  components={{
                    body: {
                      cell: EditableTableCell,
                    },
                  }}
                  headerNoPadding
                  noBorder
                  scrollable
                  scroll={{ x: 'max-content', y: 'auto' }}
                />
              </S.TableContent>
            </Form>
          )}
        </S.TablesContainer>
      );
    });
  };

  useEffect(() => {
    if (dayjs().quarter() === 4) {
      setDateValue([dayjs().quarter(dayjs().quarter() - 1), dayjs().quarter(dayjs().quarter())]);
    } else {
      setDateValue([dayjs().quarter(dayjs().quarter()), dayjs().quarter(dayjs().quarter() + 1)]);
    }

    setIsFirstRendering(false);
  }, []);

  const completeSocketURL = `${import.meta.env.VITE_API_WEBSOCKET}?channel=CalculatedNetcost`;

  const { sendJsonMessage, lastMessage, lastJsonMessage } = useWebSocket(
    completeSocketURL,
    {
      shouldReconnect: () => true,
      retryOnError: true,
    },
    !!completeSocketURL
  );

  const closeWebSocketConnection = (channel: string) => {
    sendJsonMessage({ action: 'unsubscribe', channel });
  };

  const UpdateNetCost = async () => {
    try {
      const { data } = await api.get(
        getURIWithFalse('components/prices', {
          ...params,
          startQ: `Q${dayjs(dateValue?.[0]).format('Q/YYYY')}`,
          endQ: `Q${dayjs(dateValue?.[1]).format('Q/YYYY')}`,
        })
      );
      setComponents(data.content || []);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setUpdateValues(false);
    }
  };

  useEffect(() => {
    if (lastMessage?.isTrusted) {
      UpdateNetCost();
    }
  }, [lastMessage, lastJsonMessage]);

  useEffect(() => {
    const onNavigateOut = history.listen((location) => {
      if (location.pathname !== pathname) {
        closeWebSocketConnection('CalculatedNetcost');
      }
    });

    return () => {
      onNavigateOut();
    };
  }, []);

  return {
    params,
    dateValue,
    partOptions,
    isLoading,
    components,
    supplierOptions,
    specOptions,
    categoryOptions,
    dateRequire,
    fileDate,
    visibleModalUpdateData,
    isProcessingData,
    visibleModalTax,
    componentType,
    period,
    maxDate,
    minDate,
    isFirstRendering,
    CMs,
    updateValues,
    closeModalTax,
    setDates,
    setDateValue,
    disabledDate,
    onOpenChange,
    handleRenderFiltersTags,
    handleUpdateFilters,
    handleRenderClearAllFilters,
    RenderTablesComponents,
    createObject,
    handleFetchPrices,
    handleFetchHeaders,
    ApplyFilters,
    setVisibleModalUpdateData,
    setMinDate,
    setMaxDate,
  };
}
