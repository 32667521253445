import { useHistory } from 'react-router-dom';
import { RoutesPath } from '../../../routes/routesPath';

export default function useCardDistribution() {
  const history = useHistory();
  const handleNavigateToAwardSplit = (bidId: string, altGroup: string, isEdit: string) => {
    history.push(RoutesPath.awardSplit, { bidId, altGroup, isEdit });
  };

  return { handleNavigateToAwardSplit };
}
