import { PercentageOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';
import { t } from 'i18next';
import { ReactNode, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import { BidStatus } from '../../helpers/enums';
import { formatDataToSelect, handleFormatValueFields } from '../../helpers/nUtils';
import { getURI, oneAlert } from '../../helpers/utils';
import { RoutesPath } from '../../routes/routesPath';
import { api } from '../../services/api';
import { services } from '../../services/services';
import theme from '../../styles/theme';
import { TotalAwardsData } from './ModalTotalAwards/types';
import * as S from './styles';
import { SupplierCard } from './SupplierCard';
import { SupplierCardProps } from './SupplierCard/types';
import {
  AwardSplitPageProps,
  AwardSplitProps,
  PayloadDistribution,
  PayloadToSend,
  setDistributionParams,
} from './types';

export function useAwardSplit() {
  const [dataAward, setDataAward] = useState<AwardSplitProps>();
  const [dataAwardModal, setDataAwardModal] = useState<TotalAwardsData>();
  const [projects, setProjects] = useState<Array<StyledSelectOptions>>([]);
  const [suppliers, setSupplier] = useState<Array<StyledSelectOptions>>([]);
  const [suppliersSelected, setSuppliersSelected] = useState<Array<string>>();
  const [visualizationType, setVisualizationType] =
    useState<SupplierCardProps['visualizationType']>('NET');

  const [projectSelected, setProjectSelected] = useState('');
  const [errorDistribution, setErrorDistribution] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const propsPage = useRef<AwardSplitPageProps>();
  const history = useHistory();
  const [isFirstRendering, setIsFirstRendering] = useState(true);

  const handleLoadData = (data: AwardSplitProps) => {
    const supplierList = data?.suppliers || [];
    setSupplier(formatDataToSelect(supplierList, false));
    setSuppliersSelected(data?.suppliers);
    setProjectSelected(data.currentProject);
  };

  const handleLoadProjects = (projectsReceived: Array<string>) => {
    setProjects(formatDataToSelect(projectsReceived, false));
    setProjectSelected(projectsReceived[0]);
  };

  const handleVerifyIfIsEditingMustBeSend = (): boolean => {
    return isFirstRendering && projectSelected !== '';
  };

  const handleNavigateToDetailsPage = (altGroup: string) => {
    history.replace(RoutesPath.awardDetails, { bidId: propsPage.current?.bidId, altGroup });
  };

  const handleGetAwards = async () => {
    try {
      setIsFirstRendering(false);
      const { data, status } = await api.get(
        getURI(`${services.rfq}/award-simulation/${propsPage.current?.bidId}/split`, {
          altGroup: propsPage.current?.altGroup,
          project: projectSelected,
          isEdit: handleVerifyIfIsEditingMustBeSend() ? propsPage.current?.isEdit : false,
        })
      );
      if (status === 200) {
        setDataAward(data);
        handleLoadData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetTotalAwardsModal = async () => {
    try {
      if (projectSelected) {
        const { data, status } = await api.get(
          getURI(`${services.rfq}/award-simulation/${propsPage.current?.bidId}/total-awards`, {
            altGroup: propsPage.current?.altGroup,
            project: projectSelected,
          })
        );

        if (status === 200) {
          setDataAwardModal(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllData = () => {
    setLoading(true);
    Promise.all([handleGetAwards(), handleGetTotalAwardsModal()])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        oneAlert('error', t('toast.errorOnList'));
        console.log(error);
      });
  };

  const handleRenderSpecs = () => {
    try {
      return dataAward?.specs.map((spec, index) => {
        return (
          <S.Labels className="normal" key={spec}>
            {index + 1 === dataAward.specs.length ? spec : `${spec} |`}
          </S.Labels>
        );
      });
    } catch (error) {
      return '-';
    }
  };

  const handleGetProjects = async (bidId: string, altGroup: string) => {
    try {
      setLoading(true);
      const { data, status } = await api.get(
        getURI(`${services.rfq}/award-simulation/${bidId}/projects`, {
          altGroup,
        })
      );
      status === 200 && handleLoadProjects(data);
    } catch (error) {
      console.log(error);
      oneAlert('error', t('toast.errorOnList'));
    }
  };

  const handleCalculatePercentProjectsDone = (): number => {
    try {
      return dataAward ? (dataAward.projectsConcluded / dataAward.totalProjects) * 100 : 0;
    } catch (error) {
      return 0;
    }
  };

  const handleVerifyIfSupplierIsSelectedOnSupplierSelect = (supplier: string): boolean => {
    return suppliersSelected
      ? suppliersSelected.some((supplierSelected) => supplierSelected === supplier)
      : false;
  };

  const handleToggleSplitDistribution = () => {
    setDataAward((prevState) => {
      if (!prevState) return prevState;
      return {
        ...prevState,
        splitAward: !prevState?.splitAward,
      };
    });
  };

  //setando todo o award para um supplier
  const handleAllAwardDistributionForOneSupplier = (position = 1) => {
    setErrorDistribution(false);
    setDataAward((prevState) => {
      if (!prevState) return prevState;
      return {
        ...prevState,
        ranking: prevState.ranking.map((supplier) => {
          return {
            ...supplier,
            demand: supplier.position === position ? prevState.totalDemand : 0,
            demandPercentage: supplier.position === position ? 100 : 0,
          };
        }),
      };
    });
  };

  const handleSetAwardsByClickOnCard = (position: number) => {
    if (dataAward?.status !== BidStatus.RESULT && !dataAward?.splitAward) {
      handleAllAwardDistributionForOneSupplier(position);
    }
  };

  const handleRenderAwardSupplierCards = (): ReactNode => {
    try {
      return dataAward?.ranking.map((supplier) => {
        const projectIsSelected = handleVerifyIfSupplierIsSelectedOnSupplierSelect(
          supplier.supplier
        );
        return (
          <SupplierCard
            altGroup={dataAward.altGroup}
            type={projectIsSelected ? 'NORMAL' : 'EMPTY'}
            visualizationType={visualizationType}
            key={supplier.supplier}
            data={supplier}
            select={supplier.demand > 0}
            onClick={handleSetAwardsByClickOnCard}
          />
        );
      });
    } catch (error) {
      return null;
    }
  };

  const handleVerifyIfProjectIsNotFirst = (): boolean => {
    return projects.findIndex((project) => project.value === projectSelected) > 0;
  };

  const handlePreviousProject = () => {
    try {
      const indexProjectPrevious = projects.findIndex(
        (project) => project.value === projectSelected
      );
      indexProjectPrevious > 0 && setProjectSelected(projects[indexProjectPrevious - 1].label);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNextProject = () => {
    try {
      debugger;
      const indexCurrentProject = projects.findIndex(
        (project) => project.value === projectSelected
      );
      //caso ainda exista um projeto a ser informado os valores
      if (indexCurrentProject < projects.length - 1) {
        setProjectSelected(projects[indexCurrentProject + 1].label);
      } else {
        handleNavigateToDetailsPage(propsPage.current?.altGroup || '');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCalculatePercentageByQuantity = (quantity: number): number => {
    try {
      return dataAward
        ? Number(
            handleFormatValueFields({
              number: (quantity / dataAward.totalDemand) * 100,
              notation: 'standard',
            })
          )
        : 0;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const handleCalculateTotalItensByPercent = (percent: number): number => {
    try {
      return dataAward ? (percent / 100) * dataAward.totalDemand : 0;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const handleSetDistribution = ({ position, percent, quantity }: setDistributionParams) => {
    try {
      //limpando erro no formulário caso tenha sido exibido
      setErrorDistribution(false);

      if (quantity || quantity === 0) {
        setDataAward((prevState) => {
          if (!prevState) return prevState;
          return {
            ...prevState,
            ranking: prevState?.ranking.map((supplier) => {
              return supplier.position !== position
                ? supplier
                : {
                    ...supplier,
                    demand: Math.round(quantity),
                    demandPercentage: handleCalculatePercentageByQuantity(quantity),
                  };
            }),
          };
        });
      }

      if (percent || percent === 0) {
        setDataAward((prevState) => {
          if (!prevState) return prevState;
          return {
            ...prevState,
            ranking: prevState?.ranking.map((supplier) => {
              return supplier.position !== position
                ? supplier
                : {
                    ...supplier,
                    demand: handleCalculateTotalItensByPercent(percent),
                    demandPercentage: percent,
                  };
            }),
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerifyIfSupplierIsDisabled = (position: number): boolean => {
    const supplierByPosition = dataAward?.ranking.find(
      (supplier) => supplier.position === position
    );
    return suppliersSelected
      ? !suppliersSelected.includes(supplierByPosition?.supplier ?? '')
      : false;
  };

  const handleValidateAwardDistributionIsComplete = () => {
    try {
      const totalDistributed = dataAward?.ranking.reduce(
        (total, supplier) => total + supplier.demand,
        0
      );
      return totalDistributed === dataAward?.totalDemand;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const handleGenerateDistribution = (): Array<PayloadDistribution> => {
    try {
      const distribution: Array<PayloadDistribution> = [];
      dataAward?.ranking.forEach((supplier) => {
        if (supplier.demand > 0) {
          distribution.push({
            demand: supplier.demand,
            demandPercentage: supplier.demandPercentage,
            supplier: supplier.supplier,
          });
        }
      });
      return distribution;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleGeneratePayloadToSend = (): PayloadToSend => {
    return {
      altGroup: propsPage.current?.altGroup || '',
      project: projectSelected || '',
      splitAward: dataAward?.splitAward || false,
      //mesmo em caso de distribuição 100% para um único supplier, será enviado dados de distribuição
      distribution: handleGenerateDistribution(),
      remark: dataAward?.remark || '',
    };
  };

  const handleSendAwardDistribution = async () => {
    try {
      if (handleValidateAwardDistributionIsComplete()) {
        setLoading(true);
        const { status } = await api.post(
          `${services.rfq}/award-simulation/${propsPage.current?.bidId}/split`,
          handleGeneratePayloadToSend()
        );
        status === 204 && handleNextProject();
      } else {
        setErrorDistribution(true);
      }
    } catch (error: any) {
      console.log(error);
      oneAlert('error', error.message || t('toast.errorOnSave'));
      setLoading(false);
    }
  };

  const handleRenderEditsSplitAward = (): ReactNode => {
    return dataAward?.ranking.map((supplier, index) => {
      //os suppliers são recebidos do back ordenados por posição logo índice + 1  =  posição no ranking
      const RANKING_POSITION = index + 1;
      return (
        <S.CardInputs key={supplier.position}>
          <S.ContainerInputs>
            <S.Labels className="small">{t('pages.awardSplit.labels.demand')}</S.Labels>
            <InputNumber
              style={{ width: '100%' }}
              max={dataAward.totalDemand}
              min={0}
              disabled={handleVerifyIfSupplierIsDisabled(RANKING_POSITION)}
              value={Math.round(supplier.demand)}
              onChange={(value) => {
                handleSetDistribution({
                  position: RANKING_POSITION,
                  quantity: Number(value) || 0,
                });
              }}
            />
          </S.ContainerInputs>
          <S.ContainerInputs>
            <InputNumber
              style={{ width: '100%' }}
              max={100}
              min={0}
              disabled={handleVerifyIfSupplierIsDisabled(RANKING_POSITION)}
              value={supplier.demandPercentage}
              onChange={(value) => {
                handleSetDistribution({
                  position: RANKING_POSITION,
                  percent: Number(value) || 0,
                });
              }}
              suffix={<PercentageOutlined style={{ color: theme.colorsDesignSystem.primary }} />}
            />
          </S.ContainerInputs>
        </S.CardInputs>
      );
    });
  };

  const handleSaveRemark = (remarkValue: string) => {
    setDataAward((prevState) => {
      if (!prevState) return prevState;
      return {
        ...prevState,
        remark: remarkValue,
      };
    });
  };

  const handleNavigateBackAwardSimulation = () => {
    history.push(`/awards/${propsPage.current?.bidId}`);
  };

  const handleValidateIfAllProjectsConcluded = () => {
    return dataAward?.totalProjects === dataAward?.projectsConcluded;
  };

  const handleVerifyIfSplitMustBeDisabled = (): boolean => {
    if (dataAward?.suppliers.length === 1) return true;
    return false;
  };

  const handleRenderTitleButtonNext = (): string => {
    if (handleValidateIfAllProjectsConcluded()) {
      return t('pages.awardSplit.labels.continue');
    }

    return t('pages.awardSplit.labels.addContinue');
  };

  return {
    handleRenderSpecs,
    handleCalculatePercentProjectsDone,
    handleRenderAwardSupplierCards,
    setVisualizationType,
    setProjectSelected,
    handleVerifyIfProjectIsNotFirst,
    setSuppliersSelected,
    handleSetDistribution,
    handleAllAwardDistributionForOneSupplier,
    handleVerifyIfSupplierIsDisabled,
    handleValidateAwardDistributionIsComplete,
    setErrorDistribution,
    handleSendAwardDistribution,
    setVisibleModal,
    handleGetAwards,
    handleGetTotalAwardsModal,
    handleGetAllData,
    handleLoadData,
    handleToggleSplitDistribution,
    handleRenderEditsSplitAward,
    handleSaveRemark,
    handleNavigateBackAwardSimulation,
    handlePreviousProject,
    handleValidateIfAllProjectsConcluded,
    handleNavigateToDetailsPage,
    handleVerifyIfSplitMustBeDisabled,
    setIsFirstRendering,
    handleRenderTitleButtonNext,
    handleGetProjects,
    dataAwardModal,
    errorDistribution,
    projectSelected,
    projects,
    suppliers,
    dataAward,
    suppliersSelected,
    visibleModal,
    loading,
    propsPage,
  };
}
