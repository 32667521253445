/* eslint-disable no-undefined */
import { FolderViewOutlined, SearchOutlined } from '@ant-design/icons';
import { Checkbox, Empty } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import StyledButton from '../../../components/Common/StyledButton';
import StyledModal from '../../../components/Common/StyledModal';
import StyledRangePiker from '../../../components/Common/StyledRangePicker';
import StyledSelect from '../../../components/Common/StyledSelect';
import { Loading } from '../../../components/Loading';

import { TaxConfiguration } from '../ModalTax';
import * as S from './styles';
import { RangeValue } from './types';
import { usePricesPage } from './usePricesPage';

export function PricesPage() {
  const {
    params,
    dateValue,
    partOptions,
    isLoading,
    components,
    supplierOptions,
    specOptions,
    categoryOptions,
    dateRequire,
    fileDate,
    visibleModalUpdateData,
    visibleModalTax,
    componentType,
    period,
    updateValues,
    maxDate,
    isFirstRendering,
    minDate,
    closeModalTax,
    setDates,
    setDateValue,
    disabledDate,
    onOpenChange,
    handleRenderFiltersTags,
    handleUpdateFilters,
    handleRenderClearAllFilters,
    RenderTablesComponents,
    createObject,
    handleFetchPrices,
    handleFetchHeaders,
    ApplyFilters,
    setVisibleModalUpdateData,
    setMinDate,
    setMaxDate,
    CMs,
  } = usePricesPage();

  useEffect(() => {
    !isFirstRendering && handleFetchPrices();
  }, [isFirstRendering]);

  useEffect(() => {
    handleFetchHeaders();
  }, [params.cm, params.category, params.spec, params.source, params.withPrices]);

  useEffect(() => {
    createObject(components);
  }, [components]);

  const { t } = useTranslation();
  const sourceOptions = [
    { value: 'FOB', label: 'FOB' },
    { value: 'BR', label: 'BR' },
  ];
  return (
    <>
      {visibleModalTax && (
        <StyledModal
          title={t('pages.componentsGroup.modalTax.modalTaxTitle')}
          body={
            <TaxConfiguration componentType={componentType} period={period} componentTab="price" />
          }
          onClose={closeModalTax}
          footer={null}
          open
          width="90%"
        />
      )}
      <S.ContainerFilters>
        <StyledSelect
          options={CMs}
          label={t('pages.componentsPrices.labels.cm')}
          placeholder={t('pages.componentsPrices.placeHolders.cm')}
          onChange={(value: any) => handleUpdateFilters('cm', value)}
          styles={{ width: '6rem' }}
          value={params.cm}
        />
        <StyledSelect
          styles={{ width: '8rem' }}
          options={categoryOptions}
          label={t('pages.componentsPrices.labels.category')}
          placeholder={t('pages.componentsPrices.placeHolders.category')}
          onChange={(value: any) => handleUpdateFilters('category', value)}
          value={params.category || null}
          showSearch
          suffixIcon={
            <S.SearchButton>
              <SearchOutlined className="icon" />
            </S.SearchButton>
          }
        />
        <StyledSelect
          styles={{ width: '6rem' }}
          options={sourceOptions}
          label={t('pages.componentsPrices.labels.source')}
          placeholder={t('pages.componentsPrices.placeHolders.source')}
          onChange={(value: any) => handleUpdateFilters('source', value)}
          value={params.source || null}
        />
        <StyledSelect
          styles={{ width: '10rem' }}
          label={t('pages.componentsPrices.labels.specification')}
          placeholder={t('pages.componentsPrices.placeHolders.specificaton')}
          suffixIcon={
            <S.SearchButton>
              <SearchOutlined className="icon" />
            </S.SearchButton>
          }
          showSearch
          options={specOptions}
          value={params.spec || null}
          onChange={(value: any) => {
            handleUpdateFilters('spec', value);
          }}
        />
        <StyledSelect
          styles={{ width: '9rem' }}
          label={t('pages.componentsPrices.labels.partNumber')}
          placeholder={t('pages.componentsPrices.placeHolders.partNumber')}
          onChange={(value: any) => {
            handleUpdateFilters('partNumber', value);
          }}
          showSearch
          value={params.partNumber || null}
          options={partOptions}
          suffixIcon={
            <S.SearchButton>
              <SearchOutlined className="icon" />
            </S.SearchButton>
          }
        />

        {/* <StyledSelect
          styles={{ width: '8rem' }}
          label={t('pages.componentsPrices.labels.supplier')}
          placeholder={t('pages.componentsPrices.placeHolders.supplier')}
          suffixIcon={
            <S.SearchButton>
              <SearchOutlined className="icon" />
            </S.SearchButton>
          }
          showSearch
          options={supplierOptions}
          value={params.supplier || null}
          onChange={(value: any) => {
            handleUpdateFilters('supplier', value);
          }}
        /> */}

        <StyledRangePiker
          label={t('pages.componentsPrices.labels.quarter')}
          picker="quarter"
          value={dateValue}
          disabledDate={disabledDate}
          placeholder={[
            t('pages.componentsPrices.placeHolders.startQuarter'),
            t('pages.componentsPrices.placeHolders.endQuarter'),
          ]}
          onCalendarChange={(val: RangeValue) => {
            if (val) {
              if (val[0] !== null) {
                setMinDate(dayjs(`01-01-${val[0]?.year()}`));
                setMaxDate(dayjs(`12-31-${val[0]?.year()}`));
              } else if (val[1] !== null) {
                setMinDate(dayjs(`01-01-${val[1]?.year()}`));
                setMaxDate(dayjs(`12-31-${val[1]?.year()}`));
              }
            }
            setDates(val);
          }}
          onChange={(val) => {
            setDateValue(val);
          }}
          onOpenChange={onOpenChange}
          changeOnBlur
          required={dateRequire}
          minDate={minDate}
          maxDate={maxDate}
        />
        <Checkbox
          style={{ padding: '0.375rem' }}
          checked={params.withPrices}
          onChange={() => {
            handleUpdateFilters('withPrices', !params.withPrices);
          }}
        >
          {t('pages.componentsPrices.labels.withPrices')}
        </Checkbox>
        <StyledButton
          onClick={() => ApplyFilters()}
          variant="primary"
          text={t('pages.componentsPrices.titleButtons.apply')}
        />
      </S.ContainerFilters>
      <S.FiltersTagContainer>
        {handleRenderFiltersTags()}
        {handleRenderClearAllFilters()}
      </S.FiltersTagContainer>
      <S.ReferenceData>
        <FolderViewOutlined />
        {t('pages.componentsPrices.tabTitles.prices')}
        <span className="Text">{fileDate}</span>
      </S.ReferenceData>
      {isLoading ? (
        <Loading />
      ) : components.length > 0 ? (
        RenderTablesComponents(components)
      ) : (
        <Empty />
      )}
    </>
  );
}
