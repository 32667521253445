import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { YearsScenariosContextType } from './types';
import { api } from '../../services/api';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';

const AppContext = createContext<YearsScenariosContextType | undefined>(
  {} as YearsScenariosContextType
);

export function YearsScenariosContextProvider({ children }: { children: ReactNode }) {
  const [yearsScenarios, setYearsScenarios] = useState<Array<StyledSelectOptions>>([]);
  const handleGetYears = async () => {
    try {
      const { data: content, status } = await api.get<Array<string>>('scenarios/filter');
      if (status === 200) {
        setYearsScenarios(
          content.map((year) => {
            return {
              value: year,
              label: year,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleGetYears();
  }, []);

  const data = useMemo(
    () => ({
      yearsScenarios,
    }),
    [yearsScenarios]
  );
  return <AppContext.Provider value={data}>{children}</AppContext.Provider>;
}

export function useYearsScenariosContext() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }

  return context;
}
