import { useEffect } from 'react';
import { Col, ConfigProvider, Form, Popconfirm, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyledPageHeader } from '../../components/Common/StyledPageHeader/styles';
import { SelectInput } from '../../components/LateralFilter/SelectInput';
import { Loading } from '../../components/Loading';
import { useSpot } from './useSpot';

import * as S from './styles';
import { ActionButton } from '../../components/ActionButton';
import { Button } from '../../components/Button';
import { MultipleEditableCell } from '../../components/MultipleEditableCell';
import SimpleTable from '../../components/Tables/SimpleTable';

export function SpotPage() {
  const { t } = useTranslation();

  const {
    isLoading,
    form,
    validateMessages,
    mergedColumns,
    data,
    fetchStrategies,
    canUpdate,
    isEditing,
    handleSaveStrategy,
    totalScore,
    productsOptions,
    clearFilter,
    searchFilter,
    params,
    cancel,
    handleEdit,
  } = useSpot();

  useEffect(() => {
    if (canUpdate) {
      fetchStrategies();
    }
  }, [params]);

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <Col span={24}>
                <StyledPageHeader
                  title={`${t('pages.spot.title')} - ${params.productName}`}
                  className="pl-0 pr-0"
                  extra={[
                    <S.SpotTotalScore key={1}>
                      {t('pages.spot.fields.totalScore')}:{' '}
                      <span>{Number(totalScore)?.toFixed(2)} </span>
                      {t('pages.spot.fields.points')}
                    </S.SpotTotalScore>,
                  ]}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                <S.SearchFilter>
                  <Col span={6}>
                    <SelectInput
                      options={productsOptions}
                      placeholder={t('filter.product')}
                      onSelect={(id) => searchFilter({ ...params, productName: id }, 'submit')}
                      value={params.productName}
                    />
                  </Col>
                  <Col>
                    <ActionButton
                      key={1}
                      icon={null}
                      iconEnd={null}
                      onClick={handleEdit}
                      disabled={isEditing}
                      buttonText={t('common.edit')}
                    >
                      {null}
                    </ActionButton>
                  </Col>
                </S.SearchFilter>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24} style={{ marginTop: 16 }}>
                <Form form={form} component={false} validateMessages={validateMessages}>
                  <SimpleTable
                    rowKey="id"
                    data-cy="table"
                    striped
                    components={{
                      body: {
                        cell: MultipleEditableCell,
                      },
                    }}
                    columns={mergedColumns}
                    dataSource={[...new Set(data?.partTypes)]}
                    pagination={false}
                  />
                </Form>
              </Col>
            </Row>
            {isEditing && (
              <Row justify="center">
                <Col span={24}>
                  <Row justify="end" gutter={[16, 0]}>
                    <Col>
                      <Popconfirm
                        title={t('common.cancelMessage')}
                        onConfirm={() => {
                          cancel();
                        }}
                        onCancel={(e) => e?.stopPropagation()}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Button
                          buttonText=""
                          icon={null}
                          iconEnd={null}
                          type="button"
                          onClick={null}
                        >
                          Cancelar
                        </Button>
                      </Popconfirm>
                    </Col>
                    <Col>
                      <Button
                        buttonText=""
                        icon={null}
                        iconEnd={null}
                        onClick={handleSaveStrategy}
                        type="primary"
                      >
                        Salvar Estrategia
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        )}
      </Row>
    </main>
  );
}
