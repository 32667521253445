import { Flex } from 'antd';
import styled from 'styled-components';
import StyledButton from '../../components/Common/StyledButton';

export const Container = styled.main`
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.background};
  padding-bottom: 2rem;
`;

export const BackButton = styled(StyledButton)`
  padding-left: 0;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SpanTile = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const SpanQtItens = styled.span`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};

  b {
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
`;

export const SpanLabel = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  font-weight: 300;
`;

export const SupplierSpan = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const UserContainer = styled.div`
  display: flex;
  padding-left: 0.25rem;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  border-left: 2px solid ${({ theme }) => theme.colorsDesignSystem.pipeSeparatorColor};

  span {
    color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
    font-size: 1.25rem;
  }
`;

export const ContentContainer = styled.div`
  margin-top: 1.938rem;
  display: flex;
  flex-direction: row;
  gap: 1.625rem;
  justify-content: center;
`;

export const ContentLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  min-width: 24.468rem;
  width: 20%;
  min-height: 53.125rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const CardsBidContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  gap: 1rem;
`;

export const HeaderContentLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.75rem 0.75rem 0 0;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  height: 5rem;
  padding: 1.5rem;
`;

export const ContentRightContainer = styled.div`
  display: flex;
  background-color: red;
  flex-direction: column;
  width: 70%;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};

  @media (min-width: 1600px) {
    width: 80%;
  }
`;

export const HeaderContentRight = styled.div`
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.75rem 0.75rem 0 0;
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};

  span {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  .spanBlue {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }

  .row {
    gap: 0.5rem;
  }
`;

export const TitleCardRight = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  margin: 0;
`;

export const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 47.813rem;
  padding: 2.75rem 2.5rem 1.875rem 2.5rem;
`;

export const Padding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2.75rem 2.5rem 1.875rem 2.5rem;
`;

export const ContentRightImages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .subTitle {
    color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
    text-align: center;
  }

  .title {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colorsDesignSystem.black85};
    text-align: center;

    margin-top: 2.5rem;
  }
`;

export const ContainerImages = styled.div`
  height: 100%;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};

  .subTitle {
    color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.grayAcer};
  }
`;

export const ContainerCardsRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const CardInsideRightContent = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.375rem;
  background-color: ${({ theme }) =>
    theme.colorsDesignSystem.newTablesColors.colorBackgroundSubItens};
  gap: 0.75rem;
  width: 30rem;
  padding: 1rem 1.25rem 1rem 1.25rem;

  .row {
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;

    span:first-child {
      color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
    }

    span:not(:last-child):not(:first-child) {
      border-right: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder}; /* Define a borda à direita  */
      padding-right: 0.5rem;
    }
  }

  .row:not(:first-child) {
    margin-top: 1.563rem;
  }

  .blue {
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }

  span {
    font-size: 0.875rem;
  }
`;

export const MiddleCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.375rem;
  background-color: ${({ theme }) =>
    theme.colorsDesignSystem.newTablesColors.colorBackgroundSubItens};

  min-width: 12rem;
  padding: 1rem;

  .row {
    background-color: #e3ecff;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.688rem;

    span {
      font-size: 0.75em;
      color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.input_labels};
    }

    b {
      font-size: 0.75em;
      font-weight: 600;
      color: ${({ theme }) => theme.colorsDesignSystem.text};
    }
  }

  .rowCenter {
    margin-top: 1.5rem;
    gap: 0.5rem;

    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
    }

    p {
      font-size: 1rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
    }
  }
`;

export const RightCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  border-radius: 0.375rem;
  background-color: ${({ theme }) =>
    theme.colorsDesignSystem.newTablesColors.colorBackgroundSubItens};
  min-width: 12rem;
  padding: 1rem;

  .row {
    gap: 0.5rem;
    display: flex;
    align-items: center;

    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
    }

    b {
      font-size: 1rem;
      font-weight: 600;
    }

    .blue {
      font-size: 1.5rem;
      color: ${({ theme }) => theme.colorsDesignSystem.blue};
      font-weight: 600;
    }
  }
  .row:last-child {
    margin-top: 2.5rem;
  }
`;

export const FormContainer = styled.div`
  margin-top: 1.875rem;
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
`;

export const ContainerInputsForm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.875rem;

  .labels {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
    margin-right: 0.5rem;
  }
`;

export const ContainerComment = styled.div`
  display: flex;
  flex-direction: column;

  .labels {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
    margin-right: 0.5rem;
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 11.875rem;
  align-items: center;
  align-self: flex-end;
  justify-content: space-between;
  height: 3.75rem;
  border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  padding-left: 2.875rem;
  padding-right: 2.875rem;
`;

export const ContainerFooterOnlyOneButton = styled.div<{ addPadding?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  width: 100%;
  padding: ${({ addPadding }) => addPadding && '1rem 2rem'};
`;

export const RoundContainer = styled(Flex)`
  padding: 1.125rem 1.78rem;
  justify-content: space-between;
  border-radius: 0.375rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.secondaryGrayBackground};
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
  margin-top: 1.5rem;

  .title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
  }

  .label {
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
  }

  .value {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
  }
`;

export const Classification = styled.span`
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: 3.5rem;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  padding: 0.1rem 0.5rem;
`;
