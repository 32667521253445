/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-unused-vars */
import { useEffect, useRef } from 'react';
import { SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import i18n from 'i18next';
import moment from 'moment';
import { Row, message as antMessage } from 'antd';
import theme from '../styles/theme';

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export const groupsOptions = (noneValue = '') => {
  return [
    { value: noneValue, label: i18n.t('common.none') },
    { value: '1', label: 'User' },
    { value: '2', label: 'Admin' },
  ];
};

export const states = (noneValue = '') => {
  return [
    { value: noneValue, label: i18n.t('common.none') },
    { value: 'AC', label: 'AC' },
    { value: 'AL', label: 'AL' },
    { value: 'AP', label: 'AP' },
    { value: 'AM', label: 'AM' },
    { value: 'BA', label: 'BA' },
    { value: 'CE', label: 'CE' },
    { value: 'DF', label: 'DF' },
    { value: 'ES', label: 'ES' },
    { value: 'GO', label: 'GO' },
    { value: 'MA', label: 'MA' },
    { value: 'MT', label: 'MT' },
    { value: 'MS', label: 'MS' },
    { value: 'MG', label: 'MG' },
    { value: 'PA', label: 'PA' },
    { value: 'PB', label: 'PB' },
    { value: 'PR', label: 'PR' },
    { value: 'PE', label: 'PE' },
    { value: 'PI', label: 'PI' },
    { value: 'RJ', label: 'RJ' },
    { value: 'RN', label: 'RN' },
    { value: 'RS', label: 'RS' },
    { value: 'RO', label: 'RO' },
    { value: 'RR', label: 'RR' },
    { value: 'SC', label: 'SC' },
    { value: 'SP', label: 'SP' },
    { value: 'SE', label: 'SE' },
    { value: 'TO', label: 'TO' },
  ];
};

export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const capitalizeFirstLetter = (string) => {
  try {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  } catch (error) {
    console.log(error);
    return '-';
  }
};

export const monthWithNumber = (month) => {
  if (!month) return;

  switch (month.toLowerCase()) {
    case 'january':
      return '01 - JAN';
    case 'jan':
      return '01 - JAN';
    case 'february':
      return '02 - FEV';
    case 'fev':
      return '02 - FEV';
    case 'feb':
      return '02 - FEB';
    case 'march':
      return '03 - MAR';
    case 'mar':
      return '03 - MAR';
    case 'april':
      return '04 - ABR';
    case 'abr':
      return '04 - ABR';
    case 'apr':
      return '04 - APR';
    case 'mai':
      return '05 - MAI';
    case 'may':
      return '05 - MAY';
    case 'june':
      return '06 - JUN';
    case 'jun':
      return '06 - JUN';
    case 'july':
      return '07 - JUL';
    case 'jul':
      return '07 - JUL';
    case 'august':
      return '08 - AGO';
    case 'ago':
      return '08 - AGO';
    case 'aug':
      return '08 - AUG';
    case 'september':
      return '09 - SET';
    case 'set':
      return '09 - SET';
    case 'sep':
      return '09 - SEP';
    case 'october':
      return '10 - OUT';
    case 'out':
      return '10 - OUT';
    case 'oct':
      return '10 - OCT';
    case 'november':
      return '11 - NOV';
    case 'nov':
      return '11 - NOV';
    case 'dez':
      return '12 - DEZ';
    case 'december':
      return '12 - DEC';
    case 'dec':
      return '12 - DEC';
    default:
      return '-';
  }
};

export function oneAlert(type, message) {
  if (type === 'success') {
    return antMessage.success(message);
  }
  if (type === 'error') {
    return antMessage.error(message);
  }
  if (type === 'info') {
    return antMessage.info(message);
  }
  if (type === 'loading') {
    return antMessage.open({
      content: (
        <Row>
          <SyncOutlined
            style={{ color: theme.colors.blue, fontSize: '0.875rem', marginRight: '0.875rem' }}
          />
          <span>{message}</span>
        </Row>
      ),
    });
  }
  antMessage.error('NO_ALERT_TYPE');

  // toast.dismiss();
  // setTimeout(() => {
  //   if (type === 'success') {
  //     toast.success(message, '', { allowHtml: true });
  //   } else if (type === 'error') {
  //     toast.error(message, '', { allowHtml: true });
  //   } else if (type === 'info') {
  //     toast.info(message, '', { allowHtml: true });
  //   } else {
  //     toast.error('NO_ALERT_TYPE');
  //   }
  // }, 400);
}

export const getErrorMessage = (error) => {
  if (!error) return '';
  if (typeof error === 'string') return error;

  let message = '';
  const messageCode = error?.errorResponse?.messageCode;
  const messageDetail = error?.errorResponse?.messageDetail;

  if (typeof messageCode === 'string') {
    if (messageCode.includes('duplication.exception')) {
      if (messageDetail && messageDetail.length) {
        message = `${i18n.t('toast.exDuplication')} ${messageDetail}`;
      } else {
        message = i18n.t('toast.exDuplication');
      }
    } else if (messageCode.includes('bom.name.rule.validation.exception')) {
      message = i18n.t('toast.skuFormat');
    } else if (
      messageCode.includes('sku.invalid.exception') ||
      messageCode.includes('sku.invalid.name.exception')
    ) {
      message = i18n.t('toast.skuFormat');
    } else if (messageCode.includes('component.not.found')) {
      if (messageDetail && messageDetail.length) {
        message = `${i18n.t('toast.missingComponent')} ${messageDetail}`;
      } else {
        const findComponent = messageCode.split(':');
        const component = findComponent.length ? `: ${findComponent[1].trim()}` : '';
        message = `${i18n.t('toast.missingComponent')} ${component}`;
      }
    } else if (messageCode.includes('supplier.not.found')) {
      if (messageDetail && messageDetail.length) {
        message = `${i18n.t('toast.missingSupplier')} ${messageDetail}`;
      } else {
        const findComponent = messageCode.split(':');
        const component = findComponent.length ? `: ${findComponent[1].trim()}` : '';
        message = `${i18n.t('toast.missingSupplier')} ${component}`;
      }
    } else if (
      messageCode.includes(
        'optimizationSetupComponentMin.invalid.major.optimizationSetupComponentMax'
      )
    ) {
      message = i18n.t('toast.errorComponentMax');
    } else if (
      messageCode.includes(
        'optimizationSetupComponentMax.invalid.less.optimizationSetupComponentMin'
      )
    ) {
      message = i18n.t('toast.errorComponentMin');
    } else if (messageCode.includes('groups.delete.error')) {
      const usersInTheGroup = messageDetail.match(/\[(.*?)\]/)[1];
      message = `${i18n.t('toast.errorOnGroupDelete')} [${usersInTheGroup}]`;
    } else if (messageCode.includes('component.type.duplicite')) {
      message = `${i18n.t('toast.errorDuplicateComponent')}${messageDetail}`;
    } else if (messageCode.includes('component.group.spec.duplicidade')) {
      message = `${i18n.t('toast.errorDuplicateSpec')}${messageDetail}`;
    } else if (messageDetail.includes('not found on SKU')) {
      const itemsku = messageDetail.split(',')[0];
      message = `${itemsku} ${i18n.t('toast.skuNotFound')}`;
    }
  }

  if (message === '') {
    console.log('getError=>', error);
  } else {
    console.log('getErrorMessage=>', message);
  }

  return message;
};

export const randomString = () => {
  return Math.random().toString(36).slice(2) + new Date().valueOf();
};

export const csvArrayToObj = (csvData) => {
  if (!csvData) return null;
  let hasEmptyKeys;

  const data = csvData
    .map((csvLine, csvIndex) => {
      if (csvIndex === 0 || !csvLine.length) return null; // skip header and empty lines
      const objLine = csvLine.reduce(
        (a, v, i) => {
          let property = csvData[0][i].toLowerCase();
          if (property === 'partnumber') property = 'partNumber';

          return { ...a, [property]: v };
        },
        {
          id: randomString(),
        }
      );

      if (!objLine.supplier || !objLine.spec || !objLine.partNumber) {
        hasEmptyKeys = true;
        return null;
      }
      return objLine;
    })
    .filter((filter) => !!filter); //filter empty lines

  if (hasEmptyKeys) {
    oneAlert('info', i18n.t('toast.hasEmptyKeys'));
    return null;
  }

  return data;
};

export const transformDataPrices = (dataSource) => {
  try {
    const data = [];
    dataSource?.forEach((spec) => {
      spec?.children?.forEach((price) => {
        const obj = {
          spec: spec.spec,
          partNumber: price.partNumber,
          description: price.description,
          supplier: price.supplier.shortName,
          january: price.january,
          february: price.february,
          march: price.march,
          april: price.april,
          may: price.may,
          june: price.june,
          july: price.july,
          august: price.august,
          september: price.september,
          october: price.october,
          november: price.november,
          december: price.december,
        };

        data.push(obj);
      });
    });

    return data;
  } catch (e) {
    console.log('ErrorTransformDataPrices=>', e);
  }
};

export const transformDataForecast = (dataSource) => {
  try {
    const data = [];
    dataSource?.forEach((sku) => {
      const obj = {
        sku: sku.sku,
        project: sku.project,
        modelName: sku.modelName,
        january: sku.children[0].january,
        february: sku.children[0].february,
        march: sku.children[0].march,
        april: sku.children[0].april,
        may: sku.children[0].may,
        june: sku.children[0].june,
        july: sku.children[0].july,
        august: sku.children[0].august,
        september: sku.children[0].september,
        october: sku.children[0].october,
        november: sku.children[0].november,
        december: sku.children[0].december,
        totalMonth: sku.children[0].totalMonth,
      };

      data.push(obj);
    });

    return data;
  } catch (e) {
    console.log('ErrorTransformDataForecast=>', e);
  }
};

export const debounce = (callback, timer) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    // eslint-disable-next-line
    timeout = setTimeout(() => callback.apply(context, args), timer);
  };
};

export const hasPropertyWithHeader = (obj) => {
  const hasPropWithHeader = Object.getOwnPropertyNames(obj)?.find((item) =>
    item?.includes('header_')
  );
  return !!hasPropWithHeader;
};

export const arrayToStringMessage = (message) => {
  if (!message) return '';
  if (typeof message === 'string') return message;

  if (Array.isArray(message)) {
    let stringMessage = '';
    message.forEach((item) => {
      // eslint-disable-next-line
      stringMessage += item.message + '\n';
    });

    return stringMessage;
  }
};

export function slugify(string, separator = '-') {
  return string
    ? string
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replace(/[^a-z0-9 -]/g, '')
        .trim()
        .replace(/\s+/g, separator)
    : '';
}

export function fileName(name, cenario) {
  const fullName = cenario ? `${name}-${slugify(cenario)}` : name;
  return `${fullName}-${new Date().toISOString().slice(0, 10)}.csv`;
}

export function filterBaseScenarios(scenarios) {
  if (!Array.isArray(scenarios)) return [];
  return scenarios.filter((scenario) => !scenario.base);
}

export function filterUserScenarios(scenarios) {
  if (!Array.isArray(scenarios)) return [];
  return scenarios.filter((scenario) => scenario.base);
}

export function parseScenarioName(scenario) {
  //if changed, please review the parseScenarios2 function
  if (!scenario || !scenario?.name?.length) return;

  const name = scenario?.targetYear
    ? `${scenario.name} - ${scenario.targetYear} (v${scenario.version})`
    : `${scenario.name} (v${scenario.version})`;
  return name;
}

export function parsePpbName(ppb) {
  if (!ppb || !ppb.inforceDate || !ppb.targetScore || !ppb.productName) {
    return 'Erro no objeto ppb';
  }

  //Notebook (2022) - 42 pontos
  const datePpb = new Date(ppb.inforceDate).getFullYear();
  return `${ppb.productName} (${datePpb}) - ${ppb.targetScore}`;
}

export function getPpbOptions(ppbs) {
  if (!ppbs.length) return [];

  return ppbs.map((ppb) => {
    return {
      label: parsePpbName(ppb),
      value: ppb.id,
    };
  });
}

export function parseBaseScenarios(scenarios) {
  if (!scenarios.length) return [];

  return scenarios.map((scenario) => {
    return {
      label: parseScenarioName(scenario),
      value: scenario?.id,
      updated: scenario?.lastUpdate,
    };
  });
}

export function parseScenariosLite(scenarios) {
  if (!scenarios?.length) return [];

  //API Lite scenarios has a boolean true for base scenarios
  const userScenarios = scenarios.filter((scenario) => scenario.base === false);

  return userScenarios.map((scenario) => {
    return {
      label: parseScenarioName(scenario),
      value: scenario?.id,
      updated: scenario?.lastUpdate,
    };
  });
}

export function parseScenarios(scenarios) {
  if (!scenarios?.length) return [];
  const userScenarios = filterUserScenarios(scenarios);

  return userScenarios.map((scenario) => {
    return {
      label: parseScenarioName(scenario),
      value: scenario?.id,
      name: scenario?.name,
      updated: scenario?.lastUpdate,
    };
  });
}

export function clearLocalStorage() {
  localStorage.removeItem('scenario');
  localStorage.removeItem('targetYear');
  localStorage.removeItem('@scenarios-modal');
}

export function getBaseVersion(scenarioName) {
  return scenarioName.split('(')[1].substr(1, 2);
}

export function getVersion(scenarioName) {
  return scenarioName.split('(')[1].replace(')', '');
}

export function isActualOrForwardMonths(monthName) {
  const currentMonth = new Date().getMonth() + 1;
  const getMonthByName = new Date(`${monthName} 1, ${new Date().getFullYear()}`).getMonth() + 1;

  return getMonthByName >= currentMonth;
}

export function parseScenarios2(scenarios, selectedScenario, labelType) {
  if (!scenarios.length || !selectedScenario) return [];
  const scenariosOptions = parseScenarios(scenarios);
  const baseVersion = getBaseVersion(selectedScenario);
  const scenarios2Options = scenariosOptions.filter(
    (option) => option.label.includes(baseVersion) && option.label !== selectedScenario
  );

  return [
    {
      label: labelType === 'select' ? i18n.t('common.select') : i18n.t('common.none'),
      value: '',
    },
    ...scenarios2Options,
  ];
}

export function clearRequestParams(obj) {
  if (!obj.partType || !obj.partType.length) {
    delete obj.partType;
  }

  if (!obj.order || !obj.orderBy) {
    delete obj.order;
    delete obj.orderBy;
  }

  return obj;
}

export function removeEmptyFilterWithFalse(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value != null && value !== '')
  );
}

export function removeEmptyFilter(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => !!value && value != null && value !== '')
  );
}

export function getFilterURL(obj) {
  return Object.entries(obj)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');
}

export function paramsAsStringWithFalse(params) {
  return getFilterURL(removeEmptyFilterWithFalse(params));
}

export function paramsAsString(params) {
  return getFilterURL(removeEmptyFilter(params));
}

export function removeValuesDuplicates(array) {
  const result = [];
  const options = {};
  array.forEach((o) => {
    if (!options[o.value]) {
      result.push(o);
      options[o.value] = true;
    }
  });
  return result;
}

export function removeNullOptions(array) {
  if (!array?.length) return [];
  return array.filter((item) => (item.value === 0 || !!item.value) && !!item.label);
}

export function getURIWithFalse(route, params = {}) {
  return params ? encodeURI(`${route}?${paramsAsStringWithFalse(params)}`) : encodeURI(route);
}

export function getURI(route, params = {}) {
  return params ? encodeURI(`${route}?${paramsAsString(params)}`) : encodeURI(route);
}

export function isFobOrBr(local) {
  if (local) {
    return ['0', '3', '4', '5', '8'].includes(local.toString()) ? `BR` : `FOB`;
  }

  return '';
}

export function getQueryString(param = 'allAsObject') {
  const locationSearch = window.location.search;
  if (!locationSearch) return null;

  if (param === 'allAsObject') {
    const urlSearchParams = new URLSearchParams(locationSearch);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params?.scenario === 'null') {
      params.scenario = '';
    }

    if (params?.targetYear === 'null') {
      params.targetYear = '';
    }

    return params;
  }

  const urlParams = new URLSearchParams(locationSearch);
  return urlParams.get(param);
}

export function filterAndUrlParams(params) {
  const qsParams = getQueryString();
  return { ...params, ...qsParams };
}

export function getSortOrder(orderMethod) {
  switch (orderMethod) {
    case 'ascend':
      return 'ASC';

    case 'descend':
      return 'DESC';

    default:
      return orderMethod;
  }
}

export const cleanPartTypeString = (str) => {
  if (typeof str !== 'string') return '';
  return str.replace(' - Finish Good', '').replace(' - Part Type', '').trim();
};

export const shortPartTypeString = (str) => {
  if (typeof str !== 'string') return '';
  return str.replace(' - Finish Good', ' - FG').replace(' - Part Type', ' - PT').trim();
};

export const statusFromMessage = (message) => {
  if (typeof message !== 'string') return null;
  if (message.includes('code 500')) return 500;

  return null;
};

export const normalizeObjectError = (exception) => {
  let error = exception;
  const { data, message, response } = error;
  const errorResponse = {};
  const errorMessage = data?.message || message || 'Request error';
  const responseMessage = response?.data;

  if (!data && message && typeof error === 'object') {
    error = error.toJSON();
  }

  errorResponse.status = error.status || error.code || statusFromMessage(errorMessage);
  errorResponse.message = errorMessage;
  errorResponse.error = error;
  errorResponse.data = data;
  errorResponse.errorResponse = responseMessage;

  console.log('REQUEST_ERROR=>', errorResponse);
  return errorResponse;
};

export function RawHTML({ children, className = '' }) {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />') }}
    />
  );
}

export function renderPrice(price, withSign = true) {
  const sign = withSign ? `$ ` : ``;
  if (price === '*') return '*';
  if (!price || price === 'NaN' || price === 'Infinity') return `${sign}0`;

  const options = withSign
    ? {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 3,
        maximumFractionDigits: 5,
      }
    : {
        signDisplay: 'never',
        minimumFractionDigits: 3,
        maximumFractionDigits: 5,
      };

  return new Intl.NumberFormat('en-US', options).format(price);
}

export function renderDecimal(value) {
  if (value === '*') return '*';
  if (!value || value === 'NaN') return 0;
  return parseFloat(value).toFixed(2);
}

export function structuralCloneAsync(obj) {
  return new Promise((resolve) => {
    const { port1, port2 } = new MessageChannel();
    port2.onmessage = (event) => resolve(event.data);
    port1.postMessage(obj);
  });
}

export function structuralClone(obj) {
  const oldState = window.history.state;
  window.history.replaceState(obj, document.title);

  const copy = window.history.state;
  window.history.replaceState(oldState, document.title);
  return copy;
}

export function resultDataObj(resultData) {
  /***
   * Copying object to get a new reference.
   * Not using spread because we also need nested new references here.
   *
   * https://dassur.ma/things/deep-copy/
   * https://web.dev/structured-clone/
   *
   * JSON.parse(JSON.stringify(obj));
   * Works But things like functions, undefined, Dates, Maps, Sets, RegExps,
   * ArrayBuffers and other built-in types just get lost at serialization
   *
   * structuralCloneAsync is an ugly crossbrowser solution with MessageChannel, but async.
   *
   * structuralClone is a sinc ugly solution, but has problems in safary
   *
   * structuredClone is a new ES method that creates a deep clone.
   * Check caniuse if the required browsers spec changes
   * https://caniuse.com/mdn-api_structuredclone
   * https://developer.mozilla.org/en-US/docs/Web/API/structuredClone   *
   * ***/

  let data = structuredClone(resultData);

  const totalData = {
    id: 'TOTAL',
    partType: 'TOTAL',
    costBrA: data.costBrA,
    costBrB: data.costBrB,
    costFobA: data.costFobA,
    costFobB: data.costFobB,
    costTotalA: data.costTotalA,
    costTotalB: data.costTotalB,
    gapTotalA: data.gapTotalA,
    gapTotalB: data.gapTotalB,
    investPointA: data.investPointA,
    investPointB: data.investPointB,
    scoreA: data.scoreA,
    scoreB: data.scoreB,
  };

  const dataWithNoChildren = !data?.children
    ? []
    : data?.children?.map((item) => {
        delete item.children;
        return item;
      });

  data = [...dataWithNoChildren, totalData];
  return data;
}

export function getTagColor(local) {
  if (local) return 'rgb(255,153, 0, 0.7)';
  return 'rgb(166,77,121, 0.7)';
}

export function invertFormatDate(date) {
  if (!date) return '';
  let newDate = date;

  //if is date time, get only date
  if (date.includes(' ')) {
    const [d] = date.split(' ');
    newDate = d;
  }

  /********************
   * if "-" convert to BR, if "/" to EN
   * Expected EN format: YYYY-MM-DD
   * Expected BR format: DD/MM/YYYY
   ********************/

  if (newDate.includes('-')) {
    const [year, month, day] = newDate.split('-');
    newDate = `${day}/${month}/${year}`;
  } else if (newDate.includes('/')) {
    const [day, month, year] = newDate.split('/');
    newDate = `${year}-${month}-${day}`;
  }

  return newDate;
}

export function dateFormat(dateInput) {
  /*
    TODO localStorage locale should be default in all dates of the system
    const locale = localStorage.getItem('language') === 'en' ? 'en-US' : 'pt-BR';
  */

  const locale = 'pt-BR';
  const timezone = 'America/Sao_Paulo';
  const date = new Date(dateInput);

  const dateFormatter = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: timezone,
  });
  const formattedDate = dateFormatter.format(date);

  const timeFormatter = new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: false,
    timeZone: timezone,
  });
  const formattedTime = timeFormatter.format(date).replace(':', 'h');

  const formattedDateTime = `${formattedDate}, ${formattedTime}`;

  return {
    date: formattedDate,
    time: formattedTime,
    dateTime: formattedDateTime,
  };
}

export function renderDate(date, formatMonth = 'long') {
  const currentLanguage = localStorage.getItem('language') === 'en' ? 'en-US' : 'pt-BR';
  const formatted = moment(date).format('YYYY-MM-DD');
  const newDate = new Date(formatted);
  const options = { month: formatMonth, day: '2-digit', year: 'numeric', timeZone: 'UTC' };
  return new Intl.DateTimeFormat(currentLanguage, options).format(newDate);
}

export function renderDateHour(dateString, withSeconds = false) {
  if (!dateString) return '-';

  const format = withSeconds ? 'DD/MM/YYYY - H:mm:ss' : 'DD/MM/YYYY - H[h]mm';
  return dayjs(dateString).format(format);
}

export const filterNestedObj = (entireObj, keyToFind, valToFind) => {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
};

export const updateSimulation = (object, id, index, value) => {
  const newObject = object;
  newObject.forEach((type) => {
    type.children.forEach((spec) => {
      spec.children.forEach((month) => {
        if (month.id === id) {
          month[index] = value;
        }
      });
    });
  });

  return newObject;
};

export const keyDownA11yEnter = (handler) => {
  return function onKeyDown(event) {
    if (event.type === 'keydown' && event.key === 'Enter') {
      handler();
    }
  };
};

export const keyDownA11y = (handler) => {
  return function onKeyDown(event) {
    if (['keydown', 'keypress'].includes(event.type) && ['Enter', ' '].includes(event.key)) {
      handler();
    }
  };
};

export const keyDownA11yEsc = (handler) => {
  return function onKeyDown(event) {
    if (['keydown', 'keypress'].includes(event.type) && ['Escape', 'Tab'].includes(event.key)) {
      handler();
    }
  };
};

export const renameObjKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {}
  );

export const capitalize = (string) => {
  return `${string.substr(0, 1).toUpperCase()}${string.substr(1)}`;
};

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const formatDecimalPlaces = (number) => {
  return Math.round(Number(number) * 100) / 100;
};

export function formatScoreData(item) {
  return {
    projectName: item.projectName,
    finished: item.finished,
    sku: item.sku,
    modelName: item.modelName,
    forecast: [
      {
        month: 'Jan',
        value: renderDecimal(item.january.skuScore),
        target: renderDecimal(item.january.skuTarget),
        skuForecast: item.january.skuForecast,
      },
      {
        month: 'Feb',
        value: renderDecimal(item.february.skuScore),
        target: renderDecimal(item.february.skuTarget),
        skuForecast: item.february.skuForecast,
      },
      {
        month: 'Mar',
        value: renderDecimal(item.march.skuScore),
        target: renderDecimal(item.march.skuTarget),
        skuForecast: item.march.skuForecast,
      },
      {
        month: 'Apr',
        value: renderDecimal(item.april.skuScore),
        target: renderDecimal(item.april.skuTarget),
        skuForecast: item.april.skuForecast,
      },
      {
        month: 'May',
        value: renderDecimal(item.may.skuScore),
        target: renderDecimal(item.may.skuTarget),
        skuForecast: item.may.skuForecast,
      },
      {
        month: 'Jun',
        value: renderDecimal(item.june.skuScore),
        target: renderDecimal(item.june.skuTarget),
        skuForecast: item.june.skuForecast,
      },
      {
        month: 'Jul',
        value: renderDecimal(item.july.skuScore),
        target: renderDecimal(item.july.skuTarget),
        skuForecast: item.july.skuForecast,
      },
      {
        month: 'Aug',
        value: renderDecimal(item.august.skuScore),
        target: renderDecimal(item.august.skuTarget),
        skuForecast: item.august.skuForecast,
      },
      {
        month: 'Sep',
        value: renderDecimal(item.september.skuScore),
        target: renderDecimal(item.september.skuTarget),
        skuForecast: item.september.skuForecast,
      },
      {
        month: 'Oct',
        value: renderDecimal(item.october.skuScore),
        target: renderDecimal(item.october.skuTarget),
        skuForecast: item.october.skuForecast,
      },
      {
        month: 'Nov',
        value: renderDecimal(item.november.skuScore),
        target: renderDecimal(item.november.skuTarget),
        skuForecast: item.november.skuForecast,
      },
      {
        month: 'Dec',
        value: renderDecimal(item.december.skuScore),
        target: renderDecimal(item.december.skuTarget),
        skuForecast: item.december.skuForecast,
      },
      {
        month: 'Total',
        value: renderDecimal(item.total.skuScore),
        target: renderDecimal(item.total.skuTarget),
        skuForecast: item.total.skuForecast,
      },
    ],
  };
}

export function getMaxAxisValue(data) {
  if (!data?.length) return 0;

  let maxValue = 0;
  data.forEach((item) => {
    if (item.value > maxValue) {
      maxValue = parseFloat(item.value);
    }
    if (item.target > maxValue) {
      maxValue = parseFloat(item.target);
    }
  });

  return Math.ceil(maxValue);
}
