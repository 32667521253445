import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 27.188rem;
  height: 4.5rem;
  border-radius: 0.375rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.gray_background};
`;

export const Contact = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  margin: 0.875rem 0 0 1rem;
`;

export const Email = styled.span`
  font-size: 0.75rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colorsDesignSystem.tagSupllier};
  margin: 0.063rem 0 0 1rem;
`;

export const Supplier = styled.p`
  font-size: 0.75rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colorsDesignSystem.tagSupllier};
  margin: 0.063rem 0 0.875rem 1rem;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
