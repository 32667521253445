import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NewRoundModal } from '../../components/Common/NewRoundModal';
import StyledButton from '../../components/Common/StyledButton';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledModal from '../../components/Common/StyledModal';
import { StyledPagination } from '../../components/Common/StyledPagination';
import StyledSelect from '../../components/Common/StyledSelect';
import StyledTabs from '../../components/Common/StyledTabs';
import { StyledTab } from '../../components/Common/StyledTabs/types';
import SimpleTable from '../../components/Tables/SimpleTable';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import theme from '../../styles/theme';
import * as S from './styles';
import { RfqItem } from './types';
import { useRfq } from './useRfq';

export function RfqPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    columns,
    form,
    rfqRecords,
    isLoading,
    bidsName,
    bidsStatus,
    categories,
    suppliers,
    params,
    isOpenNewRoundModal,
    pageControl,
    clearAllFilters,
    handleUpdateFilters,
    handleFetchRfqRecords,
    handleRenderClearAllFilters,
    handleRenderFiltersTags,
    handleFetchRfqHeadersFilters,
    setIsOpenNewRoundModal,
    createNewRound,
    changePage,
    loadingNewRound,
  } = useRfq();

  useDocumentTitle(t('pages.rfq.title'));

  const [tabs, setTabs] = useState<StyledTab[]>([
    { selected: true, title: t('pages.rfq.titleTabs.open') },
    { selected: false, title: t('pages.rfq.titleTabs.concluded') },
  ]);

  useEffect(() => {
    handleFetchRfqHeadersFilters();
    handleFetchRfqRecords();
  }, [params]);

  useEffect(() => {
    handleFetchRfqRecords();
  }, [params.page, params.limit]);

  useEffect(() => {
    if (tabs.find((item) => item.selected)?.title === 'Open') {
      handleUpdateFilters('tab', 'OPEN');
      clearAllFilters();
    } else {
      handleUpdateFilters('tab', 'CONCLUDED');
      clearAllFilters();
    }
  }, [tabs]);

  return (
    <S.Container data-cy="pageRfq">
      <StyledModal
        title={t('components.modals.newRound.title')}
        body={<NewRoundModal />}
        footer={
          <Flex justify="space-between" align="center">
            <Button
              type="link"
              onClick={() => setIsOpenNewRoundModal({ bidId: '', isOpen: false })}
            >
              {t('common.cancel')}
            </Button>
            <Button type="primary" onClick={createNewRound} loading={loadingNewRound}>
              {t('common.confirm')}
            </Button>
          </Flex>
        }
        open={isOpenNewRoundModal.isOpen}
        width="44rem"
        centered
        onClose={() => setIsOpenNewRoundModal({ bidId: '', isOpen: false })}
      />
      <StyledHeader
        title={t('pages.rfq.title')}
        actions={
          <StyledButton
            small
            iconLeft={<PlusOutlined />}
            onClick={() => history.push('new-rfq')}
            variant="primary"
            style={{ height: '2rem' }}
            text={t('pages.rfq.labels.addNew')}
          />
        }
      />
      <Row style={{ marginBottom: '1.25rem' }}>
        <StyledTabs tabs={tabs} setSelectedTab={setTabs} />
      </Row>
      <S.ContainerFilters data-cy="filtersRfq">
        <StyledSelect
          options={bidsName}
          showSearch
          suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
          label={t('pages.rfq.labels.bidName')}
          placeholder={t('pages.rfq.placeholders.bidName')}
          onChange={(value: string) => {
            handleUpdateFilters('bidName', value);
          }}
          styles={{ minWidth: '11rem' }}
          value={params.bidName || null}
          data-cy="filterBidName"
        />
        <StyledSelect
          suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
          label={t('pages.rfq.labels.category')}
          placeholder={t('pages.rfq.placeholders.category')}
          onChange={(value: string) => {
            handleUpdateFilters('category', value);
          }}
          options={categories}
          showSearch
          value={params.category || null}
          styles={{ minWidth: '7.25rem' }}
          data-cy="filterCategory"
        />
        <StyledSelect
          showSearch
          suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
          label={t('pages.rfq.labels.supplier')}
          placeholder={t('pages.rfq.placeholders.supplier')}
          onChange={(value: string) => {
            handleUpdateFilters('supplier', value);
          }}
          options={suppliers}
          value={params.supplier || null}
          styles={{ minWidth: '7.25rem' }}
          data-cy="filterSupplier"
        />
        <StyledSelect
          label={t('pages.rfq.labels.status')}
          placeholder={t('pages.rfq.placeholders.status')}
          onChange={(value: string) => {
            handleUpdateFilters('status', value);
          }}
          options={bidsStatus}
          value={params.status || null}
          styles={{ minWidth: '9rem' }}
          data-cy="filterStatus"
        />

        <StyledButton
          small
          onClick={() => {
            handleFetchRfqRecords();
          }}
          variant="primary"
          style={{ height: '2rem' }}
          text={t('pages.shareProportion.labels.apply')}
          data-cy="applyButton"
        />
      </S.ContainerFilters>

      <S.FiltersTagContainer>
        {handleRenderFiltersTags()}
        {handleRenderClearAllFilters()}
      </S.FiltersTagContainer>

      <Form form={form} component={false}>
        <SimpleTable
          striped
          bordered={false}
          pagination={false}
          isLoading={isLoading}
          dataSource={rfqRecords}
          rowKey={rfqRecords[0]?.bidName}
          columns={columns}
          onRow={(record: RfqItem) => {
            return {
              onClick: () => {
                record.status.toUpperCase() !== 'DRAFT'
                  ? history.push(`/view-rfq?bidId=${record.id}`)
                  : history.push(`/new-rfq?bidId=${record.id}`);
              },
            };
          }}
          noBorder
          scrollable
        />
      </Form>
      {pageControl.totalPages > 1 && (
        <StyledPagination
          size={params.limit}
          totalElements={pageControl.totalElements}
          pageNumber={params.page + 1}
          onChange={changePage}
        />
      )}
    </S.Container>
  );
}
