import { InfoCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 18.625rem;
  height: 8.25rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: 0.75rem;
  padding: 1.5rem;
`;

export const Circle = styled.div`
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.backgroundCircleAward};
`;

export const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0.75rem;
  .title {
    font-weight: bold;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }
`;

export const ContainerImpactLabels = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  p {
    margin: 0;
  }
`;

export const ImpactLabel = styled.p<{ positive: boolean }>`
  font-weight: 600;
  font-size: 1.5rem;
  color: ${({ positive, theme }) =>
    positive
      ? theme.colorsDesignSystem.red
      : theme.colorsDesignSystem.rfqIndicationTagColors.greenFont};
`;

export const Label = styled.p`
  margin-top: 0.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.input_labels};
`;

export const StyledInfoCircle = styled(InfoCircleFilled)`
  font-size: 1rem;
  margin-top: -0.4rem;
  color: ${({ theme }) => theme.colorsDesignSystem.red};
`;
