import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FileAddOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { Button, Form, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import IndicationTagRfq from '../../components/Common/IndicationTagRfq';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';
import StyledIndicationTag from '../../components/IndicationTag';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import { BidStatus } from '../../helpers/enums';
import { getUserNameAndEmail, removeDiplicated } from '../../helpers/nUtils';
import { getURI, oneAlert } from '../../helpers/utils';
import { api } from '../../services/api';
import { services } from '../../services/services';
import theme from '../../styles/theme';
import { StyledSelectOptions } from '../ComponentsPrices/PricePage/types';
import * as S from './styles';
import { ParamsPropsRfq, RfqItem } from './types';

export function useRfq() {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [bidsName, setBidsName] = useState<Array<StyledSelectOptions>>([]);
  const [categories, setCategories] = useState<Array<StyledSelectOptions>>([]);
  const [suppliers, setSuppliers] = useState<Array<StyledSelectOptions>>([]);
  const [bidsStatus, setBidsStatus] = useState<Array<StyledSelectOptions>>([]);
  const initialParams: ParamsPropsRfq = {
    bidName: '',
    category: '',
    supplier: '',
    status: '',
    limit: 10,
    page: 0,
    tab: 'OPEN',
  };
  const [params, setParams] = useState<ParamsPropsRfq>(initialParams);
  const [rfqRecords, setRfqRecords] = useState<Array<RfqItem>>([]);
  const [isOpenNewRoundModal, setIsOpenNewRoundModal] = useState<{
    bidId: string;
    isOpen: boolean;
  }>({ bidId: '', isOpen: false });
  const [loadingNewRound, setLoadingNewRound] = useState<boolean>(false);
  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });

  const handleGetTextIndicationTagRfqByType = (type: string): string => {
    if (type === BidStatus.ROUND_OPEN) {
      return 'Round started';
    }
    if (type === BidStatus.CANCELED) {
      return 'CANCELED';
    }
    if (type === BidStatus.OPEN) {
      return 'BID Open';
    }
    if (type === BidStatus.CLOSED) {
      return 'BID Close';
    }
    if (type === BidStatus.IN_REVIEW) {
      return 'Under analysis';
    }
    if (type === BidStatus.RESULT) {
      return 'Result';
    }

    return 'Draft';
  };

  const handleFetchRfqRecords = async (Tab?: string) => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get(getURI(`${services.rfq}/bid/filter`, { ...params }));
      if (status === 200) {
        setRfqRecords(data.content || []);
        setPageControl({
          pageLabel: `${data.pageable.pageNumber + 1}`,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
        });
      }

      status === 204 && setRfqRecords([]);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
      setRfqRecords([]);
    } finally {
      setIsLoading(false);
    }
  };

  const changePage = (page: number, pageSize: number) => {
    setParams({ ...params, page: page - 1, limit: pageSize });
    setPageControl({ ...pageControl, pageLabel: `${page}` });
  };

  const handleConvertStringArrayToStyledSelectOptions = (
    data: Array<string>
  ): Array<StyledSelectOptions> => {
    try {
      return data.map((value) => {
        return {
          label: value,
          value,
        };
      });
    } catch (error) {
      return [];
    }
  };

  const handleFetchRfqHeadersFilters = async () => {
    try {
      const { data, status } = await api.get(getURI(`${services.rfq}/bid/header`, { ...params }));
      if (status === 200) {
        setBidsName(handleConvertStringArrayToStyledSelectOptions(data.bidNameList || []));
        setBidsStatus(handleConvertStringArrayToStyledSelectOptions(data.statusList || []));
        setCategories(handleConvertStringArrayToStyledSelectOptions(data.categoryList || []));
        setSuppliers(handleConvertStringArrayToStyledSelectOptions(data.supplierList || []));
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
      setBidsName([]);
      setBidsStatus([]);
      setCategories([]);
      setSuppliers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteRfqRecords = async (id: string) => {
    try {
      setIsLoading(true);
      const { status } = await api.delete(`${services.rfq}/bid/${id}`);
      if (status === 200) {
        oneAlert('success', t('toast.successOnDelete'));
        handleFetchRfqRecords();
      }

      if (status === 204) {
        setRfqRecords([]);
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
      setRfqRecords([]);
    } finally {
      setIsLoading(false);
    }
  };

  const createNewRound = async () => {
    try {
      setLoadingNewRound(true);
      const { status } = await api.patch(
        `${services.rfq}/bid/newRound?idBid=${isOpenNewRoundModal.bidId}`
      );
      if (status === 200 || status === 204) {
        handleFetchRfqRecords();
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnSave'));
    } finally {
      setLoadingNewRound(false);
      setIsOpenNewRoundModal({ bidId: '', isOpen: false });
    }
  };

  const columns: ColumnsType<RfqItem> = [
    {
      title: t('pages.rfq.labels.bidName'),
      dataIndex: 'bidName',
      key: 'bidName',
      align: 'left',
      render: (_, record) => {
        return (
          <S.ContainerDataTable align="left" bidName>
            <span>{record.bidName}</span>
          </S.ContainerDataTable>
        );
      },
    },
    {
      title: t('pages.rfq.labels.category'),
      dataIndex: 'category',
      key: 'category',
      align: 'left',
      render: (_, record) => {
        return record.category?.map((category) => {
          return <StyledIndicationTag text={category || ''} type="Category" key={category} />;
        });
      },
    },
    {
      title: t('pages.rfq.labels.suppliers'),
      dataIndex: 'suppliers',
      key: 'suppliers',
      align: 'left',
      render: (_, record) => {
        return (
          <S.ContainerDataTable align="left">
            <span>{record.suppliers ? removeDiplicated(record.suppliers).join(', ') : '-'}</span>
          </S.ContainerDataTable>
        );
      },
    },
    {
      title: t('pages.rfq.labels.start'),
      align: 'center',
      key: 'start',
      dataIndex: 'start',
      render: (_, record) => {
        return (
          <S.ContainerDataTable align="center">
            <HistoryOutlined />
            <span>{record.start}</span>
          </S.ContainerDataTable>
        );
      },
    },
    {
      title: t('pages.rfq.labels.end'),
      key: 'end',
      align: 'center',
      dataIndex: 'end',
      render: (_, record) => {
        return (
          <S.ContainerDataTable align="center">
            <HistoryOutlined />
            <span>{record.end}</span>
          </S.ContainerDataTable>
        );
      },
    },
    {
      title: t('pages.rfq.labels.status'),
      key: 'status',
      align: 'center',
      dataIndex: 'status',
      render: (_, record) => {
        return (
          <S.ContainerDataTable align="center">
            <IndicationTagRfq
              text={handleGetTextIndicationTagRfqByType(record.status)}
              type={record.status}
            />
          </S.ContainerDataTable>
        );
      },
    },
    {
      title: t('common.action'),
      key: 'action',
      align: 'center',
      width: 130,
      render: (_, record) => {
        const isDraft = record.status.toUpperCase() === 'DRAFT';
        const actions = [
          {
            key: '1',
            label: (
              <Button
                type="link"
                data-cy="delete"
                onClick={() => {
                  isDraft
                    ? history.push(`/new-rfq?bidId=${record.id}`)
                    : history.push(`/view-rfq?bidId=${record.id}`);
                }}
                icon={!isDraft ? <EyeOutlined /> : <EditOutlined />}
              >
                {!isDraft ? <>{t('pages.rfq.actions.viewData')}</> : <>{t('common.edit')} </>}
              </Button>
            ),
          },
          {
            key: '2',
            label: (
              <Button
                type="link"
                data-cy="newRound"
                disabled={
                  !(record.status === BidStatus.CLOSED || record.status === BidStatus.IN_REVIEW) ||
                  record.hasChild === true ||
                  record.createdBy !== getUserNameAndEmail(localStorage.getItem('idToken'))?.name
                }
                onClick={(e) => {
                  setIsOpenNewRoundModal({ bidId: record.id, isOpen: true });
                  e.stopPropagation();
                }}
                icon={<FileAddOutlined />}
              >
                {t('pages.rfq.actions.newRound')}
              </Button>
            ),
          },
          {
            key: '3',
            label: (
              <Popconfirm
                title={t('common.deleteMessage')}
                onClick={() => null}
                placement="topLeft"
                onConfirm={(e) => {
                  handleDeleteRfqRecords(record.id);
                  e?.stopPropagation();
                }}
                onCancel={(e) => {
                  e?.stopPropagation();
                }}
              >
                <Button
                  type="link"
                  data-cy="delete"
                  disabled={record.status !== 'DRAFT'}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  icon={<DeleteOutlined />}
                >
                  {t('common.delete')}
                </Button>
              </Popconfirm>
            ),
          },
        ];

        return <TableAction dropdownItems={actions} placement="bottom" />;
      },
    },
  ];

  const handleUpdateFilters = (field: string, value: string) => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        [field]: value,
      };
    });
  };

  const handleRenderFiltersTags = () => {
    return (
      <>
        {params.bidName && (
          <StyledFilterTag
            label={t('pages.rfq.labels.bidName')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.bidName}
            closeble
            onClose={() => {
              handleUpdateFilters('bidName', '');
            }}
          />
        )}
        {params.category && (
          <StyledFilterTag
            label={t('pages.rfq.labels.category')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.category}
            closeble
            onClose={() => {
              handleUpdateFilters('category', '');
            }}
          />
        )}
        {params.supplier && (
          <StyledFilterTag
            label={t('pages.rfq.labels.supplier')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.supplier}
            closeble
            onClose={() => {
              handleUpdateFilters('supplier', '');
            }}
          />
        )}
        {params.status && (
          <StyledFilterTag
            label={t('pages.rfq.labels.status')}
            closeicon={<CloseOutlined />}
            title={params.status}
            closeble
            onClose={() => {
              handleUpdateFilters('status', '');
            }}
          />
        )}
      </>
    );
  };

  const clearAllFilters = () => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        bidName: '',
        category: '',
        supplier: '',
        status: '',
      };
    });
  };

  const handleRenderClearAllFilters = () => {
    if (params.bidName || params.category || params.status || params.supplier) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  return {
    columns,
    form,
    rfqRecords,
    categories,
    bidsName,
    bidsStatus,
    suppliers,
    isLoading,
    params,
    isOpenNewRoundModal,
    pageControl,
    clearAllFilters,
    handleFetchRfqRecords,
    handleUpdateFilters,
    handleRenderClearAllFilters,
    handleRenderFiltersTags,
    handleFetchRfqHeadersFilters,
    setIsOpenNewRoundModal,
    createNewRound,
    changePage,
    loadingNewRound,
  };
}
